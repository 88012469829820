"use client";

import { useEffect, useMemo } from "react";

import { Builder } from "@builder.io/sdk";
import { motion } from "framer-motion";
import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
import { ArticleCard } from "~/components/article-card/article-card";
import { CardListTabs } from "~/components/card-list-tabs/card-list-tabs";
import { Pagination } from "~/components/pagination/pagination";
import { type Tag } from "~/types/article";
import { type BuilderDefaultProps } from "~/types/builder.types";
import { getItemsByPage, noop } from "~/utils";

import { useCaseStudiesQuery } from "./data";

interface Props
  extends BuilderDefaultProps<{
    caseStudiesTags: Tag[];
  }> {
  limit: number;
  loadMoreText: string;
  caseStudies: Array<{
    id: string;
    data: any;
  }>;
}

const CaseStudyList = ({
  caseStudies = [],
  limit,
  builderState,
  loadMoreText,
}: Props) => {
  const searchParams = useSearchParams();
  const page = Number(searchParams.get("page")) ?? 0;
  const tag = searchParams.get("tag") ?? "all";
  const tags = builderState?.context.caseStudiesTags ?? [];

  const { data, fetchNextPage, hasNextPage, refetch } = useCaseStudiesQuery({
    items: caseStudies,
    limit,
    page,
    tag,
  });

  const items = useMemo(
    () => getItemsByPage(data.pages, page, limit),
    [data.pages, limit, page]
  );

  const getAnimationDelay = (index: number) => {
    if (page === 0) return index * 0.08;
    const isLastLimit = items.length - index <= limit;
    return isLastLimit ? (index - (items.length - limit)) * 0.08 : 0;
  };

  const handleNextPage = () => {
    fetchNextPage().catch(noop);
  };

  useEffect(() => {
    refetch().catch(noop);
  }, [refetch, tag]);

  return (
    <section className="flex max-w-[1200px] flex-col items-center">
      <CardListTabs tags={tags} align="right" />
      <div className="mt-9 grid w-full grid-cols-1 gap-x-8 gap-y-[74px] md:grid-cols-2 lg:grid-cols-3">
        {items?.map((article, index) => (
          <motion.div
            key={article.id}
            layout
            layoutId={article.id}
            initial={{ opacity: 0, scale: 0.75 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                duration: 0.08,
                delay: getAnimationDelay(index),
              },
            }}
            exit={{
              opacity: 0,
              scale: 0.5,
              transition: {
                duration: 0,
              },
            }}
          >
            <ArticleCard {...article.data} handle={article.data.url} />
          </motion.div>
        ))}
      </div>
      <Pagination
        className="mt-16"
        nextPage={hasNextPage ? page + 1 : undefined}
        loadMoreText={loadMoreText}
        limit={0}
        total={0}
        onClick={handleNextPage}
      />
    </section>
  );
};

const registerCaseStudyList = () => {
  Builder.registerComponent(
    dynamic(
      async () =>
        await import("./case-study-list").then((mod) => mod.CaseStudyList),
      {
        ssr: false,
      }
    ),
    {
      name: "CaseStudyList",
      defaults: {
        bindings: {
          "component.options.caseStudies": "context.caseStudies.items",
        },
      },
      inputs: [
        {
          name: "loadMoreText",
          type: "string",
          defaultValue: "See more projects",
        },
        {
          name: "limit",
          friendlyName: "Max articles per page",
          type: "number",
          defaultValue: 6,
        },
        {
          name: "caseStudies",
          type: "list",
          hideFromUI: true,
          subFields: [
            {
              name: "caseStudy",
              type: "reference",
              model: "case-study-post",
            },
          ],
        },
      ],
    }
  );
};

export { CaseStudyList, registerCaseStudyList };
