import { useRef } from "react";

import { Builder } from "@builder.io/sdk";
import { motion, useInView } from "framer-motion";
import Image from "next/image";
import { imageLoader } from "~/utils";

interface Props {
  leftLabel: string;
  rightLabel: string;
  score: number;
  backgroundImage: string;
  needleImage: string;
}

const Predictometer = ({
  leftLabel,
  rightLabel,
  score,
  backgroundImage,
  needleImage,
}: Props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const needleVariants = {
    initial: { rotate: -90 },
    animate: { rotate: (score - 50) * 1.8 },
  };

  return (
    <div ref={ref} className="flex w-64 flex-col">
      <div className="relative h-32 w-full">
        <Image
          src={backgroundImage}
          alt="Speedometer"
          fill
          className="h-[120px] w-full"
          loader={imageLoader}
        />
        <div className="absolute bottom-[8px] left-1/2 flex h-20 -translate-x-1/2 items-end justify-center">
          <motion.img
            src={needleImage}
            alt="Needle"
            className="h-full w-auto origin-bottom"
            variants={needleVariants}
            initial="initial"
            animate={isInView ? "animate" : "initial"}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 10,
              delay: 0.3,
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <span className="ml-[-19px] w-[100px] text-center text-[13.5px] font-normal">
          {leftLabel}
        </span>
        <span className="mr-[-19px] w-[100px] text-center text-[13.5px] font-normal">
          {rightLabel}
        </span>
      </div>
    </div>
  );
};

const registerPredictometer = () => {
  Builder.registerComponent(Predictometer, {
    name: "Predictometer",
    inputs: [
      {
        name: "leftLabel",
        type: "string",
        defaultValue: "Low",
        required: true,
      },
      {
        name: "rightLabel",
        type: "string",
        defaultValue: "High",
        required: true,
      },
      {
        name: "score",
        type: "number",
        defaultValue: 50,
        required: true,
      },
      {
        name: "backgroundImage",
        type: "file",
        allowedFileTypes: ["png", "svg"],
        required: true,
      },
      {
        name: "needleImage",
        type: "file",
        allowedFileTypes: ["png", "svg"],
        required: true,
      },
    ],
  });
};

export { Predictometer, registerPredictometer };
