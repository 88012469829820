import { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";

import { withChildren } from "@builder.io/react";
import { Builder } from "@builder.io/sdk";
import { Button } from "~/components/ui/button";
import Portal from "~/components/ui/portals";

import { Animate } from "../animation/animate";

interface Props {
  children: React.ReactNode;
  ctaText: string;
}

const CookiesBanner = ({ children, ctaText }: Props) => {
  const [bannerDismissed, setBannerDismissed] = useState(true);

  useEffect(() => {
    const fetchBannerDismissed = async () => {
      try {
        const cookieBannerDismissed: boolean = await fetch("/api", {
          headers: {
            "banner-message": encodeURIComponent(
              ReactDOMServer.renderToStaticMarkup(children) ?? "cookie-banner"
            ),
          },
          method: "GET",
        }).then(
          async (res) => await res.json().then((data) => data.bannerDismissed)
        );
        setBannerDismissed(cookieBannerDismissed);
      } catch (e) {
        console.error(e);
        setBannerDismissed(false);
      }
    };

    void fetchBannerDismissed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dismissBanner = async () => {
    setBannerDismissed(true);
    try {
      await fetch("/api", {
        headers: {
          "banner-message": encodeURIComponent(
            ReactDOMServer.renderToStaticMarkup(children) ?? "cookie-banner"
          ),
        },
        method: "POST",
      });
    } catch (e) {
      console.error(e);
    }
  };

  if (bannerDismissed) return null;

  return (
    <Portal container="#bottom-banner">
      <Animate type="appear-up">
        <div
          className={
            "relative z-50 flex w-full flex-col items-center justify-center gap-5 bg-brand-primary-3 px-[70px] pb-7 pt-6 sm:flex-row sm:px-16 sm:pb-6"
          }
        >
          <div className="break-words">{children}</div>
          <Button
            variant={"secondary"}
            onClick={() => {
              void dismissBanner();
            }}
          >
            {ctaText}
          </Button>
        </div>
      </Animate>
    </Portal>
  );
};

const registerCookiesBanner = () => {
  Builder.registerComponent(withChildren(CookiesBanner), {
    name: "CookiesBanner",
    defaultChildren: [
      {
        "@type": "@builder.io/sdk:Element",
        component: {
          name: "BlogTypography",
          options: {
            text: "description",
          },
        },
      },
    ],
    inputs: [
      {
        name: "ctaText",
        type: "string",
        defaultValue: "Accept",
      },
    ],
  });
};

export { CookiesBanner, registerCookiesBanner };

export const closeIcon = (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.8975 13.4336L13.1024 25.2287M13.1024 13.4336L24.8975 25.2287"
      stroke="white"
      strokeWidth="1.96585"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
