import { Builder } from "@builder.io/sdk";
import { Button } from "~/components/ui/button";
import { Icon } from "~/components/ui/icon";
import { cn } from "~/utils";

interface Props {
  content: string;
  color: "primary" | "secondary" | "white";
  link: {
    value: {
      data: {
        url: string;
      };
    };
  };
}

const GoBackLink = ({ content, link, color }: Props) => {
  return (
    <Button
      variant="ghost"
      size="xs"
      className={cn("gap-3", {
        "text-brand-primary-1": color === "primary",
        "text-brand-primary-3": color === "secondary",
        "text-white": color === "white",
      })}
      link={{
        external: false,
        href: link?.value?.data?.url ?? "",
      }}
    >
      <Icon
        iconUrl="/images/icons/arrow-left.svg"
        className={cn("size-4", {
          "bg-brand-primary-1": color === "primary",
          "bg-brand-primary-3": color === "secondary",
          "bg-white": color === "white",
        })}
      />
      <span className="font-medium">{content}</span>
    </Button>
  );
};

const registerGoBackLink = () => {
  Builder.registerComponent(GoBackLink, {
    name: "GoBackLink",
    inputs: [
      {
        name: "content",
        type: "string",
        required: true,
        defaultValue: "Go Back",
      },
      {
        name: "link",
        type: "reference",
        required: true,
      },
      {
        name: "color",
        type: "string",
        required: true,
        defaultValue: "primary",
        enum: ["primary", "secondary", "white"],
      },
    ],
  });
};

export { GoBackLink, registerGoBackLink };
