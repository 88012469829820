import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Portal = ({
  children,
  container,
}: {
  children: React.ReactNode;
  container: string;
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return mounted
    ? createPortal(
        children,
        document.querySelector(`${container}`) ?? document.body
      )
    : null;
};

export default Portal;
