import { Builder } from "@builder.io/sdk";
import { PodcastEpisodeText } from "~/components/podcast-episode-text/podcast-episode-text";
import { PodcastSubscribeLinks } from "~/components/podcast-subscribe-links/podcast-subscribe-links";
import { SocialLinksShare } from "~/components/social-links-share/social-links-share";
import { BlogTypography } from "~/components/ui/blog-typography";
import { type BuilderDefaultProps } from "~/types/builder.types";
import { type Podcast } from "~/types/podcast";

interface Props extends Podcast, BuilderDefaultProps {}

const PodcastContent = ({
  date,
  description,
  episode,
  title,
  metadata,
  builderState,
}: Props) => {
  return (
    <section className="mx-auto mt-14 flex w-full max-w-[800px] flex-col items-center justify-center">
      <PodcastSubscribeLinks
        metadata={builderState?.content?.data?.metadata}
        color="primary"
      />
      <div className="mt-12 flex flex-col items-center justify-center gap-6 px-6 md:px-0">
        <PodcastEpisodeText date={date} episode={episode} />
        <BlogTypography variant="h1" align="center" mobileVariant="h2">
          {title}
        </BlogTypography>
        <BlogTypography
          variant="p1-large"
          align="center"
          className="mt-4 max-w-[596px]"
        >
          {description}
        </BlogTypography>
      </div>
      <iframe
        className="mt-12 px-5 md:p-0"
        height="200px"
        width="100%"
        seamless
        src={`https://player.simplecast.com/${metadata.podcastId}?dark=false`}
      />
      <div className="my-9 h-px w-full bg-[#EAECF0]" />
      <SocialLinksShare
        links={[
          { platform: "linkedin" },
          { platform: "twitter" },
          { platform: "facebook" },
        ]}
        copyLink
        builderState={builderState}
      />
    </section>
  );
};

const registerPodcastContent = () => {
  Builder.registerComponent(PodcastContent, {
    name: "PodcastContent",
    defaults: {
      bindings: {
        "component.options.title": "state.podcast.data.title",
        "component.options.description": "state.podcast.data.description",
        "component.options.episode": "state.podcast.data.episode",
        "component.options.metadata": "state.podcast.data.metadata",
        "component.options.handle": "state.podcast.data.handle",
        "component.options.date": "state.podcast.data.date",
      },
    },
    inputs: [
      {
        name: "title",
        type: "string",
        required: true,
      },
      {
        name: "description",
        type: "string",
        required: true,
      },
      {
        name: "episode",
        type: "number",
        required: true,
      },
      {
        name: "metadata",
        type: "object",
        required: true,
        subFields: [
          {
            name: "podcastUrl",
            type: "string",
            required: false,
          },
          {
            name: "podcastId",
            type: "string",
            required: true,
          },
          {
            name: "startAt",
            type: "number",
          },
        ],
      },
      {
        name: "handle",
        type: "string",
        required: true,
      },
      {
        name: "date",
        type: "string",
        required: true,
      },
    ],
  });
};

export { PodcastContent, registerPodcastContent };
