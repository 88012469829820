import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import { TypographyDeprecated } from "~/components/ui/typography";
import { imageLoader } from "~/utils";

interface Props {
  name: string;
  image: string;
  jobTitle: string;
}

const BlogAuthor = ({ name, image, jobTitle }: Props) => {
  return (
    <div className="relative flex w-full flex-row items-start gap-4 md:w-full md:max-w-96">
      <div className="relative size-[56px] overflow-hidden rounded-full">
        <Image
          src={image}
          fill
          alt={name}
          loader={imageLoader}
          className="object-cover object-top"
          sizes="56px"
        />
      </div>
      <div className="mx-6 flex max-w-[340px] flex-col gap-2 overflow-hidden md:ml-0 md:max-w-none">
        <TypographyDeprecated variant="h6" className="text-[#101828]">
          {name}
        </TypographyDeprecated>
        <TypographyDeprecated
          variant="field-label"
          size="medium"
          className="text-[#475467]"
        >
          {jobTitle}
        </TypographyDeprecated>
      </div>
    </div>
  );
};

const registerBlogAuthor = () => {
  Builder.registerComponent(BlogAuthor, {
    name: "BlogAuthor",
    inputs: [
      {
        name: "name",
        type: "string",
        required: true,
      },
      {
        name: "jobTitle",
        type: "string",
        required: true,
      },
      {
        name: "image",
        type: "file",
        allowedFileTypes: ["jpeg", "png", "jpg"],
        required: true,
      },
    ],
  });
};

export { BlogAuthor, registerBlogAuthor };
