import { Builder } from "@builder.io/sdk";
import { registerAccordion } from "~/components/accordion/accordion";
import { registerArticleCard } from "~/components/article-card/article-card";
import { registerArticleList } from "~/components/article-list/article-list";
import { registerBannerPopup } from "~/components/banner-popup/banner-popup";
import { registerBlogAuthor } from "~/components/blog-author/blog-author";
import { registerBlogPostListTabs } from "~/components/blog-post-list-tabs/blog-post-list-tabs";
import { registerBlogPostList } from "~/components/blog-post-list/blog-post-list";
import { registerCardListTabs } from "~/components/card-list-tabs/card-list-tabs";
import { registerCaseStudyList } from "~/components/case-study-list/case-study-list";
import { registerContactForm } from "~/components/contact-form/contact-form";
import { registerCookiesBanner } from "~/components/cookies-banner/cookies-banner";
import { registerFeaturedBlogPost } from "~/components/featured-blog-post/featured-blog-post";
import { registerFooterCopyright } from "~/components/footer-copyright/footer-copyright";
import { registerGoBackLink } from "~/components/go-back-link/go-back-link";
import { registerGuideCard } from "~/components/guide-card/guide-card";
import { registerCarousel } from "~/components/hero-carousel/hero-carousel";
import { registerHeroSection } from "~/components/hero-section/hero-section";
import { registerHomeAnimation } from "~/components/home-animation/home-animation";
import { registerHubSpotForm } from "~/components/hubspot-form/hubspot-form";
import { registerIconWithTextCard } from "~/components/icon-with-text-card/icon-with-text-card";
import { registerImageGallery } from "~/components/image-gallery/image-gallery";
import { registerImageOverride } from "~/components/image-override/image-override";
import { registerImageRow } from "~/components/image-row/image-row";
import { registerImageSlider } from "~/components/image-slider/image-slider";
import { registerInsightsCategoryTabs } from "~/components/insights-category-tabs/insights-category-tabs";
import { registerInsightsForm } from "~/components/insights-form/insights-form";
import { registerLinkBox } from "~/components/link-box/link-box";
import { registerLogoCarousel } from "~/components/logo-carousel/logo-carousel";
import { registerMap } from "~/components/map/map";
import { registerNewsletterForm } from "~/components/newsletter-form/newsletter-form";
import { registerOldPostContent } from "~/components/old-post-content/old-post-content";
import { registerPagination } from "~/components/pagination/pagination";
import { registerPodcastCard } from "~/components/podcast-card/podcast-card";
import { registerPodcastContent } from "~/components/podcast-content/podcast-content";
import { registerPodcastFeaturedCard } from "~/components/podcast-featured-card/podcast-featured-card";
import { registerPodcastHero } from "~/components/podcast-hero/podcast-hero";
import { registerPodcastSubscribeLinks } from "~/components/podcast-subscribe-links/podcast-subscribe-links";
import { registerPredictometer } from "~/components/predictometer/predictometer";
import { registerServiceCard } from "~/components/service-card/service-card";
import { registerShareButton } from "~/components/share-button/share-button";
import { registerSocialLinksShare } from "~/components/social-links-share/social-links-share";
import { registerStickyHeader } from "~/components/sticky-header/sticky-header";
import { registerTeamMemberList } from "~/components/team-member-list/team-member-list";
import { registerTestimonialCarousel } from "~/components/testimonial-carousel/testimonial-carousel";
import { registerTextSectionGrowingCircle } from "~/components/text-section-growing-circle/text-section-growing-circle";
import { registerTextSectionParallaxCircle } from "~/components/text-section-parallax-circle/text-section-parallax-circle";
import { registerBlogTypography } from "~/components/ui/blog-typography";
import { registerBulletPoints } from "~/components/ui/bullet-points";
import { registerButton } from "~/components/ui/button";
import { registerTypography } from "~/components/ui/typography";
import { registerUpcomingEventCard } from "~/components/upcoming-event-card/upcoming-event-card";
import { registerVariableStatItem } from "~/components/variable-stat/variable-stat-item";
import { registerVideo } from "~/components/video/video";

registerCarousel();
registerButton();
registerTypography();
registerFooterCopyright();
registerLogoCarousel();
registerTextSectionGrowingCircle();
registerTextSectionParallaxCircle();
registerStickyHeader();
registerTestimonialCarousel();
registerVideo();
registerContactForm();
registerHeroSection();
registerServiceCard();
registerArticleList();
registerMap();
registerImageSlider();
registerImageRow();
registerNewsletterForm();
registerImageGallery();
registerArticleCard();
registerIconWithTextCard();
registerAccordion();
registerPredictometer();
registerInsightsCategoryTabs();
registerInsightsForm();
registerGoBackLink();
registerGuideCard();
registerPodcastHero();
registerPodcastContent();
registerPodcastCard();
registerBlogAuthor();
registerUpcomingEventCard();
registerPodcastFeaturedCard();
registerPagination();
registerPodcastSubscribeLinks();
registerBulletPoints();
registerFeaturedBlogPost();
registerBlogPostListTabs();
registerBlogPostList();
registerSocialLinksShare();
registerShareButton();
registerBlogTypography();
registerHubSpotForm();
registerCardListTabs();
registerCaseStudyList();
registerImageOverride();
registerTeamMemberList();
registerBannerPopup();
registerOldPostContent();
registerLinkBox();
registerVariableStatItem();
registerHomeAnimation();
registerCookiesBanner();

Builder.register("insertMenu", {
  name: "Blog Post Components",
  items: [
    {
      name: "FeaturedBlogPost",
    },
    {
      name: "BlogPostListTabs",
    },
    {
      name: "BlogPostList",
    },
  ],
});

Builder.register("insertMenu", {
  name: "UI Components",
  items: [
    {
      name: "BlogTypography",
    },
    {
      name: "Button",
    },
    {
      name: "BulletPoints",
    },
    {
      name: "Accordion",
    },
    {
      name: "StickyHeader",
    },
    {
      name: "Pagination",
    },
    {
      name: "ShareButton",
    },
    {
      name: "Typography",
    },
    {
      name: "SocialLinksShare",
    },
    {
      name: "LinkBox",
    },
    {
      name: "Home Screen Animation",
    },
  ],
});

Builder.register("insertMenu", {
  name: "Content Components",
  items: [
    {
      name: "Icon With Text Card",
    },
    {
      name: "ArticleCard",
    },
    {
      name: "ArticleList",
    },
    {
      name: "ContactForm",
    },
    {
      name: "Footer",
    },
    {
      name: "HeroSection",
    },
    {
      name: "ImageGallery",
    },
    {
      name: "InfiniteImageSlider",
    },
    {
      name: "Map",
    },
    {
      name: "Newsletter Form",
    },
    {
      name: "ServiceCard",
    },
    {
      name: "Testimonial Carousel",
    },
    {
      name: "Text Section Growing Circle",
    },
    {
      name: "Text Section Parallax Circle",
    },
    {
      name: "video",
    },
    {
      name: "ImageRow",
    },
    {
      name: "VariableStat",
    },
    {
      name: "Predictometer",
    },
    {
      name: "UpcomingEventCard",
    },
    {
      name: "Hero Carousel",
    },
    {
      name: "LogoCarousel",
    },
    {
      name: "GoBackLink",
    },
    {
      name: "SocialLinksShare",
    },
    {
      name: "HubSpotForm",
    },
    {
      name: "CardListTabs",
    },
    {
      name: "CaseStudyList",
    },
    {
      name: "TeamMemberList",
    },
    {
      name: "OldPostContent",
    },
    {
      name: "VariableStatItem",
    },
  ],
});

Builder.register("insertMenu", {
  name: "Insights Components",
  items: [
    {
      name: "InsightsCategoryTabs",
    },
    {
      name: "registerInsightsCategoryTabs",
    },
    {
      name: "Insights Form",
    },
    {
      name: "PodcastCard",
    },
    {
      name: "PodcastContent",
    },
    {
      name: "PodcastFeaturedCard",
    },
    {
      name: "PodcastHero",
    },
    {
      name: "PodcastSubscribeLinks",
    },
    {
      name: "GuideCard",
    },
  ],
});
