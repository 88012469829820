"use client";
import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import { Modal } from "~/components/ui/modal";
import { imageLoader } from "~/utils";

import { BlogTypography } from "../ui/blog-typography";
import { TypographyDeprecated } from "../ui/typography";
import type { TeamMembers } from "./team-member-card";

export function TeamMemberModal({
  bio,
  funFact,
  furryImage,
  furryName,
  image,
  linkedin,
  name,
  role,
}: TeamMembers) {
  const router = useRouter();
  const searchParams = useSearchParams();

  const handleClose = () => {
    const params = new URLSearchParams(searchParams);
    params.delete("team-member");
    const stringParams = params.size > 0 ? `?${params.toString()}` : "";
    router.push(`/who-we-are${stringParams}`, { scroll: false });
  };

  return (
    <Modal handleClose={handleClose}>
      <div className="flex flex-col">
        <div className="relative aspect-square">
          <Image
            src={image}
            fill
            className="object-cover"
            alt="Team member image"
            loader={imageLoader}
          />
        </div>
        <TypographyDeprecated
          variant="h5"
          className="mt-4 text-4xl text-brand-primary-4"
        >
          {name}
        </TypographyDeprecated>
        <TypographyDeprecated
          className="my-2 text-xl text-brand-primary-1"
          variant="subtitle"
        >
          {role}
        </TypographyDeprecated>
        <TypographyDeprecated
          variant="subtitle"
          className="mb-4 text-brand-secondary-3 underline"
        >
          <a href={linkedin}>LinkedIn</a>
        </TypographyDeprecated>
        <TypographyDeprecated variant="p" className="my-4">
          {bio}
        </TypographyDeprecated>
        {funFact && (
          <div className="my-4 flex">
            <BlogTypography
              variant="p1-large"
              className="mr-2 whitespace-nowrap uppercase"
            >
              Fun fact:
            </BlogTypography>
            <BlogTypography variant="p1-large" color={"primary-4"}>
              {funFact}
            </BlogTypography>
          </div>
        )}
        {furryImage && (
          <div className="relative my-4 flex size-80 items-center justify-center self-center rounded-full">
            <Image
              src={furryImage}
              fill
              className="aspect-square rounded-full object-cover"
              alt="Furry friend image"
              loader={imageLoader}
            />
          </div>
        )}
        {furryName && (
          <TypographyDeprecated
            color="primary"
            className="mb-14 mt-4 text-sm font-bold uppercase text-brand-primary-4"
            variant="subtitle"
            align="center"
          >
            Furry friend: {furryName}
          </TypographyDeprecated>
        )}
      </div>
    </Modal>
  );
}
