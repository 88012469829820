"use client";

import { Builder } from "@builder.io/sdk";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { Button } from "~/components/ui/button";

interface Props {
  loadMoreText: string;
  limit: number;
  total: number;
  nextPage?: number;
  onClick?: () => void;
  className?: string;
}

const Pagination = ({
  total,
  loadMoreText,
  limit,
  nextPage,
  onClick,
  className,
}: Props) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const currentPage = Number(searchParams.get("page")) ?? 0;

  const shouldShowPagination = nextPage
    ? nextPage > 0
    : limit * (currentPage + 1) < total;

  const createPageURL = (pageNumber: number | string) => {
    const params = new URLSearchParams(searchParams);
    params.set("page", pageNumber.toString());
    return `${pathname}?${params.toString()}`;
  };

  return shouldShowPagination ? (
    <Button asChild size="xs" onClick={onClick} className={className}>
      <Link
        scroll={false}
        href={createPageURL(nextPage ?? currentPage + 1)}
        prefetch
      >
        {loadMoreText}
      </Link>
    </Button>
  ) : null;
};

const registerPagination = () => {
  Builder.registerComponent(Pagination, {
    name: "Pagination",
    inputs: [
      {
        name: "loadMoreText",
        type: "string",
        defaultValue: "Load More",
      },
      {
        name: "limit",
        type: "number",
        defaultValue: 10,
      },
      {
        name: "total",
        type: "number",
        defaultValue: 0,
      },
      {
        name: "nextPage",
        type: "number",
      },
    ],
  });
};

export { Pagination, registerPagination };
