export const bgColor = {
  "primary-1": "bg-brand-primary-1",
  "primary-2": "bg-brand-primary-2",
  "primary-3": "bg-brand-primary-3",
  "primary-4": "bg-brand-primary-4",
  "secondary-1": "bg-brand-secondary-1",
  "secondary-2": "bg-brand-secondary-2",
  "secondary-3": "bg-brand-secondary-3",
  "secondary-4": "bg-brand-secondary-4",
  "secondary-5": "bg-brand-secondary-5",
  "secondary-6": "bg-brand-secondary-6",
  "secondary-7": "bg-brand-secondary-7",
  "light-neutral-1": "bg-neutral-light-1",
  "light-neutral-2": "bg-neutral-light-2",
  "mid-neutral-1": "bg-neutral-mid-1",
  "mid-neutral-2": "bg-neutral-mid-2",
  "mid-neutral-3": "bg-neutral-mid-3",
  error: "bg-error",
  success: "bg-success",
  warning: "bg-warning",
  informational: "bg-informational",
  white: "bg-white",
  black: "bg-black",
};
