import { useEffect, useMemo, useReducer, useRef } from "react";
import { flushSync } from "react-dom";

import { noop } from "~/utils";

interface UseVideoProps {
  muted?: boolean;
  autoplay?: boolean;
}

interface VideoState {
  isPaused: boolean;
  progress: number;
  downloadProgress: number;
  duration: number;
  muted: boolean;
  disableTransition: boolean;
  autoplay: boolean;
}

const initialState: VideoState = {
  isPaused: true,
  progress: 0,
  downloadProgress: 0,
  duration: 0,
  muted: false,
  disableTransition: false,
  autoplay: false,
};

const ACTIONS = {
  SET_IS_PAUSED: "SET_IS_PAUSED",
  SET_PROGRESS: "SET_PROGRESS",
  SET_DOWNLOAD_PROGRESS: "SET_DOWNLOAD_PROGRESS",
  SET_DURATION: "SET_DURATION",
  TOGGLE_MUTED: "TOGGLE_MUTED",
  SET_DISABLE_TRANSITION: "SET_DISABLE_TRANSITION",
  SET_AUTOPLAY: "SET_AUTOPLAY",
} as const;

type VideoAction =
  | { type: typeof ACTIONS.SET_IS_PAUSED; payload: boolean }
  | { type: typeof ACTIONS.SET_PROGRESS; payload: number }
  | { type: typeof ACTIONS.SET_DOWNLOAD_PROGRESS; payload: number }
  | { type: typeof ACTIONS.SET_DURATION; payload: number }
  | { type: typeof ACTIONS.TOGGLE_MUTED }
  | { type: typeof ACTIONS.SET_DISABLE_TRANSITION; payload: boolean }
  | { type: typeof ACTIONS.SET_AUTOPLAY; payload: boolean };

const videoReducer = (state: VideoState, action: VideoAction): VideoState => {
  switch (action.type) {
    case ACTIONS.SET_IS_PAUSED:
      return { ...state, isPaused: action.payload };
    case ACTIONS.SET_PROGRESS:
      return { ...state, progress: action.payload };
    case ACTIONS.SET_DOWNLOAD_PROGRESS:
      return { ...state, downloadProgress: action.payload };
    case ACTIONS.SET_DURATION:
      return { ...state, duration: action.payload };
    case ACTIONS.TOGGLE_MUTED:
      return { ...state, muted: !state.muted };
    case ACTIONS.SET_DISABLE_TRANSITION:
      return { ...state, disableTransition: action.payload };
    case ACTIONS.SET_AUTOPLAY:
      return { ...state, autoplay: action.payload };
    default:
      return state;
  }
};

const useVideo = ({
  muted: initialMuted = false,
  autoplay: initialAutoplay = false,
}: UseVideoProps) => {
  const ref = useRef<HTMLVideoElement>(null);
  const [state, dispatch] = useReducer(videoReducer, {
    ...initialState,
    autoplay: initialAutoplay,
    isPaused: !initialAutoplay,
    muted: initialMuted,
  });

  const {
    isPaused,
    progress,
    downloadProgress,
    duration,
    muted,
    disableTransition,
    autoplay,
  } = state;

  // Memoized transition style
  const transition = useMemo(() => {
    if (disableTransition) return "none";
    return `width ${duration / 100}s linear`;
  }, [disableTransition, duration]);

  useEffect(() => {
    const video = ref.current;
    if (!video) return;

    const updateProgress = () => {
      flushSync(() => {
        dispatch({
          type: ACTIONS.SET_DOWNLOAD_PROGRESS,
          payload: (video.buffered.end(0) / video.duration) * 100,
        });
      });
    };

    video.addEventListener("progress", updateProgress);

    return () => {
      video.removeEventListener("progress", updateProgress);
    };
  }, []);

  useEffect(() => {
    const video = ref.current;
    if (!video) return;

    const updateProgress = () => {
      flushSync(() => {
        if (video.currentTime === video.duration) {
          dispatch({ type: ACTIONS.SET_IS_PAUSED, payload: true });
        }

        dispatch({
          type: ACTIONS.SET_PROGRESS,
          payload: (video.currentTime / video.duration) * 100,
        });
        dispatch({
          type: ACTIONS.SET_DURATION,
          payload: video.duration,
        });
      });
    };

    video.addEventListener("timeupdate", updateProgress);

    return () => {
      video.removeEventListener("timeupdate", updateProgress);
    };
  }, []);

  // When iPhone/iPad autoplay is disabled, the initial video thumbnail is not shown
  // The code below is a workaround to fix this issue
  useEffect(() => {
    if (autoplay) return;
    const ua = navigator?.userAgent;
    if (!ua) return;

    let timeout: NodeJS.Timeout;

    const isIphone = ua.includes("iPhone") || ua.includes("iPad");

    if (isIphone) {
      dispatch({ type: ACTIONS.SET_AUTOPLAY, payload: true });

      timeout = setTimeout(() => {
        dispatch({ type: ACTIONS.SET_AUTOPLAY, payload: false });
      });
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [autoplay]);

  const selectProgress = (e: React.MouseEvent<HTMLDivElement>) => {
    flushSync(() => {
      dispatch({ type: ACTIONS.SET_DISABLE_TRANSITION, payload: true });
    });

    if (!ref.current) return;

    const { left, width } = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - left;
    const percent = clickX / width;
    const time = ref.current.duration * percent;
    ref.current.currentTime = time;

    flushSync(() => {
      dispatch({ type: ACTIONS.SET_DURATION, payload: ref.current!.duration });
      dispatch({
        type: ACTIONS.SET_PROGRESS,
        payload: (time / ref.current!.duration) * 100,
      });
    });

    requestIdleCallback(() => {
      dispatch({ type: ACTIONS.SET_DISABLE_TRANSITION, payload: false });
    });
  };

  const mute = () => {
    if (!ref.current) return;

    flushSync(() => {
      dispatch({ type: ACTIONS.TOGGLE_MUTED });
    });

    ref.current.muted = !muted;
  };

  const play = () => {
    ref.current
      ?.play()
      .then(() => {
        dispatch({ type: ACTIONS.SET_IS_PAUSED, payload: false });
      })
      .catch(noop);
  };

  const pause = () => {
    ref.current?.pause();
    dispatch({ type: ACTIONS.SET_IS_PAUSED, payload: true });
  };

  const rewind = () => {
    if (!ref.current) return;
    ref.current.currentTime -= 10;
  };

  const forward = () => {
    if (!ref.current) return;
    ref.current.currentTime += 10;
  };

  const fullScreen = () => {
    ref.current?.requestFullscreen().catch(noop);
  };

  const download = () => {
    ref.current?.requestFullscreen().catch(noop);
  };

  return {
    ref,
    play,
    pause,
    rewind,
    forward,
    fullScreen,
    download,
    mute,
    selectProgress,
    isPaused,
    progress,
    downloadProgress,
    duration,
    muted,
    transition,
    autoplay,
  };
};

export { useVideo };
