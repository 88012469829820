import { Builder } from "@builder.io/sdk";
import Link from "next/link";
import { PodcastCardBackground } from "~/components/podcast-card-background/podcast-card-background";
import { PodcastEpisodeCircle } from "~/components/podcast-episode-circle/podcast-episode-circle";
import { PodcastEpisodeText } from "~/components/podcast-episode-text/podcast-episode-text";
import { PodcastSubscribeLinks } from "~/components/podcast-subscribe-links/podcast-subscribe-links";
import { BlogTypography } from "~/components/ui/blog-typography";
import { type Podcast } from "~/types/podcast";

interface Props {
  podcast:
    | {
        value: {
          data: Podcast;
        };
        data: never;
      }
    | {
        value: never;
        data: Podcast;
      };
}

const PodcastFeaturedCard = ({ podcast }: Props) => {
  const data = podcast?.value?.data ?? podcast?.data;
  return (
    <section className="flex w-full flex-col gap-6">
      <header className="flex w-full flex-col-reverse items-center justify-between gap-6 md:flex-row">
        <BlogTypography variant="h1">Latest Episode</BlogTypography>
        <PodcastSubscribeLinks metadata={data?.metadata} color="white" />
      </header>

      <Link
        prefetch
        className="group relative flex w-full flex-col items-start gap-5 "
        href={data.url ?? ""}
      >
        <div className="relative h-[240px] w-full overflow-hidden md:h-[494px]">
          <PodcastCardBackground
            accentColor={data.accentColor}
            className="relative h-[240px] transition duration-300 ease-in-out group-hover:scale-105 md:h-[494px]"
          >
            <div className={"relative size-full overflow-hidden"}>
              <PodcastEpisodeCircle
                className="-left-4 top-10 scale-[0.86] md:left-[12%] md:top-1/4"
                episode={data.episode}
              />
            </div>
          </PodcastCardBackground>
        </div>

        <div className="mx-6 flex max-w-[340px] flex-col gap-2 overflow-hidden transition duration-300 ease-in-out group-hover:opacity-70 md:ml-0 md:max-w-none">
          <PodcastEpisodeText date={data.date} episode={data.episode} />
          <div className="flex flex-col gap-6 md:flex-row md:items-center md:justify-between md:gap-10">
            <BlogTypography variant="h1" mobileVariant="h5">
              {data.title}
            </BlogTypography>
          </div>
        </div>
      </Link>
    </section>
  );
};

const registerPodcastFeaturedCard = () => {
  Builder.registerComponent(PodcastFeaturedCard, {
    name: "PodcastFeaturedCard",
    inputs: [
      {
        name: "podcast",
        type: "reference",
        required: true,
        model: "podcast-post",
      },
    ],
  });
};

export { PodcastFeaturedCard, registerPodcastFeaturedCard };
