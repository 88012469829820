import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import { HubSpotForm } from "~/components/hubspot-form/hubspot-form";
import { BlogTypography } from "~/components/ui/blog-typography";

interface InsightsFormProps {
  title: string;
  subtitle: string;
  image: string;
  form: {
    region: string;
    portalId: string;
    formId: string;
  };
}

const InsightsForm = ({ title, subtitle, image, form }: InsightsFormProps) => {
  return (
    <form
      method="POST"
      onSubmit={(event) => {
        event.preventDefault();
      }}
      className="flex w-full flex-col bg-transparent  md:items-center"
    >
      <div className="flex max-w-[1054px] flex-wrap items-center gap-10 md:w-full">
        {image && (
          <div className="relative size-[354px]">
            <Image
              className="w-full object-cover"
              layout="fill"
              src={image}
              alt="Image"
            />
          </div>
        )}
        <div className="flex flex-col gap-8">
          <div className="flex w-full flex-col gap-4">
            <BlogTypography variant="h2" color="primary-4" align="center">
              {title}
            </BlogTypography>
            {subtitle && (
              <BlogTypography
                variant="p1-large"
                color="primary-4"
                align="center"
              >
                {subtitle}
              </BlogTypography>
            )}
          </div>
          <div className="flex w-full flex-col gap-6">
            <HubSpotForm {...form} />
          </div>
        </div>
      </div>
    </form>
  );
};

const registerInsightsForm = () => {
  Builder.registerComponent(InsightsForm, {
    name: "Insights Form",
    inputs: [
      {
        name: "title",
        type: "string",
        defaultValue: "Watch Webinar",
        required: true,
      },
      {
        name: "subtitle",
        type: "string",
        defaultValue: "We care about your data in our Privacy Policy.",
        required: false,
      },
      {
        name: "image",
        type: "file",
        allowedFileTypes: ["jpeg", "png", "svg"],
        required: false,
      },
      {
        name: "buttonText",
        type: "string",
        required: true,
        hideFromUI: true,
      },
      {
        name: "privacyLink",
        type: "string",
        required: true,
        hideFromUI: true,
      },
      {
        name: "form",
        type: "object",
        friendlyName: "HubSpot Form",
        subFields: [
          {
            name: "region",
            type: "string",
            required: true,
          },
          {
            name: "portalId",
            type: "string",
            required: true,
          },
          {
            name: "formId",
            type: "string",
            required: true,
          },
        ],
      },
    ],
  });
};

export { InsightsForm, registerInsightsForm };
