import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { TypographyDeprecated } from "~/components/ui/typography";

export interface TeamMembers {
  image: string;
  name: string;
  role: string;
  linkedin: string;
  bio: string;
  funFact?: string;
  furryImage: string;
  furryName: string;
  handle: string;
}

const TeamMemberCard = ({ image, name, role, handle }: TeamMembers) => {
  const searchParams = useSearchParams();

  const getSearchParams = (handle: string) => {
    const params = new URLSearchParams(searchParams);
    params.set("team-member", handle);
    return params.toString();
  };

  return (
    <Link scroll={false} href={{ search: getSearchParams(handle) }}>
      <div
        className="group relative flex h-[215px] w-[190px] items-end bg-cover bg-center grayscale transition-all hover:grayscale-0 md:h-[275px] md:w-[245px]"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="flex w-full flex-col items-start bg-white/90 p-2 group-hover:visible md:invisible">
          <TypographyDeprecated
            variant="subtitle"
            size="lg"
            color="primary"
            className="max-w-screen-lg text-[18px] md:text-[22.86px]"
          >
            {name}
          </TypographyDeprecated>
          <TypographyDeprecated
            variant="p"
            color="primary"
            size="sm"
            className="max-w-screen-lg text-[10px] md:text-[12.7px]"
          >
            {role}
          </TypographyDeprecated>
        </div>
      </div>
    </Link>
  );
};

export { TeamMemberCard };
