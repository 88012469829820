import { builder } from "@builder.io/sdk";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { getItemsByPage } from "~/utils";

async function getTeamMembers(limit: number, page: number, tag: string) {
  const data = await builder.getAll("team-member", {
    options: { includeRefs: true, offset: page * limit },
    limit,
    fields: "id,data",
    omit: "data.inputs,data.blocks",
    sort: { "data.name": 1 },
    query: {
      ...(tag !== "all" && {
        "data.name": {
          $regex: `^${tag}`,
          $options: "i",
        },
      }),
    },
  });

  return data as Array<{
    id: string;
    data: any;
  }>;
}

export const useTeamMembersQuery = ({
  items,
  limit,
  page = 0,
  tag = "all",
}: {
  items: Array<{
    id: string;
    data: any;
  }>;
  page?: number;
  tag: string;
  limit: number;
}) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    initialData: {
      pageParams: [0],
      pages: [items],
    },
    queryKey: ["team-member-list", { tag }] as const,
    queryFn: async ({ pageParam, queryKey }) => {
      if (pageParam === 0) {
        queryClient.setQueryData(["team-member-list", { tag }], {
          pageParams: [0],
          pages: [items],
        });
      }

      return await getTeamMembers(limit, pageParam, queryKey[1].tag);
    },
    initialPageParam: page,
    getNextPageParam: (_, items) => {
      const totalItemsToFetch = limit * (page + 1);
      const itemsByPage = getItemsByPage(items, page, limit);
      return itemsByPage.length === totalItemsToFetch ? page + 1 : undefined;
    },
  });
};
