import { Fragment } from "react";

import { Builder } from "@builder.io/sdk";
import Link from "next/link";
import { TypographyDeprecated } from "~/components/ui/typography";

interface FooterCopyrightProps {
  text: string;
  links: Array<{
    text: string;
    reference: {
      value: {
        data: {
          url: string;
        };
      };
    };
  }>;
}

const FooterCopyright = ({ text, links = [] }: FooterCopyrightProps) => {
  const filteredLinks = links.filter((link) => link?.text);

  return (
    <TypographyDeprecated variant="p" size="sm" color="white">
      {text}
      {!!(filteredLinks.length > 0) && (
        <span className="hidden sm:inline"> | </span>
      )}
      <span className="flex justify-center sm:inline">
        {filteredLinks.map((link, index) => (
          <Fragment key={index}>
            <Link
              href={link?.reference?.value?.data?.url ?? ""}
              className="text-brand-primary-3 hover:underline"
            >
              {link.text}
            </Link>
            {index < filteredLinks.length - 1 ? " | " : ""}
          </Fragment>
        ))}
      </span>
    </TypographyDeprecated>
  );
};

const registerFooterCopyright = () => {
  Builder.registerComponent(FooterCopyright, {
    name: "Footer Copyright",
    models: ["symbol"],
    inputs: [
      {
        name: "text",
        type: "string",
        required: true,
      },
      {
        name: "links",
        type: "list",
        required: true,
        subFields: [
          {
            name: "text",
            type: "string",
            required: true,
          },
          {
            name: "reference",
            type: "reference",
            required: true,
          },
        ],
      },
    ],
  });
};

export { registerFooterCopyright, FooterCopyright };
