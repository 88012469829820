"use client";

import { Builder } from "@builder.io/sdk";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import { Button } from "~/components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { type Tag } from "~/types/article";

interface Props {
  tags: Tag[];
}

const BlogPostListTabs = ({ tags }: Props) => {
  const searchParams = useSearchParams();
  const tag = searchParams.get("tag") ?? "all";
  const currentPage = Number(searchParams.get("page")) ?? 0;
  const router = useRouter();

  const createPageURL = (tag: string) => {
    const params = new URLSearchParams(searchParams);
    params.set("tag", tag.toString());
    currentPage > 0 && params.delete("page");
    return `?${params.toString()}`;
  };

  const handleTabClick = (newTab: string) => {
    router.push(createPageURL(newTab), { scroll: false });
  };

  return (
    <Tabs className="w-full" defaultValue={tag} onValueChange={handleTabClick}>
      <TabsList className="border-b-0 sm:border-b-0">
        <div className="flex flex-wrap justify-center gap-2 md:justify-start">
          <TabsTrigger value="all" asChild>
            <Button
              variant="outline"
              className="text-brand-primary-4 hover:border-brand-primary-3 hover:bg-brand-primary-3 hover:opacity-85 hover:brightness-100 data-[state=active]:border-brand-primary-3 data-[state=active]:bg-brand-primary-3 data-[state=active]:text-brand-primary-4"
            >
              All
            </Button>
          </TabsTrigger>
          {tags.map((tag, index) => (
            <TabsTrigger key={index} value={tag.handle} asChild>
              <Button
                variant="outline"
                className="text-brand-primary-4 hover:border-brand-primary-3 hover:bg-brand-primary-3 hover:opacity-85 hover:brightness-100 data-[state=active]:border-brand-primary-3 data-[state=active]:bg-brand-primary-3 data-[state=active]:text-brand-primary-4"
                asChild
              >
                <Link href={createPageURL(tag.handle)} scroll={false} prefetch>
                  {tag.name}
                </Link>
              </Button>
            </TabsTrigger>
          ))}
        </div>
      </TabsList>
    </Tabs>
  );
};

const registerBlogPostListTabs = () => {
  Builder.registerComponent(
    dynamic(
      async () =>
        await import("./blog-post-list-tabs").then(
          (mod) => mod.BlogPostListTabs
        ),
      {
        ssr: false,
      }
    ),
    {
      name: "BlogPostListTabs",
      defaults: {
        bindings: {
          "component.options.tags": "context.blogPostTags",
        },
      },
      inputs: [
        {
          name: "tags",
          type: "list",
          subFields: [
            {
              name: "tag",
              type: "reference",
              model: "tags",
            },
          ],
        },
      ],
    }
  );
};

export { BlogPostListTabs, registerBlogPostListTabs };
