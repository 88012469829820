import Link from "next/link";
import { usePathname } from "next/navigation";
import { cn } from "~/utils";

interface NavLinkProps extends React.ComponentProps<typeof Link> {
  exact?: boolean;
  activeClassName?: string;
  href: string;
}

const NavLink = ({
  children,
  exact,
  href,
  className,
  activeClassName,
  ...props
}: NavLinkProps) => {
  const pathname = usePathname();
  const isActive = exact ? pathname === href : pathname.startsWith(href);

  return (
    <Link
      href={href}
      {...props}
      className={cn(className, isActive && activeClassName)}
    >
      {children}
    </Link>
  );
};

export { NavLink };
