import { Builder } from "@builder.io/sdk";
import { type VariantProps, cva } from "class-variance-authority";
import { cn } from "~/utils";

import { Animate } from "../animation/animate";

const typographyVariants = cva("whitespace-pre-wrap", {
  variants: {
    variant: {
      h1: "text-[2.875rem] leading-normal md:text-[4rem] md:leading-[108%]",
      h2: "text-4xl leading-normal md:text-[2.875rem]",
      h3: "text-4xl leading-normal",
      h4: "text-[1.75rem] leading-normal",
      h4alt:
        "font-serif text-[1.75rem] italic leading-[126.923%] tracking-[0.26px]",
      h5: "text-[1.3125rem] leading-[138.095%] tracking-[0.105px]",
      h6: "text-[1.125rem] tracking-[0.054px]",
      p: "",
      subtitle: "",
      "field-label": "",
      "button-text": "text-sm tracking-[0.175px]",
    },
    color: {
      primary: "text-brand-primary-4",
      secondary: "text-brand-secondary-4",
      grey: "text-brand-primary-1",
      white: "text-white",
      black: "text-black",
    },
    size: {
      xl: "",
      lg: "",
      medium: "",
      sm: "",
      xs: "",
    },
    align: {
      default: "",
      left: "text-left",
      center: "text-center",
      right: "text-right",
    },
    fontFamily: {
      default: "",
      sans: "font-sans",
      serif: "font-serif",
      mono: "font-mono",
    },
    fontWeight: {
      default: "",
      regular: "font-normal",
      bold: "font-bold",
    },
  },
  compoundVariants: [
    {
      variant: "field-label",
      size: "xl",
      className: "text-base uppercase leading-5 tracking-[0.8px]",
    },
    {
      variant: "field-label",
      size: "lg",
      className: "text-base leading-5 tracking-[0.04px]",
    },
    {
      variant: "field-label",
      size: "medium",
      className: "text-[0.875rem] leading-[128.571%] tracking-[0.042px]",
    },
    {
      variant: "field-label",
      size: "sm",
      className: "text-[0.75rem] leading-[150%] tracking-[0.036px]",
    },
    {
      variant: "p",
      size: "lg",
      className: "text-lg leading-[144.444%] tracking-[0.09px]",
    },
    {
      variant: "p",
      size: "medium",
      className: "text-sm leading-4",
    },
    {
      variant: "p",
      size: "sm",
      className: "text-xs leading-4 tracking-[0.06px]",
    },
    {
      variant: "p",
      size: "xs",
      className: "text-[0.625rem] tracking-[0.05px]",
    },
    {
      variant: "subtitle",
      size: "lg",
      className: "text-[1.3125rem] font-bold leading-6 tracking-[0.158px]",
    },
    {
      variant: "subtitle",
      size: "medium",
      className:
        "text-base font-bold uppercase leading-[131.25%] tracking-[1.6px]",
    },
    {
      variant: "subtitle",
      size: "sm",
      className: "text-sm font-bold uppercase leading-normal tracking-[0.7px]",
    },
  ],
  defaultVariants: {
    variant: "p",
  },
});

export interface TypographyProps
  extends VariantProps<typeof typographyVariants> {
  children: React.ReactNode;
  fontSize?: number;
  className?: string;
  style?: React.CSSProperties;
  as?: "p" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  title?: string;
  animated?: boolean;
}

const TypographyDeprecated = ({
  variant,
  children,
  color,
  size,
  className,
  fontSize,
  fontFamily,
  fontWeight,
  style,
  align,
  as,
  title,
  animated,
}: TypographyProps) => {
  const getComponent = () => {
    if (as) return as;

    if (variant === "h4alt") return "h4";

    if (!variant || variant === "field-label" || variant === "subtitle") {
      return "p";
    }

    if (variant === "button-text") return "span";

    return variant;
  };

  const Comp = getComponent();

  const typographyProps = {
    title,
    style: { ...style, fontSize },
    className: cn(
      typographyVariants({
        variant,
        color,
        size,
        className,
        align,
        fontFamily,
        fontWeight,
      })
    ),
  };

  return animated ? (
    <Animate type="appear-up">
      <Comp {...typographyProps}>{children}</Comp>
    </Animate>
  ) : (
    <Comp {...typographyProps}>{children}</Comp>
  );
};

const registerTypography = () => {
  Builder.registerComponent(TypographyDeprecated, {
    name: "Typography",
    hideFromInsertMenu: true,
    inputs: [
      {
        name: "children",
        friendlyName: "Content",
        helperText: "Content of the text",
        type: "longText",
        required: true,
      },
      {
        name: "variant",
        friendlyName: "Variant",
        type: "string",
        defaultValue: "p",
        required: true,
        enum: [
          {
            label: "Heading 1",
            value: "h1",
          },
          {
            label: "Heading 2",
            value: "h2",
          },
          {
            label: "Heading 3",
            value: "h3",
          },
          {
            label: "Heading 4",
            value: "h4",
          },
          {
            label: "Heading 4 alt",
            value: "h4alt",
          },
          {
            label: "Heading 4 alt",
            value: "h4alt",
          },
          {
            label: "Heading 5",
            value: "h5",
          },
          {
            label: "Heading 6",
            value: "h6",
          },
          {
            label: "Paragraph",
            value: "p",
          },
          {
            label: "Subtitle",
            value: "subtitle",
          },
          {
            label: "Field Label",
            value: "field-label",
          },
          {
            label: "Button Text",
            value: "button-text",
          },
        ],
      },
      {
        name: "size",
        friendlyName: "Size",
        type: "string",
        defaultValue: "medium",
        showIf: (options) =>
          options.get("variant") === "p" ||
          options.get("variant") === "field-label" ||
          options.get("variant") === "subtitle",
        enum: [
          {
            label: "Extra Large",
            value: "xl",
          },
          {
            label: "Large",
            value: "lg",
          },
          {
            label: "Medium",
            value: "medium",
          },
          {
            label: "Small",
            value: "sm",
          },
          {
            label: "Extra Small",
            value: "xs",
          },
        ],
      },
      {
        name: "align",
        friendlyName: "Alignment",
        type: "string",
        defaultValue: "default",
        enum: [
          {
            label: "Default",
            value: "default",
          },
          {
            label: "Left",
            value: "left",
          },
          {
            label: "Center",
            value: "center",
          },
          {
            label: "Right",
            value: "right",
          },
        ],
      },
      {
        name: "fontSize",
        friendlyName: "Font Size",
        type: "number",
      },
      {
        name: "fontWeight",
        friendlyName: "Font Weight",
        type: "string",
        defaultValue: "default",
        enum: [
          {
            label: "Default",
            value: "default",
          },
          {
            label: "Regular",
            value: "regular",
          },
          {
            label: "Bold",
            value: "bold",
          },
        ],
      },
      {
        name: "fontFamily",
        friendlyName: "Font Family",
        type: "string",
        defaultValue: "default",
        enum: [
          {
            label: "Default",
            value: "default",
          },
          {
            label: "Sans",
            value: "sans",
          },
          {
            label: "Serif",
            value: "serif",
          },
          {
            label: "Monospace",
            value: "mono",
          },
        ],
      },
      {
        name: "color",
        friendlyName: "Color",
        type: "string",
        defaultValue: "primary",
        required: true,
        enum: [
          {
            label: "Primary",
            value: "primary",
          },
          {
            label: "Secondary",
            value: "secondary",
          },
          {
            label: "White",
            value: "white",
          },
          {
            label: "Black",
            value: "black",
          },
        ],
      },
      {
        name: "animated",
        type: "boolean",
        defaultValue: false,
      },
    ],
  });
};

export { TypographyDeprecated, registerTypography };
