"use client";

import { Builder } from "@builder.io/sdk";
import Link from "next/link";
import { useParams, useRouter } from "next/navigation";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { cn } from "~/utils";

interface Props {
  categories: Array<{
    name: string;
    handle: string;
    order: number;
  }>;
}

const InsightsCategoryTabs = ({ categories = [] }: Props) => {
  const router = useRouter();

  const params = useParams();
  const getDefaultValue = () =>
    !params?.content || params?.content?.[0] === "home"
      ? "all"
      : params?.content?.[0];

  const handleTabClick = (newTab: string) =>
    newTab === "all"
      ? router.push(`/insights/home`, { scroll: false })
      : router.push(`/insights/${newTab}`, { scroll: false });

  return (
    <Tabs
      className="w-full"
      defaultValue={getDefaultValue()}
      onValueChange={handleTabClick}
    >
      <TabsList
        className={cn(
          "flex w-full max-w-[100dvw] flex-wrap items-center justify-center gap-9 gap-y-3 overflow-auto border-b-2 border-b-neutral-light-2 pb-3 sm:overflow-hidden sm:px-3 md:gap-8 md:pb-0"
        )}
      >
        <TabsTrigger
          className="gap-1 text-xs font-bold hover:text-brand-primary-4 group-data-[state=active]:min-w-10 md:gap-2 md:text-xl md:group-data-[state=active]:min-w-[76px]"
          value="all"
        >
          All
          <div className="h-[3px] w-full bg-transparent group-data-[state=active]:bg-brand-secondary-2 md:h-1" />
        </TabsTrigger>
        {categories?.map((tag, index) => (
          <TabsTrigger
            key={index}
            value={tag?.handle ?? ""}
            className="gap-1 hover:text-brand-primary-4 md:gap-2 md:text-xl"
          >
            <Link href={`/insights/${tag?.handle}`} scroll={false} prefetch>
              {tag?.name}
            </Link>
            <div className="h-[3px] w-full bg-transparent group-data-[state=active]:bg-brand-secondary-2 md:h-1" />
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

const registerInsightsCategoryTabs = () => {
  Builder.registerComponent(InsightsCategoryTabs, {
    name: "InsightsCategoryTabs",
    defaults: {
      bindings: {
        "component.options.categories": "context.categories",
      },
    },
    inputs: [
      {
        name: "categories",
        type: "list",
        hideFromUI: true,
        subFields: [
          { name: "name", type: "string" },
          { name: "handle", type: "string" },
          { name: "order", type: "number" },
        ],
      },
    ],
  });
};

export { InsightsCategoryTabs, registerInsightsCategoryTabs };
