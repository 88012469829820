import { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";

import { withChildren } from "@builder.io/react";
import { Builder } from "@builder.io/sdk";
import { usePathname } from "next/navigation";
import { Button } from "~/components/ui/button";
import Portal from "~/components/ui/portals";

import { bgColor } from "./type";

interface Props {
  stickyRoutes?: Array<{ route: string }>;
  children: React.ReactNode;
  backgroundColor: keyof typeof bgColor;
  enabled: boolean;
}

const BannerPopup = ({
  children,
  stickyRoutes,
  backgroundColor = "primary-3",
  enabled = false,
}: Props) => {
  const pathname = usePathname();
  const isSticky = stickyRoutes?.some((stickyRoute) =>
    pathname.startsWith(stickyRoute.route)
  );

  const [bannerDismissed, setBannerDismissed] = useState(true);

  useEffect(() => {
    const fetchBannerDismissed = async () => {
      try {
        const bannerDismissed: boolean = await fetch("/api", {
          headers: {
            "banner-message": encodeURIComponent(
              ReactDOMServer.renderToStaticMarkup(children) ?? "banner"
            ),
          },
          method: "GET",
        }).then(
          async (res) => await res.json().then((data) => data.bannerDismissed)
        );
        setBannerDismissed(bannerDismissed);
      } catch (e) {
        console.error(e);
        setBannerDismissed(false);
      }
    };

    void fetchBannerDismissed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!enabled || (!isSticky && bannerDismissed)) return null;

  const dismissBanner = async () => {
    setBannerDismissed(true);
    try {
      await fetch("/api", {
        headers: {
          "banner-message": encodeURIComponent(
            ReactDOMServer.renderToStaticMarkup(children) ?? "banner"
          ),
        },
        method: "POST",
      });
    } catch (e) {
      console.error(e);
    }
  };

  const className = `${bgColor[backgroundColor]} relative z-50 flex w-full flex-row justify-between items-center p-4 transition-all duration-300`;

  const Banner = ({ id }: { id?: string }) => (
    <div id={id} className={className}>
      <div className="w-full break-words">{children}</div>
      {!isSticky && (
        <Button
          variant="ghost"
          onClick={() => {
            void dismissBanner();
          }}
          className="relative mt-[-7px] cursor-pointer self-start text-white transition-colors duration-300 hover:text-brand-primary-3 md:my-auto"
        >
          {closeIcon}
        </Button>
      )}
    </div>
  );

  return isSticky ? (
    <Portal container="#sticky-banner">
      <Banner />
    </Portal>
  ) : (
    <Portal container="#top-banner">
      <Banner id="banner" />
    </Portal>
  );
};

const registerBannerPopup = () => {
  Builder.registerComponent(withChildren(BannerPopup), {
    name: "BannerPopup",
    defaultChildren: [
      {
        "@type": "@builder.io/sdk:Element",
        component: {
          name: "BlogTypography",
          options: {
            text: "Title",
          },
        },
      },
    ],
    inputs: [
      {
        name: "enabled",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "stickyRoutes",
        type: "list",
        subFields: [
          {
            name: "route",
            type: "string",
            defaultValue: "/",
            required: true,
          },
        ],
      },
      {
        name: "backgroundColor",
        friendlyName: "Color",
        type: "string",
        defaultValue: "primary",
        required: true,
        enum: [
          {
            label: "Primary 1",
            value: "primary-1",
          },
          {
            label: "Primary 2",
            value: "primary-2",
          },
          {
            label: "Primary 3",
            value: "primary-3",
          },
          {
            label: "Primary 4",
            value: "primary-4",
          },
          {
            label: "Secondary 1",
            value: "secondary-1",
          },
          {
            label: "Secondary 2",
            value: "secondary-2",
          },
          {
            label: "Secondary 3",
            value: "secondary-3",
          },
          {
            label: "Secondary 4",
            value: "secondary-4",
          },
          {
            label: "Secondary 5",
            value: "secondary-5",
          },
          {
            label: "Secondary 6",
            value: "secondary-6",
          },
          {
            label: "Secondary 7",
            value: "secondary-7",
          },
          {
            label: "Light Neutral 1",
            value: "light-neutral-1",
          },
          {
            label: "Light Neutral 2",
            value: "light-neutral-2",
          },
          {
            label: "Mid Neutral 1",
            value: "mid-neutral-1",
          },
          {
            label: "Mid Neutral 2",
            value: "mid-neutral-2",
          },
          {
            label: "Mid Neutral 3",
            value: "mid-neutral-3",
          },
          {
            label: "Error",
            value: "error",
          },
          {
            label: "Success",
            value: "success",
          },
          {
            label: "Warning",
            value: "warning",
          },
          {
            label: "Informational",
            value: "informational",
          },
          {
            label: "White",
            value: "white",
          },
          {
            label: "Black",
            value: "black",
          },
        ],
      },
    ],
  });
};

export { BannerPopup, registerBannerPopup };

export const closeIcon = (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.8975 13.4336L13.1024 25.2287M13.1024 13.4336L24.8975 25.2287"
      stroke="white"
      strokeWidth="1.96585"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
