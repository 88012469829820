import { useRef } from "react";

import { motion, useScroll, useTransform } from "framer-motion";

interface Props {
  children: React.ReactNode;
  offset?: any;
}

export const Parallax = ({
  children,
  offset = ["start start", "end start"],
}: Props) => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset,
  });

  const opacity = useTransform(scrollYProgress, [0, 0.1, 0.9, 1], [0, 1, 1, 0]);

  const top = useTransform(
    scrollYProgress,
    [0, 0.2, 0.8, 1],
    [200, 0, 0, -200]
  );

  return (
    <div className="relative h-screen w-screen" ref={ref}>
      <motion.div
        style={{ opacity, top }}
        className="fixed left-0 top-0 flex size-full w-full flex-col justify-center px-6"
      >
        {children}
      </motion.div>
    </div>
  );
};
