"use client";

import { Builder } from "@builder.io/sdk";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from "@react-google-maps/api";

interface Props {
  mapId: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  scrollwheel: boolean;
  zoom: number;
  markers: Array<{
    coordinates: {
      lat: number;
      lng: number;
    };
    title: string;
    description: string;
  }>;
}

const Map = ({
  coordinates,
  mapId,
  markers,
  scrollwheel = true,
  zoom,
}: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
  });

  if (!isLoaded)
    return <div className="h-96 w-full animate-pulse bg-brand-primary-1/30" />;

  return (
    <div className="h-96 w-full">
      <GoogleMap
        id="google-map"
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        center={coordinates}
        zoom={zoom}
        options={{
          scrollwheel,
          mapId,
          disableDefaultUI: true,
          gestureHandling: "cooperative",
        }}
      >
        <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
          {() => (
            <>
              {markers.map((marker, index) => (
                <Marker
                  key={index}
                  position={marker.coordinates}
                  icon="/images/icons/map-pin-marker.png"
                />
              ))}
            </>
          )}
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
};

const registerMap = () => {
  Builder.registerComponent(Map, {
    name: "Map",
    inputs: [
      {
        name: "mapId",
        friendlyName: "Map style ID",
        type: "string",
      },
      {
        name: "coordinates",
        helperText: "Initial coordinates for the map",
        type: "object",
        subFields: [
          {
            name: "lat",
            friendlyName: "Latitude",
            type: "number",
          },
          {
            name: "lng",
            friendlyName: "Longitude",
            type: "number",
          },
        ],
      },
      {
        name: "scrollwheel",
        type: "boolean",
      },
      {
        name: "zoom",
        type: "number",
      },
      {
        name: "markers",
        type: "list",
        subFields: [
          {
            name: "coordinates",
            type: "object",
            subFields: [
              {
                name: "lat",
                friendlyName: "Latitude",
                type: "number",
              },
              {
                name: "lng",
                friendlyName: "Longitude",
                type: "number",
              },
            ],
          },
        ],
      },
    ],
  });
};

export { Map, registerMap };
