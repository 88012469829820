import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  type EmblaOptionsType,
  type EmblaCarouselType as CarouselApi,
  type EmblaOptionsType as CarouselOptions,
  type EmblaPluginType as CarouselPlugin,
} from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { Button } from "~/components/ui/button";
import { cn } from "~/utils";

interface CarouselProps {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin[];
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  isSlideActive: (index: number) => boolean;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  current: number;
  scrollTo: (index: number) => void;
  slideNodes: () => HTMLElement[];
} & CarouselProps;

const CarouselContext = createContext<CarouselContextProps | null>(null);

export function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

const Carousel = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = "horizontal",
      opts = {},
      setApi,
      plugins,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const options: Partial<EmblaOptionsType> = {
      axis: orientation === "horizontal" ? "x" : "y",
      loop: true,
      ...opts,
    };
    const [carouselRef, api] = useEmblaCarousel(options, plugins);
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const [current, setCurrent] = useState(0);

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCurrent(api.selectedScrollSnap());
      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollTo = useCallback(
      (index: number) => api?.scrollTo(index),
      [api]
    );

    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const isSlideActive = useCallback(
      (index: number) => {
        return index === current;
      },
      [current]
    );

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext]
    );

    useEffect(() => {
      if (!api || !setApi) return;

      setApi(api);
    }, [api, setApi]);

    useEffect(() => {
      if (!api) return;

      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);

    const slideNodes = useCallback(() => {
      if (!api) return [];

      return api.slideNodes();
    }, [api]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api,
          opts: options,
          orientation:
            orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          isSlideActive,
          canScrollPrev,
          canScrollNext,
          current,
          scrollTo,
          slideNodes,
        }}
      >
        <div
          {...props}
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn("relative", className)}
          role="region"
          aria-roledescription="carousel"
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  }
);
Carousel.displayName = "Carousel";

const CarouselContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    containerClassName?: string;
  }
>(({ className, containerClassName, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div
      ref={carouselRef}
      className={cn("h-full overflow-hidden", containerClassName)}
    >
      <div
        ref={ref}
        className={cn(
          "flex",
          orientation === "vertical" && "flex-col",
          className
        )}
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = "CarouselContent";

const CarouselItem = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    showSkeleton?: boolean;
  }
>(({ className, showSkeleton = false, ...props }, ref) => {
  const { api } = useCarousel();

  return !api && showSkeleton ? (
    <div
      className={cn(
        className,
        "min-w-0 shrink-0 grow-0 !basis-full animate-pulse bg-brand-primary-1/40"
      )}
    />
  ) : (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn("min-w-0 shrink-0 grow-0", className)}
      {...props}
    />
  );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(
  (
    {
      className,
      variant = "primary",
      icon,
      size = "default",
      iconUrl = ARROW_LEFT_URL,
      iconClassName,
      ...props
    },
    ref
  ) => {
    const { orientation, scrollPrev } = useCarousel();

    return (
      <Button
        ref={ref}
        variant={variant}
        icon={icon}
        size={size}
        className={cn(
          "absolute",
          orientation === "horizontal"
            ? "-left-12 top-1/2 -translate-y-1/2"
            : "-top-12 left-1/2 -translate-x-1/2 rotate-90",
          className
        )}
        onClick={scrollPrev}
        iconUrl={iconUrl}
        iconClassName={iconClassName}
        {...props}
      >
        <span className="sr-only">Previous slide</span>
      </Button>
    );
  }
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(
  (
    {
      className,
      variant = "primary",
      icon,
      size = "default",
      iconUrl = ARROW_RIGHT_URL,
      iconClassName,
      ...props
    },
    ref
  ) => {
    const { orientation, scrollNext } = useCarousel();
    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        icon={icon}
        className={cn(
          "absolute",
          orientation === "horizontal"
            ? "-right-12 top-1/2 -translate-y-1/2"
            : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
          className
        )}
        onClick={scrollNext}
        iconUrl={iconUrl}
        iconClassName={iconClassName}
        {...props}
      >
        <span className="sr-only">Next slide</span>
      </Button>
    );
  }
);
CarouselNext.displayName = "CarouselNext";

interface CarouselDotsProps {
  className?: string;
  dotClassName?: string;
}

const CarouselDots = ({ className, dotClassName }: CarouselDotsProps) => {
  const { scrollTo, slideNodes, current } = useCarousel();
  const nodes = slideNodes();

  const goToSlide = (index: number) => () => {
    scrollTo(index);
  };

  return (
    <div className={cn(" flex h-5  gap-4", className)}>
      {nodes?.map((_, index) => (
        <button
          key={index}
          className={cn(
            "size-2 rounded-full bg-white/40 transition-all duration-300",
            dotClassName,
            {
              "bg-white": current === index,
            }
          )}
          onClick={goToSlide(index)}
        />
      ))}
    </div>
  );
};

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselDots,
};

const ARROW_LEFT_URL = "/images/icons/arrow-left.svg";
const ARROW_RIGHT_URL = "/images/icons/arrow-right.svg";
