import { Builder } from "@builder.io/sdk";
import { type VariantProps, cva } from "class-variance-authority";
import { type BuilderDefaultProps } from "~/types/builder.types";
import { cn } from "~/utils";

import { Animate } from "../animation/animate";

const typographyVariants = cva("typography whitespace-pre-wrap", {
  variants: {
    variant: {
      h0: "text-[64px] font-[400] leading-[69.12px]",
      h1: "text-[46px] font-[400] leading-[55.2px]",
      h2: "text-[36px] font-[400] leading-[43.2px]",
      h3: "text-[26px] font-[400] italic leading-[33px] tracking-[0.01em]",
      h4: "text-[28px] font-[400] leading-[33.6px]",
      h5: "text-[21px] font-[400] leading-[29px] tracking-[0.005em]",
      h6: "text-[18px] font-[400] leading-[21.6px] tracking-[0.003em]",
      "field-label-caps":
        "text-[16px] font-[400] uppercase leading-[20px] tracking-wider",
      "label-large":
        "text-[16px] font-[400] leading-[20px] tracking-[0.0025em]",
      "label-medium":
        "text-[14px] font-[400] leading-[18px] tracking-[0.003em]",
      "label-small": "text-[12px] font-[400] leading-[18px] tracking-[0.003em]",
      "subtitle-large":
        "text-[21px] font-[700] leading-[24px] tracking-[0.0075em]",
      "subtitle-medium":
        "text-[16px] font-[700] uppercase leading-[21px] tracking-widest",
      "subtitle-small":
        "text-[14px] font-[700] uppercase leading-[16.8px] tracking-wider",
      "p1-large": "text-[18px] font-[400] leading-[26px] tracking-[0.005em]",
      "p2-medium": "text-[14px] font-[400] leading-[16px]",
      "p3-small": "text-[12px] font-[400] leading-[16px] tracking-[0.005em]",
      "p3-xs": "text-[10px] font-[400] leading-[12px] tracking-[0.005em]",
      "button-text":
        "text-[14px] font-[400] leading-[16.8px] tracking-[0.0125em]",
    },
    color: {
      "primary-1": "text-brand-primary-1",
      "primary-2": "text-brand-primary-2",
      "primary-3": "text-brand-primary-3",
      "primary-4": "text-brand-primary-4",
      "secondary-1": "text-brand-secondary-1",
      "secondary-2": "text-brand-secondary-2",
      "secondary-3": "text-brand-secondary-3",
      "secondary-4": "text-brand-secondary-4",
      "secondary-5": "text-brand-secondary-5",
      "secondary-6": "text-brand-secondary-6",
      "secondary-7": "text-brand-secondary-7",
      "light-neutral-1": "text-neutral-light-1",
      "light-neutral-2": "text-neutral-light-2",
      "mid-neutral-1": "text-neutral-mid-1",
      "mid-neutral-2": "text-neutral-mid-2",
      "mid-neutral-3": "text-neutral-mid-3",
      error: "text-error",
      success: "text-success",
      warning: "text-warning",
      informational: "text-informational",
      white: "text-white",
      black: "text-black",
    },
    align: {
      default: "",
      left: "text-left",
      center: "text-center",
      right: "text-right",
    },
    fontFamily: {
      default: "",
      sans: "font-sans",
      serif: "font-serif italic",
      mono: "font-mono",
    },
    fontWeight: {
      default: "",
      regular: "font-normal",
      bold: "font-bold",
    },
  },
  defaultVariants: {
    variant: "p2-medium",
  },
});

export type ColorVariant =
  | "primary-1"
  | "primary-2"
  | "primary-3"
  | "primary-4"
  | "secondary-1"
  | "secondary-2"
  | "secondary-3"
  | "secondary-4"
  | "secondary-5"
  | "secondary-6"
  | "secondary-7"
  | "light-neutral-1"
  | "light-neutral-2"
  | "mid-neutral-1"
  | "mid-neutral-2"
  | "mid-neutral-3"
  | "error"
  | "success"
  | "warning"
  | "informational"
  | "white"
  | "black";

export type TypographyVariant =
  | "h0"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "field-label-caps"
  | "label-large"
  | "label-medium"
  | "label-small"
  | "subtitle-large"
  | "subtitle-medium"
  | "subtitle-small"
  | "p1-large"
  | "p2-medium"
  | "p3-small"
  | "p3-xs"
  | "button-text";

export interface BlogTypographyProps
  extends VariantProps<typeof typographyVariants>,
    BuilderDefaultProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  as?: "p" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  title?: string;
  animated?: boolean;
  mobileVariant?: TypographyVariant | "";
  variant: TypographyVariant;
}

const Typography = ({
  variant,
  mobileVariant = "",
  children,
  color,
  className,
  fontFamily,
  fontWeight,
  style,
  align,
  as,
  title,
  animated,
}: BlogTypographyProps) => {
  const getComponent = (variant?: TypographyVariant) => {
    if (typeof children === "string" && children.includes("<")) return "div";
    if (as) return as;

    const pList = [
      "p1-large",
      "p2-medium",
      "p3-small",
      "p3-xs",
      "field-label-caps",
      "subtitle-large",
      "subtitle-medium",
      "subtitle-small",
      "label-large",
      "label-medium",
      "label-small",
    ];

    if (!variant || pList.includes(variant)) return "p";

    const headerMap = new Map([
      ["h0", "h1"],
      ["h1", "h1"],
      ["h2", "h2"],
      ["h3", "h3"],
      ["h4", "h4"],
      ["h5", "h5"],
      ["h6", "h6"],
    ]);

    if (headerMap.get(variant)) return headerMap.get(variant)!;

    if (variant === "button-text") return "span";

    return variant;
  };

  const CompDesktop = getComponent(variant);
  const CompMobile = getComponent(mobileVariant || variant);

  const getTypographyProps = (
    variant: TypographyVariant,
    type: "desktop" | "mobile"
  ) => ({
    title,
    style: { ...style },
    className: cn(
      typographyVariants({
        variant,
        color,
        className,
        align,
        fontFamily,
        fontWeight,
      }),
      {
        "hidden md:[display:-webkit-box] md:[-webkit-box-orient:vertical]":
          type === "desktop",
        "[-webkit-box-orient:vertical] [display:-webkit-box] md:hidden":
          type === "mobile",
      }
    ),
    ...(typeof children === "object" && { children }),
    ...((typeof children === "string" || typeof children === "number") && {
      dangerouslySetInnerHTML: { __html: children },
    }),
  });

  return animated ? (
    <Animate type="appear-up">
      <CompDesktop
        {...(getTypographyProps(
          variant,
          "desktop"
        ) as React.HTMLAttributes<HTMLElement>)}
      />

      <CompMobile
        {...(getTypographyProps(
          mobileVariant || variant,
          "mobile"
        ) as React.HTMLAttributes<HTMLElement>)}
      />
    </Animate>
  ) : (
    <>
      <CompDesktop
        {...(getTypographyProps(
          variant,
          "desktop"
        ) as React.HTMLAttributes<HTMLElement>)}
      />

      <CompMobile
        {...(getTypographyProps(
          mobileVariant || variant,
          "mobile"
        ) as React.HTMLAttributes<HTMLElement>)}
      />
    </>
  );
};

const registerBlogTypography = () => {
  Builder.registerComponent(Typography, {
    name: "BlogTypography",
    friendlyName: "Typography",
    inputs: [
      {
        name: "variant",
        friendlyName: "Variant",
        type: "string",
        defaultValue: "p1-large",
        required: true,
        enum: [
          {
            label: "Heading 0",
            value: "h0",
          },
          {
            label: "Heading 1",
            value: "h1",
          },
          {
            label: "Heading 2",
            value: "h2",
          },
          {
            label: "Heading 3",
            value: "h3",
          },
          {
            label: "Heading 4",
            value: "h4",
          },
          {
            label: "Heading 5",
            value: "h5",
          },
          {
            label: "Heading 6",
            value: "h6",
          },
          {
            label: "FIELD LABEL CAPS",
            value: "field-label-caps",
          },
          {
            label: "Label Large",
            value: "label-large",
          },
          {
            label: "Label Medium",
            value: "label-medium",
          },
          {
            label: "Label Small",
            value: "label-small",
          },
          {
            label: "Subtitle Large",
            value: "subtitle-large",
          },
          {
            label: "SUBTITLE MEDIUM",
            value: "subtitle-medium",
          },
          {
            label: "Subtitle Small",
            value: "subtitle-small",
          },
          {
            label: "Paragraph 1 Large",
            value: "p1-large",
          },
          {
            label: "Paragraph 2 Medium",
            value: "p2-medium",
          },
          {
            label: "Paragraph 3 Small",
            value: "p3-small",
          },
          {
            label: "Paragraph 3 XS",
            value: "p3-xs",
          },
          {
            label: "Button Text",
            value: "button-text",
          },
        ],
      },
      {
        name: "mobileVariant",
        friendlyName: "Mobile Variant",
        type: "string",
        defaultValue: "",
        enum: [
          {
            label: "Same as desktop",
            value: "",
          },
          {
            label: "Heading 0",
            value: "h0",
          },
          {
            label: "Heading 1",
            value: "h1",
          },
          {
            label: "Heading 2",
            value: "h2",
          },
          {
            label: "Heading 3",
            value: "h3",
          },
          {
            label: "Heading 4",
            value: "h4",
          },
          {
            label: "Heading 5",
            value: "h5",
          },
          {
            label: "Heading 6",
            value: "h6",
          },
          {
            label: "FIELD LABEL CAPS",
            value: "field-label-caps",
          },
          {
            label: "Label Large",
            value: "label-large",
          },
          {
            label: "Label Medium",
            value: "label-medium",
          },
          {
            label: "Label Small",
            value: "label-small",
          },
          {
            label: "Subtitle Large",
            value: "subtitle-large",
          },
          {
            label: "SUBTITLE MEDIUM",
            value: "subtitle-medium",
          },
          {
            label: "Subtitle Small",
            value: "subtitle-small",
          },
          {
            label: "Paragraph 1 Large",
            value: "p1-large",
          },
          {
            label: "Paragraph 2 Medium",
            value: "p2-medium",
          },
          {
            label: "Paragraph 3 Small",
            value: "p3-small",
          },
          {
            label: "Paragraph 3 XS",
            value: "p3-xs",
          },
          {
            label: "Button Text",
            value: "button-text",
          },
        ],
      },
      {
        name: "align",
        friendlyName: "Alignment",
        type: "string",
        defaultValue: "default",
        enum: [
          {
            label: "Default",
            value: "default",
          },
          {
            label: "Left",
            value: "left",
          },
          {
            label: "Center",
            value: "center",
          },
          {
            label: "Right",
            value: "right",
          },
        ],
      },
      {
        name: "fontFamily",
        friendlyName: "Font Family",
        type: "string",
        defaultValue: "default",
        enum: [
          {
            label: "Default",
            value: "default",
          },
          {
            label: "Lato",
            value: "sans",
          },
          {
            label: "Playfair Display",
            value: "serif",
          },
          {
            label: "Monospace",
            value: "mono",
          },
        ],
      },
      {
        name: "color",
        friendlyName: "Color",
        type: "string",
        defaultValue: "primary",
        required: true,
        enum: [
          {
            label: "Primary 1",
            value: "primary-1",
          },
          {
            label: "Primary 2",
            value: "primary-2",
          },
          {
            label: "Primary 3",
            value: "primary-3",
          },
          {
            label: "Primary 4",
            value: "primary-4",
          },
          {
            label: "Secondary 1",
            value: "secondary-1",
          },
          {
            label: "Secondary 2",
            value: "secondary-2",
          },
          {
            label: "Secondary 3",
            value: "secondary-3",
          },
          {
            label: "Secondary 4",
            value: "secondary-4",
          },
          {
            label: "Secondary 5",
            value: "secondary-5",
          },
          {
            label: "Secondary 6",
            value: "secondary-6",
          },
          {
            label: "Secondary 7",
            value: "secondary-7",
          },
          {
            label: "Light Neutral 1",
            value: "light-neutral-1",
          },
          {
            label: "Light Neutral 2",
            value: "light-neutral-2",
          },
          {
            label: "Mid Neutral 1",
            value: "mid-neutral-1",
          },
          {
            label: "Mid Neutral 2",
            value: "mid-neutral-2",
          },
          {
            label: "Mid Neutral 3",
            value: "mid-neutral-3",
          },
          {
            label: "Error",
            value: "error",
          },
          {
            label: "Success",
            value: "success",
          },
          {
            label: "Warning",
            value: "warning",
          },
          {
            label: "Informational",
            value: "informational",
          },
          {
            label: "White",
            value: "white",
          },
          {
            label: "Black",
            value: "black",
          },
        ],
      },
      {
        name: "children",
        friendlyName: "Content",
        helperText: "Content of the text",
        type: "richText",
        required: true,
        defaultValue: "Enter some text...",
      },
      {
        name: "animated",
        type: "boolean",
        defaultValue: false,
      },
    ],
  });
};

export { Typography as BlogTypography, registerBlogTypography };
