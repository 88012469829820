"use client";

import { Builder } from "@builder.io/sdk";
import { AnimatePresence, motion } from "framer-motion";
import { useSearchParams } from "next/navigation";
import { ArticleCard } from "~/components/article-card/article-card";
import { CardListTabs } from "~/components/card-list-tabs/card-list-tabs";
import { Pagination } from "~/components/pagination/pagination";
import { TeamMemberCard } from "~/components/team-member-card/team-member-card";
import { TeamMemberModal } from "~/components/team-member-card/team-member-modal";
import { ARTICLE_PAGE_VARIANTS } from "~/constants";
import { type Tag, type ArticleListType } from "~/types/article";
import { cn } from "~/utils";

interface Props {
  pagination: {
    loadMoreText: string;
    limit: number;
  };
  variant: ArticleListType;
  total: number;
  tags: Tag[];
  articles: Array<{
    id: string;
    data: any;
  }>;
  align?: "center" | "right";
}

const ArticleList = ({
  total,
  variant,
  articles = [],
  pagination,
  tags = [],
  align,
}: Props) => {
  const searchParams = useSearchParams();
  const currentPage = Number(searchParams.get("page")) ?? 0;

  const isTeamCard = variant === ARTICLE_PAGE_VARIANTS.TEAM_MEMBERS;

  const Component = isTeamCard ? TeamMemberCard : ArticleCard;

  const currentTeamCard = isTeamCard
    ? articles.find(
        (article) => article.data.handle === searchParams.get("team-member")
      )
    : null;

  const getShouldAnimate = (index: number) => {
    if (currentPage === 0) return true;
    const startIndexForCurrentPage = currentPage * pagination.limit;
    return index >= startIndexForCurrentPage;
  };

  const getAnimationDelay = (index: number) => {
    if (currentPage === 0) return index * 0.08;
    const isLastLimit = articles.length - index <= pagination.limit;
    return isLastLimit
      ? (index - (articles.length - pagination.limit)) * 0.08
      : 0;
  };

  return (
    <>
      {currentTeamCard ? <TeamMemberModal {...currentTeamCard.data} /> : null}
      <section
        className={cn(
          { "flex max-w-[1200px] flex-col items-center": !isTeamCard },
          {
            "flex self-center flex-col gap-16 items-center": isTeamCard,
          }
        )}
      >
        <CardListTabs tags={tags} align={align} />
        <div
          className={cn(
            {
              "grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mt-10 mb-14":
                !isTeamCard,
            },
            {
              "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 xl:grid-cols-5":
                isTeamCard,
            }
          )}
        >
          <AnimatePresence>
            {articles.map((article, index) => (
              <motion.div
                key={article.id}
                layout
                layoutId={article.id}
                initial={{
                  ...(getShouldAnimate(index) && { opacity: 0, scale: 0.75 }),
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.08,
                    delay: getAnimationDelay(index),
                  },
                }}
                exit={{
                  opacity: 0,
                  scale: 0.5,
                  transition: {
                    duration: 0.08,
                    delay: getAnimationDelay(index),
                  },
                }}
              >
                <Component {...article.data} />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
        <Pagination total={total} {...pagination} />
      </section>
    </>
  );
};

const registerArticleList = () => {
  Builder.registerComponent(ArticleList, {
    name: "ArticleList",
    defaults: {
      bindings: {
        "component.options.total": "context.total",
        "component.options.articles": "context.articles",
        "component.options.tags": "context.tags",
        "component.options.pagination.limit": "context.limit",
        "component.options.variant": "context.variant",
      },
    },
    models: ["article-list-page"],
    inputs: [
      {
        name: "align",
        type: "string",
        friendlyName: "Mobile Alignment",
        defaultValue: "center",
        enum: [
          {
            label: "Left",
            value: "left",
          },
          {
            label: "Center",
            value: "center",
          },
          {
            label: "Right",
            value: "right",
          },
        ],
      },
      {
        name: "total",
        type: "number",
        hideFromUI: true,
      },
      {
        name: "pagination",
        type: "object",
        subFields: [
          {
            name: "loadMoreText",
            type: "string",
            defaultValue: "Load more",
          },
          {
            name: "limit",
            friendlyName: "Max articles per page",
            type: "number",
            defaultValue: 6,
            hideFromUI: true,
          },
        ],
      },
      {
        name: "variant",
        friendlyName: "Article Card Variant",
        type: "string",
        hideFromUI: true,
      },
      {
        name: "tags",
        type: "list",
        hideFromUI: true,
        subFields: [
          {
            name: "name",
            type: "string",
          },
          {
            name: "handle",
            type: "string",
          },
        ],
      },
      {
        name: "articles",
        type: "list",
        hideFromUI: true,
        showIf: (options) => options.get("variant") === "default",
        subFields: [
          {
            name: "title",
            type: "string",
          },
          {
            name: "image",
            type: "file",
            allowedFileTypes: ["jpeg", "png", "jpg", "gif"],
          },
          {
            name: "body",
            type: "string",
            defaultValue: "This is the body of the article",
          },

          {
            name: "reference",
            type: "reference",
            friendlyName: "Link to article",
          },
        ],
      },
    ],
  });
};

export { ArticleList, registerArticleList };
