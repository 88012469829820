import { Builder } from "@builder.io/sdk";
import { ArticleCard } from "~/components/article-card/article-card";

interface Props {
  blogPost:
    | {
        value: {
          data: {
            title: string;
            blurb: string;
            image: string;
            url: string;
            date: string;
          };
        };
        data: never;
      }
    | {
        value: never;
        data: {
          title: string;
          blurb: string;
          image: string;
          url: string;
          date: string;
        };
      };
}

const FeaturedBlogPost = ({ blogPost }: Props) => {
  const data = blogPost?.value?.data ?? blogPost?.data;

  if (!data) {
    return null;
  }

  return (
    <ArticleCard
      blurb={data.blurb}
      image={data.image}
      title={data.title}
      handle={data.url?.split("/").pop() ?? ""}
      date={new Date(data.date ?? new Date())}
      category="blog"
      variant="featured"
      titleClassName="text-[#101828]"
    />
  );
};

const registerFeaturedBlogPost = () => {
  Builder.registerComponent(FeaturedBlogPost, {
    name: "FeaturedBlogPost",
    defaults: {
      bindings: {
        "component.options.blogPost": "context.lastBlogPost",
      },
    },
    inputs: [
      {
        name: "blogPost",
        type: "reference",
        required: true,
        model: "blog-posts",
      },
    ],
  });
};

export { FeaturedBlogPost, registerFeaturedBlogPost };
