"use client";

import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import Link from "next/link";
import { Button } from "~/components/ui/button";
import { useMediaQuery } from "~/hooks/use-media-query";
import { cn, imageLoader } from "~/utils";

import {
  BlogTypography,
  type ColorVariant,
  type TypographyVariant,
} from "../ui/blog-typography";

type Props = {
  title: string;
  titleColor: ColorVariant;
  desktopTitleVariant: TypographyVariant;
  mobileTitleVariant: TypographyVariant;
  backgroundImage: string;
  size: "default" | "large" | "extra-large" | "fullscreen";
  cta?: string;
  darkenBackground?: boolean;
  desktopImagePosition:
    | "top"
    | "top-left"
    | "top-right"
    | "left"
    | "right"
    | "center"
    | "bottom";
  mobileImagePosition:
    | "default"
    | "top"
    | "top-left"
    | "top-right"
    | "left"
    | "right"
    | "center"
    | "bottom";
} & (
  | {
      ctaType: "link";
      ctaLink: string;
      ctaReference?: never;
    }
  | {
      ctaType: "reference";
      ctaLink?: never;
      ctaReference: {
        value: {
          data: {
            url: string;
          };
        };
      };
    }
);

const HeroSection = ({
  backgroundImage,
  ctaType,
  size,
  title,
  titleColor = "white",
  desktopTitleVariant = "h0",
  mobileTitleVariant = "h1",
  cta,
  ctaReference,
  ctaLink,
  desktopImagePosition = "center",
  mobileImagePosition = "default",
  darkenBackground = true,
}: Props) => {
  const ctaHref =
    (ctaType === "reference" ? ctaReference?.value?.data?.url : ctaLink) ?? "";

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div
      className={cn("relative flex items-center justify-center px-8 md:px-32", {
        "py-10  h-[700px] md:h-[400px] max-h-screen": size === "default",
        "py-20  h-[640px] max-h-screen": size === "large",
        "py-20 h-[850px] max-h-screen": size === "extra-large",
        "py-20 h-screen max-h-screen": size === "fullscreen",
      })}
    >
      <div
        className={cn(
          "absolute inset-0 z-[1] size-full bg-[#182C5B] opacity-30",
          {
            "opacity-0": !darkenBackground,
          }
        )}
      />
      <Image
        fetchPriority="high"
        className={cn("absolute inset-0 z-0 size-full object-cover")}
        src={backgroundImage}
        fill
        alt={title}
        loader={imageLoader}
        style={{
          objectPosition: isMobile ? mobileImagePosition : desktopImagePosition,
        }}
      />
      <div className="z-10 flex flex-col items-center gap-8">
        {title ? (
          <>
            <BlogTypography
              animated
              color={titleColor}
              variant={desktopTitleVariant}
              mobileVariant={mobileTitleVariant}
              className="z-10 hidden md:max-w-[25ch]"
              align="center"
            >
              {title}
            </BlogTypography>
          </>
        ) : null}
        {cta ? (
          <div>
            <Button variant="primary" color="primary" size="xs" asChild>
              <Link
                href={ctaHref}
                rel={ctaType === "link" ? "noopener noreferrer" : undefined}
              >
                {cta}
              </Link>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const registerHeroSection = () => {
  Builder.registerComponent(HeroSection, {
    name: "HeroSection",
    inputs: [
      {
        name: "title",
        type: "longText",
        defaultValue: "Hello World",
      },
      {
        name: "titleColor",
        friendlyName: "Title Color",
        type: "string",
        defaultValue: "white",
        enum: [
          {
            label: "Primary 1",
            value: "primary-1",
          },
          {
            label: "Primary 2",
            value: "primary-2",
          },
          {
            label: "Primary 3",
            value: "primary-3",
          },
          {
            label: "Primary 4",
            value: "primary-4",
          },
          {
            label: "Secondary 1",
            value: "secondary-1",
          },
          {
            label: "Secondary 2",
            value: "secondary-2",
          },
          {
            label: "Secondary 3",
            value: "secondary-3",
          },
          {
            label: "Secondary 4",
            value: "secondary-4",
          },
          {
            label: "Secondary 5",
            value: "secondary-5",
          },
          {
            label: "Secondary 6",
            value: "secondary-6",
          },
          {
            label: "Secondary 7",
            value: "secondary-7",
          },
          {
            label: "Light Neutral 1",
            value: "light-neutral-1",
          },
          {
            label: "Light Neutral 2",
            value: "light-neutral-2",
          },
          {
            label: "Mid Neutral 1",
            value: "mid-neutral-1",
          },
          {
            label: "Mid Neutral 2",
            value: "mid-neutral-2",
          },
          {
            label: "Mid Neutral 3",
            value: "mid-neutral-3",
          },
          {
            label: "Error",
            value: "error",
          },
          {
            label: "Success",
            value: "success",
          },
          {
            label: "Warning",
            value: "warning",
          },
          {
            label: "Informational",
            value: "informational",
          },
          {
            label: "White",
            value: "white",
          },
          {
            label: "Black",
            value: "black",
          },
        ],
      },
      {
        name: "desktopTitleVariant",
        friendlyName: "Desktop Title Variant",
        type: "string",
        defaultValue: "h0",
        enum: [
          {
            label: "Same as desktop",
            value: "",
          },
          {
            label: "Heading 0",
            value: "h0",
          },
          {
            label: "Heading 1",
            value: "h1",
          },
          {
            label: "Heading 2",
            value: "h2",
          },
          {
            label: "Heading 3",
            value: "h3",
          },
          {
            label: "Heading 4",
            value: "h4",
          },
          {
            label: "Heading 5",
            value: "h5",
          },
          {
            label: "Heading 6",
            value: "h6",
          },
          {
            label: "FIELD LABEL CAPS",
            value: "field-label-caps",
          },
          {
            label: "Label Large",
            value: "label-large",
          },
          {
            label: "Label Medium",
            value: "label-medium",
          },
          {
            label: "Label Small",
            value: "label-small",
          },
          {
            label: "Subtitle Large",
            value: "subtitle-large",
          },
          {
            label: "SUBTITLE MEDIUM",
            value: "subtitle-medium",
          },
          {
            label: "Subtitle Small",
            value: "subtitle-small",
          },
          {
            label: "Paragraph 1 Large",
            value: "p1-large",
          },
          {
            label: "Paragraph 2 Medium",
            value: "p2-medium",
          },
          {
            label: "Paragraph 3 Small",
            value: "p3-small",
          },
          {
            label: "Paragraph 3 XS",
            value: "p3-xs",
          },
          {
            label: "Button Text",
            value: "button-text",
          },
        ],
      },
      {
        name: "mobileTitleVariant",
        friendlyName: "Mobile Title Variant",
        type: "string",
        defaultValue: "",
        enum: [
          {
            label: "Same as desktop",
            value: "",
          },
          {
            label: "Heading 0",
            value: "h0",
          },
          {
            label: "Heading 1",
            value: "h1",
          },
          {
            label: "Heading 2",
            value: "h2",
          },
          {
            label: "Heading 3",
            value: "h3",
          },
          {
            label: "Heading 4",
            value: "h4",
          },
          {
            label: "Heading 5",
            value: "h5",
          },
          {
            label: "Heading 6",
            value: "h6",
          },
          {
            label: "FIELD LABEL CAPS",
            value: "field-label-caps",
          },
          {
            label: "Label Large",
            value: "label-large",
          },
          {
            label: "Label Medium",
            value: "label-medium",
          },
          {
            label: "Label Small",
            value: "label-small",
          },
          {
            label: "Subtitle Large",
            value: "subtitle-large",
          },
          {
            label: "SUBTITLE MEDIUM",
            value: "subtitle-medium",
          },
          {
            label: "Subtitle Small",
            value: "subtitle-small",
          },
          {
            label: "Paragraph 1 Large",
            value: "p1-large",
          },
          {
            label: "Paragraph 2 Medium",
            value: "p2-medium",
          },
          {
            label: "Paragraph 3 Small",
            value: "p3-small",
          },
          {
            label: "Paragraph 3 XS",
            value: "p3-xs",
          },
          {
            label: "Button Text",
            value: "button-text",
          },
        ],
      },
      {
        name: "backgroundImage",
        friendlyName: "Background image",
        type: "file",
        allowedFileTypes: ["jpeg", "jpg", "png", "webp"],
      },
      {
        name: "desktopImagePosition",
        type: "enum",
        defaultValue: "center",
        enum: [
          { label: "Top", value: "top" },
          { label: "Top Left", value: "top left" },
          { label: "Top Right", value: "top right" },
          { label: "Left", value: "left" },
          { label: "Right", value: "right" },
          { label: "Center", value: "center" },
          { label: "Bottom", value: "bottom" },
          { label: "Bottom Left", value: "bottom left" },
          { label: "Bottom Right", value: "bottom right" },
        ],
      },
      {
        name: "mobileImagePosition",
        type: "enum",
        defaultValue: "center",
        enum: [
          { label: "Top", value: "top" },
          { label: "Top Left", value: "top left" },
          { label: "Top Right", value: "top right" },
          { label: "Left", value: "left" },
          { label: "Right", value: "right" },
          { label: "Center", value: "center" },
          { label: "Bottom", value: "bottom" },
          { label: "Bottom Left", value: "bottom left" },
          { label: "Bottom Right", value: "bottom right" },
        ],
      },
      {
        name: "cta",
        friendlyName: "Call to action",
        type: "string",
        defaultValue: "Learn more",
      },
      {
        name: "ctaType",
        friendlyName: "Call to action type",
        type: "enum",
        defaultValue: "reference",
        enum: [
          { label: "Reference", value: "reference" },
          { label: "Link", value: "link" },
        ],
        showIf: (options) => options.get("cta"),
      },
      {
        name: "ctaLink",
        friendlyName: "Call to action link",
        type: "string",
        defaultValue: "/",
        showIf: (options) =>
          options.get("ctaType") === "link" && options.get("cta"),
      },
      {
        name: "ctaReference",
        friendlyName: "Call to action reference",
        type: "reference",
        showIf: (options) =>
          options.get("ctaType") === "reference" && options.get("cta"),
      },
      {
        name: "darkenBackground",
        type: "boolean",
        helperText: "Darken the background image",
      },
    ],
  });
};

export { HeroSection, registerHeroSection };
