import { Builder } from "@builder.io/sdk";
import Link from "next/link";
import { PodcastCardBackground } from "~/components/podcast-card-background/podcast-card-background";
import { PodcastEpisodeCircle } from "~/components/podcast-episode-circle/podcast-episode-circle";
import { BlogTypography } from "~/components/ui/blog-typography";
import { type Podcast } from "~/types/podcast";
import { formatDate } from "~/utils";

interface Props extends Podcast {}

const PodcastCard = ({
  date,
  title,
  handle,
  episode,

  accentColor,
}: Props) => {
  const href = `/insights/podcasts/${handle}`;

  return (
    <Link
      prefetch
      className="group relative flex w-full flex-col items-start gap-4 md:w-full md:max-w-96"
      href={href ?? ""}
    >
      <div className="relative h-[240px] w-full overflow-hidden">
        <PodcastCardBackground
          accentColor={accentColor}
          className="relative h-[240px] transition duration-300 ease-in-out group-hover:scale-110"
        >
          <div className={"relative size-full overflow-hidden @container"}>
            <PodcastEpisodeCircle
              className="-left-4 top-10 md:left-2 md:top-[28%] md:scale-50"
              episode={episode}
            />
          </div>
        </PodcastCardBackground>
      </div>
      <div className="mx-6 flex max-w-[340px] flex-col gap-2 overflow-hidden transition duration-300 ease-in-out group-hover:opacity-70 md:ml-0 md:max-w-none">
        <BlogTypography
          variant="subtitle-small"
          className="font-normal uppercase leading-tight text-[#6941C6] md:text-[18px] md:tracking-[0.9px]"
        >
          <>
            Episode {episode} • {formatDate(date)}{" "}
          </>
        </BlogTypography>
        <BlogTypography
          variant="h5"
          className="line-clamp-2 text-[24px] font-normal leading-tight tracking-[0.06px]"
        >
          {title}
        </BlogTypography>
      </div>
    </Link>
  );
};

const registerPodcastCard = () => {
  Builder.registerComponent(PodcastCard, {
    name: "PodcastCard",
    inputs: [
      {
        name: "title",
        type: "string",
        required: true,
        defaultValue: "The title of the podcast",
      },
      {
        name: "image",
        type: "file",
        allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
      },
      {
        name: "episode",
        type: "number",
      },
      {
        name: "date",
        type: "string",
      },
      {
        name: "handle",
        type: "string",
        defaultValue: "article-handle",
      },
      {
        name: "accentColor",
        type: "string",
        enum: ["light-gray", "gray", "dark-gray", "blue"],
        defaultValue: "gray",
      },
    ],
  });
};

export { PodcastCard, registerPodcastCard };
