"use-client";

import { useRef, useState } from "react";
import { flushSync } from "react-dom";

import { Builder } from "@builder.io/sdk";
import { ShareButton } from "~/components/share-button/share-button";
import { Button } from "~/components/ui/button";
import { Icon } from "~/components/ui/icon";
import { TypographyDeprecated } from "~/components/ui/typography";
import { type BuilderDefaultProps } from "~/types/builder.types";
import { cn, getWindow, noop } from "~/utils";

interface Props extends BuilderDefaultProps {
  links: Array<{
    platform: "linkedin" | "twitter" | "facebook";
  }>;
  copyLink?: boolean;
  copyLinkPosition?: "left" | "right";
}

const SocialLinksShare = ({
  links = [],
  copyLink,
  copyLinkPosition = "right",
  builderState,
}: Props) => {
  const enabledShareLinks =
    builderState?.context.globalSettings.enabledShareLinks;
  const title = builderState?.content?.data?.title;

  const [linkCopied, setLinkCopied] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideLabel = true;

  const handleLinkCopy = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    navigator.clipboard.writeText(window.location.href).catch(noop);
    flushSync(() => {
      setLinkCopied(true);
      timeoutRef.current = setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    });
  };

  const currentUrl = getWindow()?.location?.href;

  const filteredEnabledLinks = links.filter(({ platform }) =>
    enabledShareLinks?.includes(platform)
  );

  return (
    <div
      className={cn("flex items-center gap-8", {
        "flex-wrap justify-center": !hideLabel,
      })}
    >
      <TypographyDeprecated
        className={cn("text-[#344054]", {
          hidden: hideLabel,
        })}
      >
        Share
      </TypographyDeprecated>
      {copyLink && copyLinkPosition === "left" && (
        <Button
          variant="primary-outline"
          className="flex h-10 w-[126px] gap-1 rounded-[2px] border-[#D0D5DD] font-normal text-[#344054] hover:bg-black/[0.03] hover:text-[#344054]"
          onClick={handleLinkCopy}
        >
          <Icon
            iconUrl={
              linkCopied
                ? "/images/icons/check.svg"
                : "/images/icons/clipboard.svg"
            }
            variant="primary"
            className="size-6"
          />
          {linkCopied ? "Copied" : "Copy link"}
        </Button>
      )}
      <div className="flex items-center gap-6">
        {filteredEnabledLinks?.map(({ platform }, index) => (
          <ShareButton
            key={`${platform}-${index}`}
            platform={platform}
            url={currentUrl ?? ""}
            title={title}
          />
        ))}
      </div>
      {copyLink && copyLinkPosition === "right" && (
        <Button
          variant="primary-outline"
          className="flex h-10 w-[126px] gap-1 rounded-[2px] border-[#D0D5DD] font-normal text-[#344054] hover:bg-black/[0.03] hover:text-[#344054]"
          onClick={handleLinkCopy}
        >
          <Icon
            iconUrl={
              linkCopied
                ? "/images/icons/check.svg"
                : "/images/icons/clipboard.svg"
            }
            variant="primary"
            className="size-6"
          />
          {linkCopied ? "Copied" : "Copy link"}
        </Button>
      )}
    </div>
  );
};

const registerSocialLinksShare = () => {
  Builder.registerComponent(SocialLinksShare, {
    name: "SocialLinksShare",
    inputs: [
      {
        name: "links",
        type: "list",
        enum: ["linkedin", "twitter", "facebook"],
        subFields: [
          {
            name: "platform",
            type: "string",
            required: true,
          },
        ],
        defaultValue: [
          { platform: "linkedin" },
          { platform: "twitter" },
          { platform: "facebook" },
        ],
      },
      {
        name: "copyLink",
        type: "boolean",
      },
      {
        name: "copyLinkPosition",
        type: "string",
        enum: ["left", "right"],
        defaultValue: "right",
      },
    ],
  });
};

export { SocialLinksShare, registerSocialLinksShare };
