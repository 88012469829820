import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import Link from "next/link";
import { BlogTypography } from "~/components/ui/blog-typography";
import { type Article } from "~/types/article";
import { imageLoader } from "~/utils";

interface Props extends Article {}

const GuideCard = ({ blurb, image, title, handle }: Props) => {
  return (
    <Link
      prefetch
      className="group relative flex h-[642px] w-full flex-col items-start gap-5 md:w-full md:max-w-96"
      href={handle ?? ""}
    >
      <div className="relative h-[470px] w-full overflow-hidden bg-brand-primary-1 px-8 py-20">
        <Image
          src={image}
          alt={title}
          width={314}
          height={314}
          loader={imageLoader}
          className="object-contain transition duration-300 ease-in-out group-hover:scale-110"
          sizes="(max-width: 640px) 384px, (max-width: 768px) 384px"
        />
      </div>
      <div className="mx-6 flex max-w-[340px] flex-col gap-2 overflow-hidden transition duration-300 ease-in-out group-hover:opacity-70 md:ml-0 md:max-w-none">
        <BlogTypography color="primary-4" variant="h5" className="text-2xl">
          {title}
        </BlogTypography>
        <BlogTypography
          variant="p1-large"
          color="primary-1"
          className="line-clamp-3 h-20 text-brand-primary-1"
          title={blurb}
        >
          {blurb}
        </BlogTypography>
      </div>
    </Link>
  );
};

const registerGuideCard = () => {
  Builder.registerComponent(GuideCard, {
    name: "GuideCard",
    inputs: [
      {
        name: "title",
        type: "string",
        required: true,
        defaultValue: "The title of the article",
      },
      {
        name: "blurb",
        type: "string",
        required: true,
        defaultValue: "The blurb of the article",
      },
      {
        name: "image",
        type: "file",
        allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
        required: true,
      },
      {
        name: "handle",
        type: "string",
        required: true,
        defaultValue: "article-handle",
      },
    ],
  });
};

export { GuideCard, registerGuideCard };
