import { useEffect, useRef } from "react";

import {
  type MotionValue,
  useInView,
  useMotionValue,
  useSpring,
} from "framer-motion";

interface Props {
  startValue?: number;
  target: number;
}

const Counter = ({ startValue = 0, target }: Props) => {
  const ref = useRef<HTMLSpanElement>(null);
  const motionValue = useMotionValue(startValue);
  const springValue: MotionValue<number> = useSpring(motionValue, {
    damping: 100,
    stiffness: 200,
  });
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  if (isInView) {
    motionValue.set(target);
  }

  useEffect(() => {
    springValue.on("change", (latest) => {
      if (ref.current) {
        ref.current.textContent = Intl.NumberFormat("en-US").format(
          +latest.toFixed(0)
        );
      }
    });

    return () => {
      springValue.destroy();
    };
  }, [springValue]);

  return <span ref={ref} />;
};

export { Counter };
