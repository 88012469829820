import { Builder } from "@builder.io/sdk";
import { HubSpotForm } from "~/components/hubspot-form/hubspot-form";
import { BlogTypography } from "~/components/ui/blog-typography";
import { type BuilderDefaultProps } from "~/types/builder.types";

interface Props extends BuilderDefaultProps {}

const ContactForm = ({ builderState }: Props) => {
  const settings = builderState?.context?.globalSettings?.contactForm;

  const hasSettings = Object.values(settings ?? {}).every(
    (value) => !!value && value !== ""
  );

  return hasSettings ? (
    <section>
      <div className="flex flex-col gap-8">
        <BlogTypography animated variant="h1" color="primary-4" align="center">
          Let&apos;s Talk
        </BlogTypography>
        <BlogTypography
          animated
          variant="p1-large"
          color="primary-4"
          align="center"
        >
          Ready to unlock success from an integrated full-service healthcare
          marketing strategy?
        </BlogTypography>
        <HubSpotForm
          className="w-full"
          region={settings!.region}
          portalId={settings!.portalId}
          formId={settings!.formId}
        />
      </div>
    </section>
  ) : null;
};

const registerContactForm = () => {
  Builder.registerComponent(ContactForm, {
    name: "Contact Form",
    inputs: [],
  });
};

export { ContactForm, registerContactForm };
