import React from "react";

import { Builder } from "@builder.io/react";
import Image from "next/image";

interface ImageOverrideProps {
  image: string;
  imageFit: "cover" | "contain" | "fill" | "none" | "scale-down";
  imagePosition: "top" | "bottom" | "center" | "left" | "right";
  altText?: string;
  aspectRatio?: number;
}

const ImageOverride: React.FC<ImageOverrideProps> = ({
  image,
  imageFit = "cover",
  imagePosition = "center",
  altText = "",
  aspectRatio = 1,
  ...props
}) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        aspectRatio: `1 / ${aspectRatio}`,
      }}
      {...props}
    >
      <Image
        src={image}
        alt={altText}
        layout="fill"
        objectFit={imageFit}
        objectPosition={imagePosition}
      />
    </div>
  );
};

const registerImageOverride = () => {
  Builder.registerComponent(ImageOverride, {
    name: "Image Override",
    inputs: [
      {
        name: "image",
        type: "file",
        allowedFileTypes: ["jpeg", "jpg", "png", "svg", "gif", "webp"],
        required: true,
        friendlyName: "Image",
      },
      {
        name: "imageFit",
        type: "string",
        enum: ["cover", "contain", "fill", "none", "scale-down"],
        defaultValue: "cover",
        friendlyName: "Image fit",
      },
      {
        name: "imagePosition",
        type: "string",
        enum: ["top", "bottom", "center", "left", "right"],
        defaultValue: "center",
        friendlyName: "Image position",
      },
      { name: "altText", type: "string", friendlyName: "Alt text" },
      {
        name: "aspectRatio",
        type: "number",
        defaultValue: 1,
        friendlyName: "Aspect ratio",
        helperText: "Aspect ratio of the image (width / height)",
      },
    ],
  });
};

export { ImageOverride, registerImageOverride };
