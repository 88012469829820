import { useCallback, useEffect, useState } from "react";

import { useIsPreviewing } from "@builder.io/react";
import { Builder } from "@builder.io/sdk";
import { BlogTypography } from "~/components/ui/blog-typography";
import { cn, loadScript } from "~/utils";

interface Props {
  region: string;
  portalId: string;
  formId: string;
  className?: string;
}

const HubSpotForm = ({ region, portalId, formId, className }: Props) => {
  const isPreviewing = useIsPreviewing();
  const formClassName = `hsForm-${formId}`;
  const [isLoaded, setIsLoaded] = useState(false);

  const createForm = useCallback(() => {
    if (!window.hbspt) return;

    window.hbspt.forms.create({
      region,
      portalId,
      formId,
      target: `.${formClassName}`,
      onFormReady() {
        setIsLoaded(true);
      },
    });
  }, [formClassName, formId, portalId, region]);

  useEffect(
    () => loadScript("//js.hsforms.net/forms/v2.js")(createForm),
    [createForm]
  );

  return (
    <div className={cn(formClassName, className)}>
      <div className="flex min-h-52 items-center justify-center">
        {!isLoaded && !isPreviewing && (
          <div className="flex size-full flex-col gap-6">
            <div className="flex flex-col gap-6 md:flex-row">
              <div className="h-10 w-full animate-pulse rounded-lg bg-brand-primary-1/20" />
              <div className="h-10 w-full animate-pulse rounded-lg bg-brand-primary-1/20" />
            </div>
            <div className="h-10 w-full animate-pulse rounded-lg bg-brand-primary-1/20" />
            <div className="mx-auto mt-6 h-10 w-1/2 animate-pulse rounded-lg bg-brand-primary-1/20 md:w-1/4" />
          </div>
        )}
        {isPreviewing && (
          <BlogTypography variant="h2">
            Your form will be visible only on the published site.
          </BlogTypography>
        )}
      </div>
    </div>
  );
};

const registerHubSpotForm = () => {
  Builder.registerComponent(HubSpotForm, {
    name: "HubSpotForm",
    inputs: [
      {
        name: "region",
        type: "string",
        required: true,
      },
      {
        name: "portalId",
        type: "string",
        required: true,
      },
      {
        name: "formId",
        type: "string",
        required: true,
      },
    ],
  });
};

export { HubSpotForm, registerHubSpotForm };
