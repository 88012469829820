"use client";

import { useMemo } from "react";

import { Builder } from "@builder.io/sdk";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { type Tag } from "~/types/article";
import { cn } from "~/utils";

interface Props {
  tags: Tag[];
  align?: "center" | "right";
}

const CardListTabs = ({ tags, align = "center" }: Props) => {
  const searchParams = useSearchParams();
  const tag = searchParams.get("tag") ?? "all";
  const currentPage = Number(searchParams.get("page")) ?? 0;
  const router = useRouter();

  const createPageURL = (pageNumber: number | string) => {
    const params = new URLSearchParams(searchParams);
    params.set("tag", pageNumber.toString());
    currentPage > 0 && params.delete("page");
    return `?${params.toString()}`;
  };

  const handleTabClick = (newTab: string) => {
    router.push(createPageURL(newTab), { scroll: false });
  };

  const singleCharTags = useMemo(
    () => tags?.every((tag) => tag.name.length === 1),
    [tags]
  );

  return (
    <Tabs
      className="w-full"
      defaultValue={tag}
      value={tag}
      onValueChange={handleTabClick}
    >
      <TabsList
        className={cn(
          "flex max-w-[100dvw] flex-nowrap items-start gap-y-4 overflow-auto px-5 sm:justify-normal sm:overflow-hidden sm:px-0",
          { "justify-center": align === "center" },
          {
            "items-start": align === "right",
          },
          {
            "justify-start": singleCharTags,
          },
          {
            "justify-center": !singleCharTags,
          }
        )}
      >
        <TabsTrigger
          value="all"
          className="mr-8 hover:text-brand-primary-4 md:mr-6"
        >
          All
          <div className="h-[2px] w-full bg-transparent group-data-[state=active]:bg-brand-secondary-2" />
        </TabsTrigger>
        <div
          className={cn(
            "flex flex-wrap",
            {
              "grid grid-cols-8 justify-items-start sm:flex gap-x-8 md:gap-x-6 gap-y-2":
                align === "right",
            },
            {
              "grid-cols-8": singleCharTags,
            },
            {
              "grid-cols-[min-content_1fr]": !singleCharTags,
            }
          )}
        >
          {tags?.map((tag, index) => (
            <TabsTrigger
              key={index}
              value={tag.handle}
              className="hover:text-brand-primary-4"
            >
              <Link href={createPageURL(tag.handle)} scroll={false} prefetch>
                {tag.name}
              </Link>
              <div className="h-[2px] w-full bg-transparent group-data-[state=active]:bg-brand-secondary-2" />
            </TabsTrigger>
          ))}
        </div>
      </TabsList>
    </Tabs>
  );
};

const registerCardListTabs = () => {
  Builder.registerComponent(CardListTabs, {
    name: "CardListTabs",
    defaults: {
      bindings: {
        "component.options.tags": "context.cardTags",
      },
    },
    inputs: [
      {
        name: "tags",
        type: "list",
        subFields: [
          {
            name: "tag",
            type: "reference",
            model: "tags",
          },
        ],
      },
      {
        name: "align",
        type: "string",
        defaultValue: "center",
        enum: ["center", "right"],
      },
    ],
  });
};

export { CardListTabs, registerCardListTabs };
