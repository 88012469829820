import { Builder } from "@builder.io/sdk";
import { type EmblaPluginType as CarouselPlugin } from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
import {
  CarouselContent,
  Carousel as BaseCarousel,
  CarouselItem,
  CarouselDots,
} from "~/components/ui/carousel";
import { cn, imageLoader } from "~/utils";

interface Props extends CarouselOptions {
  items: Item[];
}

interface CarouselOptions {
  autoplay: boolean;
  delay: number;
  pauseOnHover: boolean;
}

interface Item {
  image: string;
  altText?: string;
}

const LogoCarousel = ({
  items,
  autoplay = true,
  delay = 3,
  pauseOnHover = false,
}: Props) => {
  const mobileGroup = groupArrayItems(items, 6);
  const desktopGroup = groupArrayItems(items, 5);
  const carouselOptions = { autoplay, delay, pauseOnHover };

  return (
    <>
      <Carousel
        className="hidden lg:flex"
        items={desktopGroup}
        {...carouselOptions}
      />
      <Carousel
        className="lg:hidden"
        items={mobileGroup}
        {...carouselOptions}
      />
    </>
  );
};

const Carousel = ({
  autoplay,
  delay,
  pauseOnHover,
  items = [],
  className,
}: CarouselOptions & {
  items: Item[][];
  className?: string;
}) => {
  return (
    <BaseCarousel
      plugins={
        [
          autoplay &&
            Autoplay({ delay: delay * 1000, stopOnMouseEnter: pauseOnHover }),
        ].filter(Boolean) as CarouselPlugin[]
      }
      className={cn(
        "flex w-auto max-w-fit flex-col justify-between gap-12 overflow-hidden",
        className
      )}
    >
      <CarouselContent className="mt-12">
        {items.map((group, index) => {
          return (
            <CarouselItem
              key={index}
              className="grid w-screen grid-cols-2 justify-items-center gap-4 md:flex md:flex-row md:items-center md:justify-center md:gap-16"
            >
              {group.map((item, index) => (
                <div key={index} className="relative size-24  md:size-[150px]">
                  <Image
                    src={item.image}
                    alt={item.altText ?? ""}
                    className="object-contain"
                    fill
                    loader={imageLoader}
                  />
                </div>
              ))}
            </CarouselItem>
          );
        })}
      </CarouselContent>
      <CarouselDots dotClassName="bg-brand-primary-1" className="self-center" />
    </BaseCarousel>
  );
};

const registerLogoCarousel = () => {
  Builder.registerComponent(LogoCarousel, {
    name: "LogoCarousel",
    inputs: [
      {
        name: "autoplay",
        type: "boolean",
      },
      {
        name: "delay",
        type: "number",
        helperText: "Delay in seconds",
        defaultValue: 3,
      },
      {
        name: "pauseOnHover",
        type: "boolean",
      },
      {
        name: "items",
        type: "list",
        subFields: [
          {
            name: "image",
            type: "file",
            required: true,
            allowedFileTypes: ["jpeg", "jpg", "png", "svg", "gif"],
          },
          {
            name: "altText",
            friendlyName: "Alt Text",
            type: "string",
          },
        ],
      },
    ],
  });
};

export { LogoCarousel, registerLogoCarousel };

function groupArrayItems<T>(array: T[], groupLength: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < array.length; i += groupLength) {
    result.push(array.slice(i, i + groupLength));
  }
  return result;
}
