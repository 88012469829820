"use client";

import { useEffect, useRef, useState } from "react";

import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import Link from "next/link";
import {
  NavigationMenu,
  type NavigationMenuRef,
} from "~/components/navigation-menu/navigation-menu";
import Portal from "~/components/ui/portals";
import { HEADER_THRESHOLD } from "~/constants";
import { type BuilderDefaultProps } from "~/types/builder.types";
import { cn, imageLoader } from "~/utils";

interface Props extends BuilderDefaultProps {
  showHeader: boolean;
  logo: string;
  menuBackgroundImage: string;
  pages: Page[];
  links: any;
}

export interface Page {
  title: string;
  page: {
    value: {
      data: {
        title: string;
        url: string;
      };
    };
  };
}

let hasScroll = true;
let isFirstRender = true;

const StickyHeader = ({
  logo,
  showHeader: defaultShowHeader,
  menuBackgroundImage,
  pages,
  links,
  ...rest
}: Props) => {
  const isServer = rest.builderState?.state.isServer;
  const menuRef = useRef<NavigationMenuRef>(null);
  const latestScrollY = useRef(0);
  const [showHeader, setShowHeader] = useState(() => {
    if (isServer) return defaultShowHeader;
    if (isFirstRender) {
      isFirstRender = false;
      return defaultShowHeader;
    }
    return hasScroll;
  });
  const [isBelowThreshold, setIsBelowThreshold] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsBelowThreshold(currentScrollY >= HEADER_THRESHOLD);
      setShowHeader(
        currentScrollY <= latestScrollY.current || currentScrollY <= 0
      );
      hasScroll =
        currentScrollY <= latestScrollY.current || currentScrollY <= 0;
      latestScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Portal container="header">
      <div
        key="sticky-header"
        className={cn(
          "flex h-0 w-screen flex-col items-center transition-all duration-700"
        )}
      >
        <div id="sticky-banner" className="z-50 w-full"></div>
        <div
          className={cn(
            "flex w-full -translate-y-full items-start py-6 transition-all duration-700 md:py-8",
            {
              "translate-y-0": showHeader,
              "bg-brand-primary-1/50 backdrop-blur-md md:py-4":
                isBelowThreshold,
            }
          )}
        >
          <Link
            className={cn(
              "relative mx-auto h-12 w-32 translate-x-1 transition-all duration-700 md:h-14",
              {
                "md:h-8": isBelowThreshold,
              }
            )}
            href="/"
          >
            <Image
              src={logo}
              alt="Logo"
              fill
              quality={100}
              className="mt-[3px] object-contain md:mt-0"
              loader={imageLoader}
            />
          </Link>

          <NavigationMenu
            ref={menuRef}
            bgImage={menuBackgroundImage}
            logo={logo}
            links={links}
          >
            <button className="relative right-8 scale-75 cursor-pointer text-white transition-colors duration-300 hover:text-brand-primary-3 md:right-12 md:scale-100">
              {menuDots}
            </button>
          </NavigationMenu>
        </div>
      </div>
    </Portal>
  );
};

const registerStickyHeader = () => {
  Builder.registerComponent(StickyHeader, {
    name: "StickyHeader",
    defaults: {
      bindings: {
        "component.options.links": "context.headerLinks",
      },
    },

    inputs: [
      {
        name: "showHeader",
        type: "boolean",
        defaultValue: true,
      },
      {
        name: "logo",
        friendlyName: "Logo",
        type: "file",
        required: true,
        allowedFileTypes: ["png"],
      },
      {
        name: "menuBackgroundImage",
        friendlyName: "Menu background Image",
        type: "file",
        allowedFileTypes: ["png", "jpg", "jpeg", "webp"],
      },
      {
        name: "links",
        type: "list",
        hideFromUI: true,
        subFields: [
          {
            name: "link",
            type: "reference",
            model: "links",
            required: true,
          },
        ],
      },
    ],
  });
};

export { StickyHeader, registerStickyHeader };

const menuDots = (
  <svg
    width="10"
    height="36"
    viewBox="0 0 10 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4.97079" cy="5.22567" r="4.38192" fill="white" />
    <circle cx="4.97079" cy="18.1163" r="4.38192" fill="white" />
    <circle cx="4.97079" cy="31.003" r="4.38192" fill="white" />
  </svg>
);
