"use client";
import { useLayoutEffect, useRef, useState } from "react";

import {
  motion,
  useTransform,
  useMotionValue,
  useAnimationFrame,
  wrap,
} from "framer-motion";

interface Props {
  children: React.ReactNode;
  velocity: number;
}

export const InfiniteSlider = ({ children, velocity = 2 }: Props) => {
  const currentVelocity = useMotionValue(0);

  const [repetitions, setRepetitions] = useState<number>(1);
  const childRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const calculateViewportFit = () => {
      if (childRef.current) {
        const viewportWidth = window.innerWidth;
        const childWidth = childRef.current.offsetWidth;
        setRepetitions(Math.ceil(viewportWidth / childWidth) + 1);
      }
    };

    calculateViewportFit();

    window.addEventListener("resize", calculateViewportFit);

    return () => {
      window.removeEventListener("resize", calculateViewportFit);
    };
  }, []);

  const x = useTransform(
    currentVelocity,
    (v) => `${wrap(0, -(100 / repetitions), v)}%`
  );

  useAnimationFrame((_, delta) => {
    currentVelocity.set(currentVelocity.get() + velocity * (delta / 1000));
  });

  return (
    <div className="m-0 flex flex-nowrap overflow-hidden">
      <motion.div className="flex flex-nowrap" style={{ x }}>
        {Array.from({ length: repetitions }).map((_, i) => (
          <div
            key={i}
            className="flex flex-nowrap"
            ref={(i === 0 && childRef) || null}
          >
            {children}
          </div>
        ))}
      </motion.div>
    </div>
  );
};
