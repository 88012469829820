import { Builder } from "@builder.io/sdk";
import { type EmblaPluginType as CarouselPlugin } from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  useCarousel,
} from "~/components/ui/carousel";
import { cn, fillArray, imageLoader } from "~/utils";

interface Props {
  autoplay: boolean;
  pauseOnHover: boolean;
  delay: number;
  images: Array<{
    image: string;
    alt: string;
  }>;
}

const ImageGallery = ({
  images = [],
  autoplay = true,
  pauseOnHover = false,
  delay = 3,
}: Props) => {
  const filledImages = fillArray(images, 6);

  return (
    <Carousel
      plugins={
        [
          autoplay &&
            Autoplay({ delay: delay * 1000, stopOnMouseEnter: pauseOnHover }),
        ].filter(Boolean) as CarouselPlugin[]
      }
      opts={{
        align: "center",
        startIndex: 0,
      }}
    >
      <CarouselContent className="isolate h-[194px] w-full items-center md:h-[385px] lg:h-[485px] lg:w-full">
        {filledImages.map(({ alt, image }, index) => (
          <Item key={index} image={image} alt={alt} index={index} />
        ))}
      </CarouselContent>
    </Carousel>
  );
};

const Item = ({
  image,
  alt,
  index,
}: {
  image: string;
  alt: string;
  index: number;
}) => {
  const carousel = useCarousel();
  const isActive = carousel.isSlideActive(index);

  return (
    <CarouselItem
      showSkeleton
      className={cn(
        "relative z-10 flex h-1/2 basis-2/4 items-center will-change-transform md:basis-1/3",
        {
          "z-0": !isActive,
        }
      )}
    >
      <div
        className={cn(
          "relative size-full scale-150 opacity-100 transition-all duration-500 ease-in-out will-change-transform hover:opacity-100",
          {
            "scale-100 opacity-80": !isActive,
          }
        )}
      >
        <Image
          src={image}
          alt={alt}
          fill
          className="object-cover"
          loader={imageLoader}
        />
      </div>
    </CarouselItem>
  );
};

const registerImageGallery = () => {
  Builder.registerComponent(ImageGallery, {
    name: "ImageGallery",
    inputs: [
      {
        name: "autoplay",
        type: "boolean",
        defaultValue: true,
      },
      {
        name: "pauseOnHover",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "delay",
        type: "number",
        defaultValue: 3,
      },
      {
        name: "images",
        type: "list",
        required: true,
        subFields: [
          {
            name: "image",
            type: "file",
            allowedFileTypes: ["jpeg", "jpg", "png", "webp"],
          },
          {
            name: "alt",
            type: "string",
            defaultValue: "Image",
          },
        ],
      },
    ],
  });
};

export { ImageGallery, registerImageGallery };
