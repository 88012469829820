import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import { Animate } from "~/components/animation/animate";
import { BlogTypography } from "~/components/ui/blog-typography";
import { Counter } from "~/components/ui/counter";
import { imageLoader } from "~/utils";

interface Props {
  text: string;
  target: number;
  iconUrl: string;
  prefix?: string;
  suffix?: string;
}

const VariableStatItem = ({ iconUrl, target, text, prefix, suffix }: Props) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-3">
      <Animate type="appear-up">
        <figure className="size-[90px] rounded-full">
          <Image
            src={iconUrl}
            alt="icon"
            width={90}
            height={90}
            className="rounded-full"
            loader={imageLoader}
          />
        </figure>
      </Animate>
      <h1 className="whitespace-pre-wrap text-center text-[64px] font-[400] leading-[69.12px] text-brand-primary-4 ">
        {prefix}
        <Counter target={target} />
        {suffix}
      </h1>
      <BlogTypography
        animated
        variant="p1-large"
        color="primary-4"
        className="text-center text-[0.8rem] uppercase"
      >
        {text}
      </BlogTypography>
    </div>
  );
};

const registerVariableStatItem = () => {
  Builder.registerComponent(VariableStatItem, {
    name: "VariableStatItem",
    inputs: [
      {
        name: "text",
        type: "string",
        defaultValue: "industry awards",
      },
      {
        name: "target",
        type: "number",
        defaultValue: 145,
      },
      {
        name: "iconUrl",
        type: "file",
        allowedFileTypes: ["png", "svg", "jpeg", "jpg", "gif", "webp"],
      },
      {
        name: "prefix",
        type: "string",
      },
      {
        name: "suffix",
        type: "string",
      },
    ],
  });
};

export { VariableStatItem, registerVariableStatItem };
