import { BlogTypography } from "~/components/ui/blog-typography";
import { formatDate } from "~/utils";

interface Props {
  episode: number;
  date: string;
}

const PodcastEpisodeText = ({ date, episode }: Props) => {
  const text = `Episode ${episode} • ${formatDate(date)}`;
  return (
    <BlogTypography
      variant="p2-medium"
      className="font-bold uppercase  leading-normal tracking-[0.9px] text-[#6941C6]"
    >
      {text}
    </BlogTypography>
  );
};

export { PodcastEpisodeText };
