import { useRef } from "react";

import { motion, useInView } from "framer-motion";

export const Animate = ({
  type,
  children,
  className,
  once = true,
  amount = 0.3,
  enterDelay = 0,
  exitDelay = 0,
}: {
  type: "appear-up";
  children: React.ReactNode;
  className?: string;
  once?: boolean;
  amount?: number;
  enterDelay?: number;
  exitDelay?: number;
}) => {
  const Component = {
    "appear-up": AppearUp,
  }[type];

  return (
    <Component
      once={once}
      amount={amount}
      enterDelay={enterDelay}
      exitDelay={exitDelay}
      className={className}
    >
      {children}
    </Component>
  );
};

const AppearUp = ({
  children,
  className,
  once,
  amount,
  enterDelay,
  exitDelay,
}: {
  children: React.ReactNode;
  className?: string;
  once?: boolean;
  amount?: number;
  enterDelay?: number;
  exitDelay?: number;
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once, amount });
  const animations = {
    visible: { opacity: 1, translateY: 0, transition: { delay: enterDelay } },
    hidden: { opacity: 0, translateY: 30, transition: { delay: exitDelay } },
  };

  return (
    <motion.div
      animate={isInView ? "visible" : "hidden"}
      ref={ref}
      className={className}
      initial="hidden"
      variants={animations}
      transition={{ duration: 0.4, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};
