import { forwardRef, useImperativeHandle, useState } from "react";

import Image from "next/image";
import Link from "next/link";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "~/components/ui/drawer";
import { NavLink } from "~/components/ui/nav-link";
import { imageLoader } from "~/utils";

interface Props {
  children: React.ReactNode;
  bgImage: string;
  logo: string;
  links: Array<{
    id: string;
    name: string;
    url: string;
  }>;
}

export interface NavigationMenuRef {
  isOpen: boolean;
}

const NavigationMenu = forwardRef<NavigationMenuRef, Props>(
  ({ children, bgImage, logo, links }, ref) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      isOpen: open,
    }));

    return (
      <Drawer direction="right" onOpenChange={setOpen} open={open}>
        <DrawerTrigger asChild>{children}</DrawerTrigger>
        <DrawerContent className="inset-0">
          <div className="absolute inset-0 -z-10">
            <div className="size-full bg-brand-primary-2/90" />
            <Image
              src={bgImage}
              fill
              role="presentation"
              alt="Background image"
              className="isolate -z-10 object-cover"
              loader={imageLoader}
              priority
            />
          </div>
          <div className="flex w-full flex-col items-center">
            <div className="flex w-full items-start pt-8">
              <Link
                className="relative mx-auto h-14 w-32 translate-x-3"
                href="/"
                onClick={handleClose}
              >
                <Image
                  src={logo}
                  alt="Logo"
                  fill
                  quality={100}
                  className="object-contain"
                  loader={imageLoader}
                />
              </Link>
              <DrawerClose asChild>
                <button className="relative right-8 scale-100 cursor-pointer text-white transition-colors duration-300 hover:text-brand-primary-3 md:right-12">
                  {closeIcon}
                </button>
              </DrawerClose>
            </div>
            <div className="mt-20 flex w-full flex-col items-center gap-[17px] overflow-auto pb-4 md:mt-28 md:gap-6 md:p-0">
              {links.map(({ id, name, url }) => (
                <NavLink
                  key={id}
                  href={url ?? ""}
                  exact
                  onClick={handleClose}
                  className="text-center text-[33px]  font-normal text-white transition-colors duration-300 hover:text-[#BFE189] md:text-[2.75rem]"
                  activeClassName="text-brand-primary-3"
                >
                  {name}
                </NavLink>
              ))}
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    );
  }
);
NavigationMenu.displayName = "NavigationMenu";

export { NavigationMenu };

export const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
  >
    <path
      d="M19.0873 2.60547L2.69824 18.9945M2.69824 2.60547L19.0873 18.9945"
      stroke="white"
      strokeWidth="3.90241"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
