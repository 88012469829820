import { useRef } from "react";

import { withChildren } from "@builder.io/react";
import { Builder } from "@builder.io/sdk";
import { type MotionValue, motion, useScroll } from "framer-motion";
import { useParallax } from "~/hooks/use-parallax";
import { cn } from "~/utils";
interface Props {
  children: React.ReactNode;
  circleColor: string;
  backgroundColor: string;
  speed: number;
  centered: boolean;
  overflowHidden?: boolean;
}

const ParallaxCircle = ({
  circleColor,
  position,
  centered = true,
  circles = 4,
  borderWidth = 2,
  overflowHidden = true,
}: {
  circleColor: string | MotionValue<string>;
  position: MotionValue<number>;
  centered: boolean;
  circles?: number;
  borderWidth?: number;
  overflowHidden?: boolean;
}) => (
  <motion.div
    style={{ y: position }}
    className="absolute left-0 top-0 z-0 flex size-full flex-col justify-center"
  >
    <div className={cn("md:table", { "overflow-hidden": overflowHidden })}>
      {Array.from({ length: circles }).map((_, index) => (
        <motion.div
          key={index}
          style={{
            borderColor: circleColor,
            borderWidth,
          }}
          className={cn(
            "aspect-square h-auto w-[85%] border-spacing-1 rounded-full border-2 md:w-[55%] lg:w-[45%]",
            {
              "mt-60": index !== 0,
            },
            {
              "mx-auto": centered,
            },
            {
              "ml-[50%] md:ml-auto md:mr-[50%]": !centered && index % 2 === 0,
            },
            {
              "ml-[-50%] md:mr-auto md:ml-[50%]": !centered && index % 2 !== 0,
            }
          )}
        />
      ))}
    </div>
  </motion.div>
);

const TextSectionParallaxCircle = (props: Props) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const position = useParallax(scrollYProgress, props.speed ?? 300);

  return (
    <div
      style={{ clipPath: "inset(0)", backgroundColor: props.backgroundColor }}
      className="relative flex w-full overflow-y-clip md:px-9"
      ref={ref}
    >
      <ParallaxCircle
        circleColor={props.circleColor}
        position={position}
        centered={props.centered}
      />
      {props.children}
    </div>
  );
};

const registerTextSectionParallaxCircle = () => {
  Builder.registerComponent(withChildren(TextSectionParallaxCircle), {
    name: "Text Section Parallax Circle",
    defaultChildren: [
      {
        "@type": "@builder.io/sdk:Element",
        component: {
          name: "Typography",
          options: {
            text: "Title",
          },
        },
      },
    ],
    inputs: [
      {
        name: "circleColor",
        type: "color",
        defaultValue: "#E8EAEF",
      },
      {
        name: "backgroundColor",
        type: "color",
        defaultValue: "#FFFFFF",
      },
      {
        name: "speed",
        type: "number",
      },
      {
        name: "centered",
        type: "boolean",
        defaultValue: true,
      },
    ],
  });
};
export {
  TextSectionParallaxCircle,
  ParallaxCircle,
  registerTextSectionParallaxCircle,
};
