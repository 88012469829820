import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import { BlogTypography } from "~/components/ui/blog-typography";
import { TypographyDeprecated } from "~/components/ui/typography";
import { imageLoader } from "~/utils";

import { Animate } from "../animation/animate";

interface Props {
  title: string;
  services: Array<{ service: string }>;
  icon: {
    image: string;
    backgroundColor: string;
  };
}

const ServiceCard = ({ services = [], title, icon }: Props) => {
  return (
    <Animate type="appear-up">
      <article className="relative flex size-full min-h-[406px] max-w-80 flex-col lg:max-w-96">
        <div
          className="absolute top-[-5px] grid size-[84px] place-items-center self-center overflow-hidden rounded-full bg-brand-primary-1"
          style={{
            backgroundColor: icon?.backgroundColor,
          }}
        >
          <Image
            src={icon?.image}
            fill
            alt="Service Icon"
            className="object-contain"
            loader={imageLoader}
          />
        </div>

        <div className="mt-10 flex h-full flex-col items-center gap-8 rounded-lg bg-white px-10 pt-14">
          <BlogTypography variant="h2" align="center">
            {title}
          </BlogTypography>

          <ul className="ml-[-24px] flex list-none flex-col items-center space-y-1">
            {services.map((item, index) => (
              <li key={`service-${item.service}-${index}`}>
                <TypographyDeprecated
                  variant="subtitle"
                  className="text-[12.8px] font-bold uppercase leading-5 tracking-widest"
                  align="center"
                >
                  {item.service}
                </TypographyDeprecated>
              </li>
            ))}
          </ul>
        </div>
      </article>
    </Animate>
  );
};

const registerServiceCard = () => {
  Builder.registerComponent(ServiceCard, {
    name: "ServiceCard",
    inputs: [
      {
        name: "title",
        type: "string",
        defaultValue: "Branding",
      },
      {
        name: "services",
        type: "list",
        onChange: (options: Map<string, any>) => {
          if (options.get("services").length > 6) {
            options.set("services", options.get("services").slice(0, 6));
            alert("Maximum services is 6, delete items to continue.");
          }
        },
        subFields: [
          {
            name: "service",
            type: "string",
            defaultValue: "Service 1",
          },
        ],
      },
      {
        name: "icon",
        type: "object",
        subFields: [
          {
            name: "image",
            friendlyName: "Icon Image",
            helperText: "Recommended size: 64x64",
            type: "file",
            allowedFileTypes: ["png", "svg"],
          },

          {
            name: "backgroundColor",
            friendlyName: "Background Color",
            type: "color",
            defaultValue: "#8ACE1E",
          },
        ],
      },
    ],
  });
};

export { ServiceCard, registerServiceCard };
