import { withChildren } from "@builder.io/react";
import { Builder } from "@builder.io/sdk";
import Link from "next/link";

interface Props {
  children: React.ReactNode;
  href: string;
  attributes?: Record<string, string>;
}

const LinkBox = ({
  children,
  attributes: { key, ...attributes } = {},
  href,
}: Props) => {
  return (
    <Link key={key} {...attributes} href={href}>
      {children}
    </Link>
  );
};

const registerLinkBox = () => {
  Builder.registerComponent(withChildren(LinkBox), {
    name: "LinkBox",
    noWrap: true,
    inputs: [
      {
        name: "href",
        type: "string",
        required: true,
        defaultValue: "#",
        helperText: "The URL to link to",
      },
    ],
  });
};

export { LinkBox, registerLinkBox };
