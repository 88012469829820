"use client";

import { useEffect, useRef, useState } from "react";

import { Builder } from "@builder.io/sdk";
import {
  type MotionValue,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";
import Link from "next/link";
import { BlogTypography } from "~/components/ui/blog-typography";
import { Button } from "~/components/ui/button";
import { useMediaQuery } from "~/hooks/use-media-query";
import { cn, getWindow } from "~/utils";

import { ParallaxCircle } from "../text-section-parallax-circle/text-section-parallax-circle";

interface Props {
  firstSlideTitle: string;
  firstSlideDescription: string;
  firstSlideButtonLabel: string;
  firstSlideLink: string;
  firstSlideIsExternalLink: boolean;
  secondSlideTitle: string;
  secondSlideDescription: string;
  secondSlideButtonLabel: string;
  secondSlideLink: string;
  secondSlideIsExternalLink: boolean;
  thirdSlideTitle: string;
  thirdSlideDescription: string;
  thirdSlideButtonLabel: string;
  thirdSlideLink: string;
  thirdSlideIsExternalLink: boolean;
}

const GrowingCircle = ({
  textSectionYProgress,
}: {
  textSectionYProgress: MotionValue<number>;
}) => {
  const scale = useTransform(
    textSectionYProgress,
    [0.0, 0.05, 0.15, 0.33],
    [1, 2, 10, 150]
  );

  const fontSizeDirty = useTransform(
    textSectionYProgress,
    [0.0, 0.05, 0.15, 0.33],
    [20, 40, 150, 1250],
    {}
  );

  const fontSize = useTransform(fontSizeDirty, (value) => {
    return `${Math.ceil(value)}px`;
  });

  const translateX = useTransform(
    textSectionYProgress,
    [0, 0.05, 0.12, 1.0],
    ["0", "0", "41.4%", "41.4%"]
  );

  const translateY = useTransform(
    textSectionYProgress,
    [0, 0.05, 0.12, 1.0],
    ["0", "0", "-4.5%", "-4.5%"]
  );

  const transformOrigin = useTransform(
    textSectionYProgress,
    [0, 0.05, 0.12, 1.0],
    ["120px 120px", "120px 120px", "44.5px 124px", "44.5px 124px"]
  );
  const circleColor = useTransform(
    textSectionYProgress,
    [0, 0.47, 0.45, 0.63, 0.83],
    ["#FFFFFF", "#8ACE1E80", "#8ACE1E80", "#FFFFFF", "#BAC0CE"]
  );
  const isMobile = useMediaQuery("(max-width: 768px)");
  const breakpoints = isMobile
    ? [
        0,
        getWindow() ? -window.screen.height * 0.65 : 0,
        getWindow() ? -window.screen.height * 1.3 : 0,
        getWindow() ? -window.screen.height * 2.2 : 0,
      ]
    : [
        getWindow() ? window.screen.height * 0.8 : 0,
        0,
        getWindow() ? -window.screen.height * 0.8 : 0,
        getWindow() ? -window.screen.height * 1.75 : 0,
      ];
  const circlePosition = useTransform(
    textSectionYProgress,
    [0, 0.515, 0.71, 1.0],
    breakpoints
  );
  // to - totalPercentage * 0.5
  const textOpacity = useTransform(textSectionYProgress, [0.0, 0.4], [1, 0]);
  const backgroundColor = useTransform(
    textSectionYProgress,
    [0, 0.44, 0.45, 0.63, 0.83],
    ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#D1D5DE", "#454F68"]
  );

  const [opacityLevel, setOpacityLevel] = useState(0);

  useEffect(
    () =>
      textOpacity.onChange((latest) => {
        setOpacityLevel(latest);
      }),
    [textOpacity]
  );

  return (
    <div className="fixed top-0 z-[5] flex size-full flex-col items-center justify-center">
      <motion.div
        style={{
          scale,
          transformOrigin,
          backgroundColor,
          translateZ: 0,
        }}
        className={`absolute inset-auto z-0 aspect-square size-[240px] items-center justify-center rounded-full bg-white`}
      />
      <motion.div
        className={`z-[2] flex aspect-square size-[240px] items-center justify-center rounded-full bg-transparent !subpixel-antialiased`}
      >
        <motion.p
          style={{
            fontSize,
            lineHeight: "unset",
            opacity: textOpacity,
            translateX,
            translateY,
            transition: "font-size .1s linear",
            MozTransition: "revert",
          }}
          className={cn(
            "whitespace-nowrap text-center font-serif text-[20px] italic leading-[27px]",
            { "hidden invisible": opacityLevel === 0 }
          )}
        >
          Why are we the
          <br />
          home for healthcare&rsquo;s
          <br />
          leading brands?
        </motion.p>
      </motion.div>
      <div className="fixed left-0 top-0 z-[1] h-screen w-screen">
        <ParallaxCircle
          circleColor={circleColor}
          position={circlePosition}
          centered={false}
          circles={16}
          borderWidth={1}
          overflowHidden={false}
        />
      </div>
    </div>
  );
};

const AnimatedCard = ({
  title,
  description,
  buttonLabel,
  link,
  isExternalLink,
  scrollYProgress,
  from,
  to,
  startOffset = 0.05,
  whiteText = false,
  scale = false,
}: {
  title: string;
  description: string;
  buttonLabel: string;
  link: string;
  isExternalLink: boolean;
  scrollYProgress: MotionValue<number>;
  startOffset?: number;
  whiteText?: boolean;
  from: number;
  to: number;
  scale?: boolean;
}) => {
  const totalPercentage = to - from;
  const slideOpacity = useTransform(
    scrollYProgress,
    [0, from - startOffset, from, to - 0.05, to],
    [0, 0, 1, 1, 0]
  );
  const slideTranslationY = useTransform(
    scrollYProgress,
    [to - 0.05, to],
    [0, -100]
  );
  const slideScale = useTransform(
    scrollYProgress,
    [0, from - startOffset, from],
    [0, 0, 1]
  );

  const descriptionOpacity = useTransform(
    scrollYProgress,
    [from, to - totalPercentage * 0.7],
    [0, 1]
  );
  const descriptionTranslateY = useTransform(
    scrollYProgress,
    [from, to - totalPercentage * 0.7],
    [50, 0]
  );
  const buttonOpacity = useTransform(
    scrollYProgress,
    [to - totalPercentage * 0.7, to - totalPercentage * 0.5],
    [0, 1]
  );
  const buttonTranslateY = useTransform(
    scrollYProgress,
    [to - totalPercentage * 0.7, to - totalPercentage * 0.5],
    [50, 0]
  );

  const [opacityLevel, setOpacityLevel] = useState(0);

  useEffect(
    () =>
      slideOpacity.onChange((latest) => {
        setOpacityLevel(latest);
      }),
    [slideOpacity]
  );

  return (
    <motion.div
      className={cn(
        "fixed left-0 top-0 z-[6] flex h-screen w-screen flex-col items-center justify-center bg-transparent",
        { "hidden invisible": opacityLevel === 0 }
      )}
      style={{
        opacity: slideOpacity,
        scale: scale ? slideScale : undefined,
        translateY: slideTranslationY,
      }}
    >
      {title && (
        <BlogTypography
          variant="h1"
          color={whiteText ? "white" : "primary-4"}
          className="mx-auto max-w-prose text-center leading-[49px]"
        >
          {title}
        </BlogTypography>
      )}
      <motion.div
        style={{
          opacity: descriptionOpacity,
          translateY: descriptionTranslateY,
        }}
      >
        {description && (
          <BlogTypography
            variant="p1-large"
            color={whiteText ? "white" : "primary-4"}
            className="mx-auto mt-[24px] max-w-[350px] text-center leading-[26px] md:mt-[26px] md:max-w-[600px] md:text-[27px] md:leading-[41px]"
          >
            {description}
          </BlogTypography>
        )}
      </motion.div>

      {buttonLabel && (
        <motion.div
          className="mt-8 flex justify-center"
          style={{ translateY: buttonTranslateY, opacity: buttonOpacity }}
        >
          <Button variant="primary" color="primary" size="xs" asChild>
            {isExternalLink ? (
              <a href={link}>{buttonLabel}</a>
            ) : (
              <Link href={link}>{buttonLabel}</Link>
            )}
          </Button>
        </motion.div>
      )}
    </motion.div>
  );
};

const HomeAnimation = ({
  firstSlideTitle,
  firstSlideDescription,
  firstSlideButtonLabel,
  firstSlideLink,
  firstSlideIsExternalLink,
  secondSlideTitle,
  secondSlideDescription,
  secondSlideButtonLabel,
  secondSlideLink,
  secondSlideIsExternalLink,
  thirdSlideTitle,
  thirdSlideDescription,
  thirdSlideButtonLabel,
  thirdSlideLink,
  thirdSlideIsExternalLink,
}: Props) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end center"],
  });

  return (
    <div>
      <div
        style={{ clipPath: "inset(0)" }}
        className="relative min-h-[1200vh] w-auto max-w-full bg-brand-primary-3"
        ref={ref}
      >
        <GrowingCircle textSectionYProgress={scrollYProgress} />

        <AnimatedCard
          title={firstSlideTitle}
          description={firstSlideDescription}
          buttonLabel={firstSlideButtonLabel}
          link={firstSlideLink}
          isExternalLink={firstSlideIsExternalLink}
          scrollYProgress={scrollYProgress}
          startOffset={0.3}
          from={0.45}
          to={0.58}
          scale
        />
        <AnimatedCard
          title={secondSlideTitle}
          description={secondSlideDescription}
          buttonLabel={secondSlideButtonLabel}
          link={secondSlideLink}
          isExternalLink={secondSlideIsExternalLink}
          scrollYProgress={scrollYProgress}
          from={0.63}
          to={0.79}
        />
        <AnimatedCard
          title={thirdSlideTitle}
          description={thirdSlideDescription}
          buttonLabel={thirdSlideButtonLabel}
          link={thirdSlideLink}
          isExternalLink={thirdSlideIsExternalLink}
          scrollYProgress={scrollYProgress}
          whiteText
          from={0.83}
          to={1.0}
        />
      </div>
    </div>
  );
};

const registerHomeAnimation = () => {
  Builder.registerComponent(HomeAnimation, {
    name: "Home Screen Animation",
    inputs: [
      {
        name: "firstSlideTitle",
        friendlyName: "First Slide Title",
        type: "longText",
        required: true,
        defaultValue: "Big Ideas.\nBigger Impact.",
      },
      {
        name: "firstSlideDescription",
        friendlyName: "First Slide Description",
        type: "longText",
        required: true,
        defaultValue:
          "From precision marketing to emerging media, from podcasts to documentary films, we create work that touches more than 100 million patients a year — online, offline and everywhere in between.",
        max: 200,
      },
      {
        name: "firstSlideButtonLabel",
        friendlyName: "First Slide Button Label",
        type: "string",
        required: true,
        defaultValue: "Learn More",
      },
      {
        name: "firstSlideLink",
        friendlyName: "First Slide Link",
        type: "string",
        required: true,
      },
      {
        name: "firstSlideIsExternalLink",
        friendlyName: "First Slide Is External Link?",
        type: "boolean",
        required: true,
        defaultValue: false,
      },
      {
        name: "secondSlideTitle",
        friendlyName: "Second Slide Title",
        type: "longText",
        required: true,
        defaultValue: "Pathfinders and\nFuture Builders",
      },
      {
        name: "secondSlideDescription",
        friendlyName: "Second Slide Description",
        type: "longText",
        required: true,
        defaultValue:
          "Our thought leadership is forward-looking, thought-provoking and paradigm-shifting. We’re not waiting on the future. We’re making it happen.",
        max: 200,
      },
      {
        name: "secondSlideButtonLabel",
        friendlyName: "Second Slide Button Label",
        type: "string",
        required: true,
        defaultValue: "Learn More",
      },
      {
        name: "secondSlideLink",
        friendlyName: "Second Slide Link",
        type: "string",
        required: true,
      },
      {
        name: "secondSlideIsExternalLink",
        friendlyName: "Third Slide Is External Link?",
        type: "boolean",
        required: true,
        defaultValue: false,
      },
      {
        name: "thirdSlideTitle",
        friendlyName: "Third Slide Title",
        type: "longText",
        required: true,
        defaultValue: "Unparalleled\nService",
      },
      {
        name: "thirdSlideDescription",
        friendlyName: "Third Slide Description",
        type: "longText",
        required: true,
        defaultValue:
          "Lorem ipsum dolor sit amet consectetur. Leo quisque orci vitae et augue placerat tellus. Ut hendrerit sed ornare dis auctor lobortis sit.",
        max: 200,
      },
      {
        name: "thirdSlideButtonLabel",
        friendlyName: "Third Slide Button Label",
        type: "string",
        required: true,
        defaultValue: "Learn More",
      },
      {
        name: "thirdSlideLink",
        friendlyName: "Third Slide Link",
        type: "string",
        required: true,
      },
      {
        name: "thirdSlideIsExternalLink",
        friendlyName: "Third Slide Is External Link?",
        type: "boolean",
        required: true,
        defaultValue: false,
      },
    ],
  });
};
export { HomeAnimation, registerHomeAnimation };
