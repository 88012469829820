"use client";
import { Content, useIsPreviewing } from "@builder.io/react";
import { type BuilderContent, builder } from "@builder.io/sdk";
import { useRouter, notFound } from "next/navigation";

import "../builder-registry";

interface BuilderPageProps {
  content?: BuilderContent;
  model?: string;
  context?: any;
}

// Builder Public API Key set in .env file
builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

export function RenderBuilderContent({
  content,
  model,
  context,
}: BuilderPageProps) {
  const router = useRouter();
  // Call the useIsPreviewing hook to determine if
  // the page is being previewed in Builder
  const isPreviewing = useIsPreviewing();
  // If "content" has a value or the page is being previewed in Builder,
  // render the BuilderComponent with the specified content and model props.
  if (!!content || isPreviewing) {
    return (
      <Content
        content={content}
        model={model}
        context={{ ...context, router }}
      />
    );
  }
  // If the "content" is falsy and the page is
  // not being previewed in Builder, render the
  // DefaultErrorPage with a 404.
  return notFound();
}
