"use client";

import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import { useMediaQuery } from "~/hooks/use-media-query";
import { imageLoader } from "~/utils";

import { InfiniteSlider } from "../ui/infinite-slider";

interface Props {
  items: Item[];
  mobileVelocity: number;
  desktopVelocity: number;
}

interface Item {
  image: string;
  altText?: string;
}

const ImageSlider = ({ items, mobileVelocity, desktopVelocity }: Props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <section>
      <InfiniteSlider velocity={isMobile ? mobileVelocity : desktopVelocity}>
        {items?.map((item, index) => (
          <div key={index} className="relative mr-6 size-[150px] md:mr-20 ">
            <Image
              src={item.image}
              alt={item.altText ?? "logo"}
              className="object-contain py-8"
              fill
              loader={imageLoader}
            />
          </div>
        ))}
      </InfiniteSlider>
    </section>
  );
};

const registerImageSlider = () => {
  Builder.registerComponent(ImageSlider, {
    name: "InfiniteImageSlider",
    inputs: [
      {
        name: "mobileVelocity",
        friendlyName: "Mobile Velocity",
        helperText: "The speed at which the logos slides",
        type: "number",
      },
      {
        name: "desktopVelocity",
        friendlyName: "Desktop Velocity",
        helperText: "The speed at which the logos slides",
        type: "number",
      },
      {
        name: "items",
        type: "list",
        subFields: [
          {
            name: "image",
            type: "file",
            required: true,
            allowedFileTypes: ["jpeg", "jpg", "png", "svg", "gif"],
          },
          {
            name: "altText",
            friendlyName: "Alt Text",
            type: "string",
          },
        ],
      },
    ],
  });
};

export { ImageSlider, registerImageSlider };
