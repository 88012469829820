import { useRef } from "react";

import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import Link from "next/link";
import { Button } from "~/components/ui/button";
import {
  Carousel as BaseCarousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  useCarousel,
} from "~/components/ui/carousel";
import { imageLoader } from "~/utils";

import { TypographyDeprecated } from "../ui/typography";

interface Props {
  items: CarouselItemData[];
}

type CarouselItemData = {
  image: string;
  video: string;
  title: string;
  description: string;
  linkText: string;
} & (
  | {
      isExternalLink: true;
      link: string;
      reference?: never;
    }
  | {
      isExternalLink: false;
      link?: never;
      reference: {
        value: {
          data: {
            url: string;
          };
        };
      };
    }
);

const HeroCarousel = ({ items = [] }: Props) => {
  return (
    <BaseCarousel className="w-auto">
      <CarouselContent className="h-screen max-h-[1440px] md:h-[80vh] ">
        {items.map((item, index) => (
          <HeroCarouselItem key={index} index={index} {...item} />
        ))}
      </CarouselContent>
      <CarouselPrevious
        className="absolute left-8 top-1/2 z-10 hidden md:block"
        icon
        variant="ghost"
        iconClassName="bg-white"
        size="xl"
      />
      <CarouselNext
        className="absolute right-8 top-1/2 z-10 hidden md:block"
        icon
        variant="ghost"
        iconClassName="bg-white"
        size="xl"
      />
      <CarouselDots className="absolute bottom-[5%] left-1/2 -translate-x-1/2 md:bottom-[20%]" />
    </BaseCarousel>
  );
};

const HeroCarouselItem = ({
  image,
  video,
  title,
  description,
  isExternalLink,
  link,
  linkText,
  reference,
  index,
}: CarouselItemData & {
  index: number;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { scrollNext, current } = useCarousel();
  const isImage = !!image;

  if (videoRef.current && video) {
    if (current === index) {
      void (async () => {
        await videoRef.current?.play();
      })();
    } else {
      videoRef.current?.pause();
    }
  }

  return (
    <CarouselItem className="h-full w-screen">
      <div className="relative flex h-full flex-col items-center justify-center">
        {isImage ? (
          <Image
            priority={index === 0}
            fetchPriority="high"
            src={image}
            alt={title}
            fill
            className="w-screen object-cover brightness-50"
            loader={imageLoader}
          />
        ) : (
          <video
            ref={videoRef}
            muted
            preload="auto"
            onEnded={scrollNext}
            playsInline
            disablePictureInPicture
            className="h-screen w-full object-cover brightness-50"
          >
            <source src={video} type="video/mp4" />
          </video>
        )}

        <div className="absolute top-1/2 z-10 flex -translate-y-1/2 flex-col items-center gap-6 px-8 md:px-28">
          <TypographyDeprecated
            animated
            variant="subtitle"
            color="white"
            size="medium"
            className="text-center"
          >
            {description}
          </TypographyDeprecated>
          <TypographyDeprecated
            animated
            variant="h1"
            color="white"
            className="max-w-screen-lg text-center"
          >
            {title}
          </TypographyDeprecated>
        </div>
        <div className="absolute bottom-[15%] md:bottom-1/4">
          <Button variant="primary" color="primary" size="xs" asChild>
            {isExternalLink ? (
              <a href={link} target="_blank">
                {linkText}
              </a>
            ) : (
              <Link href={reference?.value?.data?.url ?? ""}>{linkText}</Link>
            )}
          </Button>
        </div>
      </div>
    </CarouselItem>
  );
};

const registerHeroCarousel = () => {
  Builder.registerComponent(HeroCarousel, {
    name: "Hero Carousel",
    inputs: [
      {
        name: "items",
        type: "list",
        subFields: [
          {
            name: "image",
            type: "file",
            helperText:
              "You can select either an image or a video. If both are select, the image will be used.",
            allowedFileTypes: ["jpeg", "jpg", "png", "svg", "gif", "webp"],
          },
          {
            name: "video",
            type: "file",
            helperText:
              "You can select either an image or a video. If both are select, the image will be used.",
            allowedFileTypes: ["mp4"],
          },
          {
            name: "title",
            type: "string",
          },
          {
            name: "description",
            type: "string",
          },
          {
            name: "isExternalLink",
            friendlyName: "Is external link?",
            type: "boolean",
          },
          {
            name: "link",
            friendlyName: "Link",
            type: "string",
            regex: {
              pattern: "https?://.+",
              message: "Please enter a valid URL",
            },
          },
          {
            name: "linkText",
            friendlyName: "Link text",
            type: "string",
          },
          {
            name: "reference",
            friendlyName: "Reference",
            type: "reference",
          },
        ],
      },
    ],
  });
};

export { HeroCarousel, registerHeroCarousel as registerCarousel };
