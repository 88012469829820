import { Builder } from "@builder.io/sdk";

interface Props {
  content: string;
}

const OldPostContent = ({ content }: Props) => {
  return (
    <div
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="old-wp-post-content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

const registerOldPostContent = () => {
  Builder.registerComponent(OldPostContent, {
    name: "OldPostContent",
    inputs: [
      {
        name: "content",
        type: "string",
        required: true,
      },
    ],
  });
};

export { OldPostContent, registerOldPostContent };

// state.$item.data.title
