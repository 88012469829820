import { Builder } from "@builder.io/sdk";
import { Button } from "~/components/ui/button";
import { type Podcast } from "~/types/podcast";
import { cn } from "~/utils";

interface Props extends Pick<Podcast, "metadata"> {
  color: "primary" | "white";
}

const PodcastSubscribeLinks = ({ metadata, color = "primary" }: Props) => {
  if (!metadata) return null;
  return (
    <div className="flex items-center gap-4">
      {!!metadata.applePodcastUrl && (
        <Button
          icon
          iconUrl="/images/icons/apple-podcasts.svg"
          variant={color}
          rounded="full"
          className="size-[30px] p-0"
          iconClassName="bg-brand-primary-4"
          link={{
            external: true,
            href: metadata.applePodcastUrl,
          }}
        >
          <span className="sr-only">Apple Podcasts</span>
        </Button>
      )}
      {!!metadata.spotifyUrl && (
        <Button
          icon
          iconUrl="/images/icons/spotify.svg"
          variant="secondary"
          rounded="full"
          className="size-[30px] p-0"
          iconClassName={cn("scale-125 bg-brand-primary-3", {
            "bg-white": color === "white",
          })}
          link={{
            external: true,
            href: metadata.spotifyUrl,
          }}
        >
          <span className="sr-only">Spotify</span>
        </Button>
      )}
    </div>
  );
};

const registerPodcastSubscribeLinks = () => {
  Builder.registerComponent(PodcastSubscribeLinks, {
    name: "PodcastSubscribeLinks",
    inputs: [
      {
        name: "metadata",
        type: "object",
        required: true,
        subFields: [
          {
            name: "rssFeed",
            type: "string",
            defaultValue: "",
          },
          {
            name: "applePodcastUrl",
            type: "string",
            defaultValue: "",
          },
          {
            name: "spotifyUrl",
            type: "string",
            defaultValue: "",
          },
        ],
      },
      {
        name: "color",
        type: "string",
        enum: ["primary", "white"],
        defaultValue: "primary",
      },
    ],
  });
};

export { PodcastSubscribeLinks, registerPodcastSubscribeLinks };
