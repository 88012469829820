import { useCallback, useSyncExternalStore } from "react";

export function useMediaQuery(query: string): boolean {
  const subscribe = useCallback(
    (callback: (e: MediaQueryListEvent) => void) => {
      const matchMedia = window.matchMedia(query);

      // Modern browsers support addEventListener on matchMedia
      if (matchMedia.addEventListener) {
        matchMedia.addEventListener("change", callback);
      } else {
        // Fallback for older browsers
        matchMedia.addListener(callback);
      }

      return () => {
        if (matchMedia.removeEventListener) {
          matchMedia.removeEventListener("change", callback);
        } else {
          // Fallback for older browsers
          matchMedia.removeListener(callback);
        }
      };
    },
    [query]
  );

  const getSnapshot = (): boolean => {
    return window.matchMedia(query).matches;
  };

  const getServerSnapshot = (): boolean => {
    // This hook is client-side only, so we can throw an error or return a default value
    // For SSR (Server-Side Rendering) support, you may want to return a sensible default
    // or ensure this hook is not used during SSR.
    return false;
  };

  // The useSyncExternalStore hook expects the subscribe function, the getSnapshot function,
  // and optionally a getServerSnapshot function for SSR environments.
  // It returns the current value of the store (true or false in this case).
  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}
