import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import Link from "next/link";
import { BlogTypography } from "~/components/ui/blog-typography";
import { type Article } from "~/types/article";
import { formatDate, imageLoader, isValidDate } from "~/utils";

interface Props extends Article {
  cancelled: boolean;
  friendlyDateText: string;
}

const formatTime = (date: string | Date) =>
  Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "shortOffset",
  }).format(isValidDate(date) ? new Date(date) : new Date());

const UpcomingEventCard = ({
  blurb,
  image,
  title,
  handle,
  date,
  eventLocation,
  cancelled,
  friendlyDateText,
}: Props) => {
  const dateText =
    friendlyDateText || `${formatDate(date)} at ${formatTime(date)}`;
  const cancelledText = "Event has been cancelled";
  const upperText = [cancelled ? cancelledText : dateText, eventLocation]
    .filter((v) => !!v)
    .join("  •  ");

  return (
    <Link
      prefetch
      className="group relative flex w-full flex-col items-start gap-4 md:max-w-96"
      href={handle ?? ""}
    >
      <div className="relative h-[240px] w-full overflow-hidden">
        <Image
          src={image}
          fill
          alt={title}
          loader={imageLoader}
          className="object-cover transition duration-300 ease-in-out group-hover:scale-110"
          sizes="(max-width: 640px) 384px, (max-width: 768px) 384px"
        />
      </div>
      <div className="mx-6 flex max-w-[340px] flex-col overflow-hidden md:mx-0 md:max-w-none">
        <BlogTypography
          variant="subtitle-medium"
          className="line-clamp-2 min-h-[44px] text-lg uppercase leading-tight tracking-[0.9px] text-[#6941C6] transition duration-300 ease-in-out group-hover:opacity-70"
          fontFamily="sans"
        >
          {upperText}
        </BlogTypography>
        <BlogTypography
          variant="h4"
          className="my-[8px] line-clamp-2 text-2xl leading-tight text-brand-primary-4 transition duration-300 ease-in-out group-hover:opacity-70"
          color="primary-4"
          fontFamily="sans"
          title={title}
        >
          {title}
        </BlogTypography>
        <BlogTypography
          variant="p1-large"
          color="primary-1"
          className="line-clamp-3 leading-snug text-brand-primary-1 transition duration-300 ease-in-out group-hover:opacity-70"
          title={blurb}
        >
          {blurb}
        </BlogTypography>
      </div>
    </Link>
  );
};

const registerUpcomingEventCard = () => {
  Builder.registerComponent(UpcomingEventCard, {
    name: "UpcomingEventCard",
    inputs: [
      {
        name: "title",
        type: "string",
        required: true,
        defaultValue: "The title of the event",
      },
      {
        name: "blurb",
        type: "string",
        required: true,
        defaultValue: "The blurb of the event",
      },
      {
        name: "image",
        type: "file",
        allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
        required: true,
      },
      {
        name: "category",
        type: "text",
        enum: ["our-work", "insights"],
        required: true,
      },
      {
        name: "handle",
        type: "string",
        required: true,
        defaultValue: "upcoming-event",
      },
      {
        name: "date",
        type: "date",
        required: true,
        defaultValue: new Date(),
      },
      {
        name: "friendlyDateText",
        type: "string",
        required: false,
      },
      {
        name: "eventLocation",
        type: "string",
        required: true,
        defaultValue: "WEBINAR",
      },
      {
        name: "cancelled",
        type: "boolean",
        defaultValue: false,
      },
    ],
  });
};

export { UpcomingEventCard, registerUpcomingEventCard };
