"use client";

import { useState } from "react";

import { builder } from "@builder.io/sdk";
import { useInfiniteQuery } from "@tanstack/react-query";

async function getCaseStudies(limit: number, page: number, tag: string) {
  const selectedTag = tag
    ? await builder
        .get("tags", {
          options: {
            limit: 1,
          },
          query: {
            "data.handle": tag,
            "data.category": "case-studies",
          },
          fields: "id,data",
        })
        .toPromise()
    : null;

  const data = await builder.getAll("case-study-post", {
    sort: {
      "data.order": 1,
      "data.date": -1,
    },
    limit: limit + 1,
    query: {
      "data.unlisted": {
        $not: true,
      },
      ...(selectedTag && {
        "data.tags": {
          $elemMatch: { "tag.id": selectedTag.id },
        },
      }),
    },
    options: {
      offset: page * limit,
      noTargeting: true,
    },
    omit: "data.blocks",
  });

  return {
    data: data.slice(0, limit) as Array<{
      id: string;
      data: any;
    }>,
    hasNextPage: data.length > limit,
  };
}

export const useCaseStudiesQuery = ({
  items,
  limit,
  page = 0,
  tag = "all",
}: {
  items: Array<{
    id: string;
    data: any;
  }>;
  page?: number;
  tag: string;
  limit: number;
}) => {
  const [nextPageMap, setNextPageMap] = useState<Record<number, boolean>>({});

  return useInfiniteQuery({
    initialData: {
      pageParams: [0],
      pages: [items],
    },
    queryKey: ["case-study-list", { tag }] as const,
    queryFn: async ({ pageParam, queryKey }) => {
      const result = await getCaseStudies(limit, pageParam, queryKey[1].tag);
      setNextPageMap((prev) => ({ ...prev, [pageParam]: result.hasNextPage }));
      return result.data;
    },
    initialPageParam: page,
    getNextPageParam: () => {
      return nextPageMap[page] ? page + 1 : null;
    },
  });
};
