import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import Link from "next/link";
import { BlogTypography } from "~/components/ui/blog-typography";
import { type Article } from "~/types/article";
import { cn, imageLoader } from "~/utils";

interface Props extends Article {
  variant?: "featured" | "default";
  titleClassName?: string;
}

const ArticleCard = ({
  blurb,
  image,
  title,
  category = "",
  handle = "",
  variant = "default",
  titleClassName,
}: Props) => {
  const href = handle.startsWith("/")
    ? handle
    : `${category ?? ""}/${handle.replace("/", "")}`;

  return (
    <Link
      prefetch
      className={cn(
        "group relative flex w-full flex-col items-start gap-4 md:w-full md:max-w-96",
        {
          "w-full md:max-w-full": variant === "featured",
        }
      )}
      href={href ?? ""}
    >
      <div
        className={cn("relative h-[240px] w-full overflow-hidden", {
          "md:h-[494px]": variant === "featured",
        })}
      >
        <Image
          src={image}
          fill
          alt={title}
          loader={imageLoader}
          className={cn(
            "object-cover transition duration-300 ease-in-out group-hover:scale-110",
            {
              "group-hover:scale-105": variant === "featured",
            }
          )}
          sizes="(max-width: 640px) 384px, (max-width: 768px) 384px"
        />
      </div>
      <div
        className={cn(
          "mx-6 flex max-w-[340px] flex-col gap-2 overflow-hidden transition duration-300 ease-in-out group-hover:opacity-70 md:ml-0 md:w-full md:max-w-full",
          {
            "gap-[14px]": variant === "featured",
          }
        )}
      >
        <BlogTypography
          title={title}
          color="primary-4"
          variant={variant === "featured" ? "h1" : "h5"}
          mobileVariant="h5"
          className={cn("line-clamp-2 font-normal", titleClassName, {
            "text-[24px] leading-tight": variant === "default",
            "font-normal leading-tight": variant === "featured",
          })}
        >
          {title}
        </BlogTypography>
        <BlogTypography
          title={blurb}
          variant="p1-large"
          color="primary-1"
          className={cn("line-clamp-3 leading-snug", {
            "text-xl max-w-[900px] text-brand-primary-2":
              variant === "featured",
          })}
        >
          {blurb}
        </BlogTypography>
      </div>
    </Link>
  );
};

const registerArticleCard = () => {
  Builder.registerComponent(ArticleCard, {
    name: "ArticleCard",
    inputs: [
      {
        name: "title",
        type: "string",
        required: true,
        defaultValue: "The title of the article",
      },
      {
        name: "blurb",
        type: "string",
        required: true,
        defaultValue: "The blurb of the article",
      },
      {
        name: "image",
        type: "file",
        allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
        required: true,
      },
      {
        name: "category",
        type: "text",
        enum: ["our-work", "insights", "case-studies"],
        required: true,
      },
      {
        name: "handle",
        type: "string",
        required: true,
        defaultValue: "article-handle",
      },
    ],
  });
};

export { ArticleCard, registerArticleCard };

// state.$item.data.title
