import { Builder } from "@builder.io/sdk";
import { HubSpotForm } from "~/components/hubspot-form/hubspot-form";
import { BlogTypography } from "~/components/ui/blog-typography";
import { type BuilderDefaultProps } from "~/types/builder.types";

interface Props extends BuilderDefaultProps {
  title: string;
  subtitle: string;
}

const NewsletterForm = ({
  builderState,
  title = "Stay in Touch",
  subtitle = "Receive our updates, industry news and insights.",
}: Props) => {
  const settings = builderState?.context?.globalSettings?.newsletterForm;

  const hasSettings = Object.values(settings ?? {}).every(
    (value) => !!value && value !== ""
  );

  return hasSettings ? (
    <section className="flex w-full flex-col bg-brand-primary-3  md:items-center">
      <div className="mx-6 my-20 flex max-w-[480px] flex-col items-center gap-8 md:w-full">
        <BlogTypography variant="h0" color="primary-4" align="center">
          {title}
        </BlogTypography>
        <BlogTypography
          variant="p1-large"
          color="primary-4"
          align="center"
          className="max-w-[25ch] md:max-w-full"
        >
          {subtitle}
        </BlogTypography>
        <HubSpotForm
          className="w-full"
          region={settings!.region}
          portalId={settings!.portalId}
          formId={settings!.formId}
        />
      </div>
    </section>
  ) : null;
};

const registerNewsletterForm = () => {
  Builder.registerComponent(NewsletterForm, {
    name: "Newsletter Form",
    inputs: [
      {
        name: "title",
        type: "string",
        defaultValue: "Stay in Touch",
      },
      {
        name: "subtitle",
        type: "string",
        defaultValue: "Receive our updates, industry news and insights.",
      },
    ],
  });
};

export { NewsletterForm, registerNewsletterForm };
