import { motion } from "framer-motion";

import { Button } from "./button";

export function Modal({
  children,
  handleClose,
}: {
  children: React.ReactNode;
  handleClose?: <T>(e: React.MouseEvent<T>) => void;
}) {
  return (
    <motion.div
      onClick={handleClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="fixed inset-0 z-[100] flex content-center items-center overflow-auto bg-black/50 pt-40 md:pb-40"
    >
      <motion.dialog
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ type: "spring", stiffness: 50 }}
        open={true}
        className="relative w-full max-w-prose border-none bg-white px-8 py-6"
        onClose={handleClose}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
        <Button
          onClick={handleClose}
          variant="ghost"
          icon
          iconUrl={CLOSE_ICON_URL}
          size="lg"
          iconClassName="bg-black"
          className="absolute -top-8 right-6 cursor-pointer rounded-full bg-brand-primary-3 p-3 font-bold text-brand-primary-4 transition-colors duration-300 hover:brightness-90 md:-right-6"
        >
          X
        </Button>
      </motion.dialog>
    </motion.div>
  );
}

const CLOSE_ICON_URL = "/images/icons/close.svg";

const modalVariants = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
      mass: 1,
    },
  },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { duration: 0.3 },
  },
};
