import { cn } from "~/utils";

interface Props {
  className?: string;
  accentColor: "light-gray" | "gray" | "dark-gray" | "blue";
  children?: React.ReactNode;
  as?: React.ElementType;
}

const PodcastCardBackground = ({
  accentColor,
  className,
  children,
  as = "div",
}: Props) => {
  const Component = as;
  return (
    <Component
      className={cn("w-full", className, {
        "bg-[#BAC0CE]": accentColor === "light-gray",
        "bg-[#616A80]": accentColor === "gray",
        "bg-[#454F68]": accentColor === "dark-gray",
        "bg-[#1B2743]": accentColor === "blue",
      })}
    >
      {children}
    </Component>
  );
};

export { PodcastCardBackground };
