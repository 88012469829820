import { useEffect, useState } from "react";

import { Builder } from "@builder.io/sdk";
import { Button } from "~/components/ui/button";

interface Props {
  platform: "linkedin" | "twitter" | "facebook";
  url: string;
  title?: string;
  className?: string;
}

const socialMediaConfig = {
  linkedin: {
    shareUrl: "https://www.linkedin.com/sharing/share-offsite/?url=",
    icon: "/images/icons/linkedin.svg",
  },
  twitter: {
    shareUrl: "https://twitter.com/intent/tweet?url=",
    icon: "/images/icons/x-twitter.svg",
  },
  facebook: {
    shareUrl: "https://www.facebook.com/sharer/sharer.php?u=",
    icon: "/images/icons/facebook.svg",
  },
} as const;

const ShareButton = ({ platform, url, title, className }: Props) => {
  const { icon, shareUrl } = socialMediaConfig[platform];
  const [platformUrl, setPlatformUrl] = useState("");

  // Workaround to prevent hydration mismatch error
  useEffect(
    () =>
      setPlatformUrl(
        shareUrl +
          encodeURIComponent(url) +
          (title ? `&text=${encodeURIComponent(title)}` : "")
      ),
    [shareUrl, url, title]
  );

  return (
    <Button
      variant="primary"
      icon
      iconUrl={icon}
      rounded="full"
      iconClassName="bg-white"
      className={className}
      link={{
        external: true,
        href: platformUrl,
      }}
    >
      <span className="sr-only">{platform}</span>
    </Button>
  );
};

const registerShareButton = () => {
  Builder.registerComponent(ShareButton, {
    name: "ShareButton",
    inputs: [
      {
        name: "platform",
        type: "string",
        required: true,
        enum: ["linkedin", "twitter", "facebook"],
      },
      {
        name: "url",
        type: "string",
        required: true,
      },
      {
        name: "className",
        type: "string",
      },
    ],
  });
};

export { ShareButton, registerShareButton };
