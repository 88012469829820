import { useRef } from "react";

import { Builder } from "@builder.io/sdk";
import {
  type MotionValue,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";
import Link from "next/link";
import { Button } from "~/components/ui/button";

import { Parallax } from "../ui/parallax";
import { TypographyDeprecated } from "../ui/typography";

type Props = {
  title: string;
  subtitle?: string;
  description: string;
  buttonLabel: string;
  fontColor: string;
  backgroundColor: string;
} & (
  | {
      isExternalLink: true;
      link: string;
      reference?: never;
    }
  | {
      isExternalLink: false;
      link?: never;
      reference: {
        value: {
          data: {
            url: string;
          };
        };
      };
    }
);

const GrowingCircle = ({ scale }: { scale: MotionValue<number> }) => (
  <div className="fixed top-0 z-[1] flex size-full flex-col justify-center">
    <motion.div
      style={{ scale }}
      className={`mx-auto aspect-square h-auto w-[85%] rounded-full bg-white md:w-[55%] lg:w-[45%]`}
    />
  </div>
);

const TextSectionGrowingCircle = ({
  title,
  subtitle,
  description,
  buttonLabel,
  fontColor,
  backgroundColor,
  isExternalLink,
  link,
  reference,
}: Props) => {
  const ref = useRef(null);
  const { scrollYProgress: textSectionYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end center"],
  });

  const scale = useTransform(textSectionYProgress, [0, 1], [0, 3]);

  return (
    <div
      style={{ clipPath: "inset(0)" }}
      className="relative max-h-[6000px] w-auto max-w-full"
      ref={ref}
    >
      <GrowingCircle scale={scale} />
      <div
        className="relative flex size-full items-center justify-center px-6 pb-[50vh]"
        style={{ backgroundColor: "transparent" }}
      >
        <div
          className="absolute left-0 top-0 z-0 size-full"
          style={{ backgroundColor }}
        />
        <div className="z-[2] flex flex-col items-center justify-center">
          {subtitle && (
            <Parallax>
              <TypographyDeprecated
                variant="h4alt"
                color="primary"
                style={{ color: fontColor }}
                className="mx-auto max-w-prose text-center"
              >
                {subtitle}
              </TypographyDeprecated>
            </Parallax>
          )}

          <Parallax offset={["start start", "end start"]}>
            {title && (
              <TypographyDeprecated
                animated
                variant="h1"
                style={{ color: fontColor }}
                color="primary"
                className="mx-auto max-w-prose text-center"
              >
                {title}
              </TypographyDeprecated>
            )}
            {description && (
              <TypographyDeprecated
                animated
                variant="h5"
                color="primary"
                style={{ color: fontColor }}
                className="mx-auto mt-[30px] max-w-[40ch] text-center"
              >
                {description}
              </TypographyDeprecated>
            )}
            {buttonLabel && (
              <div className="mt-8 flex justify-center">
                <Button variant="primary" color="primary" size="xs" asChild>
                  {isExternalLink ? (
                    <a href={link}>{buttonLabel}</a>
                  ) : (
                    <Link href={reference?.value?.data?.url ?? ""}>
                      {buttonLabel}
                    </Link>
                  )}
                </Button>
              </div>
            )}
          </Parallax>
        </div>
      </div>
    </div>
  );
};

const registerTextSectionGrowingCircle = () => {
  Builder.registerComponent(TextSectionGrowingCircle, {
    name: "Text Section Growing Circle",
    inputs: [
      {
        name: "title",
        type: "longText",
      },
      {
        name: "subtitle",
        type: "longText",
        required: false,
      },
      {
        name: "description",
        type: "longText",
      },
      {
        name: "buttonLabel",
        type: "string",
      },
      {
        name: "isExternalLink",
        friendlyName: "Is external link?",
        defaultValue: false,
        type: "boolean",
      },
      {
        name: "link",
        friendlyName: "Link",
        type: "string",
        regex: {
          pattern: "https?://.+",
          message: "Please enter a valid URL",
        },
      },
      {
        name: "reference",
        friendlyName: "Reference",
        type: "reference",
      },
      {
        name: "fontColor",
        type: "color",
      },
      {
        name: "backgroundColor",
        type: "color",
      },
    ],
  });
};
export { TextSectionGrowingCircle, registerTextSectionGrowingCircle };
