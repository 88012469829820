"use client";

import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "~/components/ui/carousel";
import { imageLoader } from "~/utils";

interface Props {
  images: Array<{ image: string; alt: string }>;
}

const ImageRow = ({ images }: Props) => {
  return (
    <Carousel
      opts={{
        active: true,
        align: "center",
        containScroll: false,
        slidesToScroll: "auto",
        breakpoints: {
          "(min-width: 1024px)": {
            active: images.length > 3,
          },
        },
      }}
    >
      <CarouselContent className="h-[180px] lg:h-[268px] lg:w-full">
        {images.map(({ alt, image }, index) => (
          <CarouselItem
            showSkeleton
            className="relative w-[90%] lg:w-1/3"
            key={index}
          >
            <Image
              src={image}
              alt={alt}
              fill
              className="object-cover"
              loader={imageLoader}
            />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};

const registerImageRow = () => {
  Builder.registerComponent(ImageRow, {
    name: "ImageRow",
    inputs: [
      {
        name: "images",
        type: "list",
        allowedFileTypes: ["jpeg", "jpg", "png", "webp"],
        helperText:
          "For best results, the quantity of images should be a multiple of 3.",
        subFields: [
          { name: "image", type: "file" },
          {
            name: "alt",
            friendlyName: "Alt text",
            type: "string",
            defaultValue: "Carousel Image",
          },
        ],
      },
    ],
  });
};

export { ImageRow, registerImageRow };
