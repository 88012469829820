"use client";

import { Builder } from "@builder.io/sdk";
import { AnimatePresence, motion } from "framer-motion";
import { useSearchParams } from "next/navigation";
import { ArticleCard } from "~/components/article-card/article-card";

interface Props {
  limit: number;
  blogPosts: Array<{
    id: string;
    data: any;
  }>;
}

const BlogPostList = ({ blogPosts = [], limit }: Props) => {
  const searchParams = useSearchParams();
  const currentPage = Number(searchParams.get("page")) ?? 0;

  const getShouldAnimate = (index: number) => {
    if (currentPage === 0) return true;
    const startIndexForCurrentPage = currentPage * limit;
    return index >= startIndexForCurrentPage;
  };

  const getAnimationDelay = (index: number) => {
    if (currentPage === 0) return index * 0.08;
    const isLastLimit = blogPosts.length - index <= limit;
    return isLastLimit ? (index - (blogPosts.length - limit)) * 0.08 : 0;
  };

  return (
    <section className="flex w-full flex-col items-center">
      <div className="grid w-full grid-cols-1 gap-x-8 gap-y-[74px] md:grid-cols-2 lg:grid-cols-3">
        <AnimatePresence>
          {blogPosts.map((article, index) => (
            <motion.div
              key={article.id}
              layout
              layoutId={article.id}
              initial={{
                ...(getShouldAnimate(index) && { opacity: 0, scale: 0.75 }),
              }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  duration: 0.08,
                  delay: getAnimationDelay(index),
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.5,
                transition: {
                  duration: 0.08,
                  delay: getAnimationDelay(index),
                },
              }}
            >
              <ArticleCard
                {...article.data}
                handle={article.data?.url ?? ""}
                titleClassName="leading-tight"
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </section>
  );
};

const registerBlogPostList = () => {
  Builder.registerComponent(BlogPostList, {
    name: "BlogPostList",
    defaults: {
      bindings: {
        "component.options.blogPosts": "context.blogPosts",
      },
    },
    inputs: [
      {
        name: "limit",
        friendlyName: "Max articles per page",
        type: "number",
        defaultValue: 6,
      },
      {
        name: "blogPosts",
        type: "list",
        hideFromUI: true,
        subFields: [
          {
            name: "blog",
            type: "reference",
            model: "blog-posts",
          },
        ],
      },
    ],
  });
};

export { BlogPostList, registerBlogPostList };
