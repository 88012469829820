import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import { TypographyDeprecated } from "~/components/ui/typography";
import { imageLoader } from "~/utils";

interface Props {
  title: string;
  description: string;
  icon: {
    image: string;
    backgroundColor: string;
  };
}

const IconWithTextCard = ({ title, description, icon }: Props) => {
  return (
    <article className="relative flex w-full flex-col">
      <div
        className="relative flex aspect-square size-[84px] flex-none items-center justify-center self-center overflow-hidden rounded-full bg-brand-primary-1"
        style={{
          backgroundColor: icon?.backgroundColor,
        }}
      >
        <Image
          src={icon?.image}
          fill
          alt="Perk Icon"
          className="object-contain"
          loader={imageLoader}
        />
      </div>

      <div className="mt-5 flex h-full flex-col items-center gap-2">
        <TypographyDeprecated variant="h5" className="text-2xl" align="center">
          {title}
        </TypographyDeprecated>
        <TypographyDeprecated variant="p" size="lg" align="center">
          {description}
        </TypographyDeprecated>
      </div>
    </article>
  );
};

const registerIconWithTextCard = () => {
  Builder.registerComponent(IconWithTextCard, {
    name: "Icon With Text Card",
    inputs: [
      {
        name: "title",
        type: "string",
        defaultValue: "Branding",
      },
      {
        name: "description",
        type: "string",
        defaultValue: "Branding",
      },
      {
        name: "icon",
        type: "object",
        subFields: [
          {
            name: "image",
            friendlyName: "Icon Image",
            helperText: "Recommended size: 64x64",
            type: "file",
            allowedFileTypes: ["png", "svg"],
          },

          {
            name: "backgroundColor",
            friendlyName: "Background Color",
            type: "color",
            defaultValue: "#8ACE1E",
          },
        ],
      },
    ],
  });
};

export { IconWithTextCard, registerIconWithTextCard };
