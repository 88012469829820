import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import { PodcastCardBackground } from "~/components/podcast-card-background/podcast-card-background";
import { PodcastEpisodeCircle } from "~/components/podcast-episode-circle/podcast-episode-circle";
import { type BuilderDefaultProps } from "~/types/builder.types";
import { imageLoader } from "~/utils";

interface Props extends BuilderDefaultProps {
  episode: number;
  podcastLogo?: string;
  color: "light-gray" | "gray" | "dark-gray" | "blue";
}

const PodcastHero = ({ color, episode, builderState }: Props) => {
  const podcastLogo = builderState?.context.globalSettings.podcastLogoUrl;
  return (
    <PodcastCardBackground
      as="section"
      accentColor={color}
      className="relative z-0 h-[240px] max-w-[100dvw] bg-brand-primary-1 md:h-[494px]"
    >
      <div className="relative size-full overflow-hidden">
        <PodcastEpisodeCircle
          className="left-[5%] top-1/4 md:left-[15%]"
          episode={episode}
        />
      </div>
      {!!podcastLogo && (
        <div className="absolute bottom-[-80px] left-1/2 flex size-36 -translate-x-1/2 items-center justify-center rounded-full bg-brand-primary-1 md:size-48">
          <Image
            alt="Podcast logo"
            src={podcastLogo}
            fill
            className="rounded-full"
            loader={imageLoader}
          />
        </div>
      )}
    </PodcastCardBackground>
  );
};

const registerPodcastHero = () => {
  Builder.registerComponent(PodcastHero, {
    name: "PodcastHero",
    inputs: [
      {
        name: "episode",
        type: "number",
        required: true,
      },
      {
        name: "color",
        type: "string",
        enum: ["light-gray", "gray", "dark-gray", "blue"],
        defaultValue: "gray",
      },
      {
        name: "podcastLogo",
        type: "file",
        allowedFileTypes: ["png", "jpg", "jpeg", "svg", "webp"],
        required: true,
        hideFromUI: true,
      },
    ],
  });
};

export { PodcastHero, registerPodcastHero };
