import { Builder } from "@builder.io/sdk";
import { AnimatePresence, motion } from "framer-motion";
import { TypographyDeprecated } from "~/components/ui/typography";

export interface BulletPointsProps {
  variant?:
    | "p"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h4alt"
    | "h5"
    | "h6"
    | "subtitle"
    | "field-label"
    | "button-text";
  size?: "medium" | "xl" | "lg" | "sm" | "xs";
  color?: "primary" | "secondary" | "white" | "black" | "grey";
  spaceBetweenItems?: number;
  bulletPoints: Array<{ bulletPoint: string }>;
}

const BulletPoints = ({
  variant,
  size,
  color,
  bulletPoints,
  spaceBetweenItems = 48,
}: BulletPointsProps) => {
  return (
    <ul
      style={{ gap: `${spaceBetweenItems}px` }}
      className="flex list-disc flex-col"
    >
      <AnimatePresence>
        {bulletPoints.map(({ bulletPoint }, index) => (
          <TypographyDeprecated
            key={index}
            variant={variant}
            size={size}
            color={color}
            animated
          >
            <motion.li
              layout
              layoutId={bulletPoint}
              initial={{ opacity: 0, scale: 0.75 }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  duration: 0.08,
                  delay: (index - bulletPoints.length) * 0.08,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.5,
                transition: {
                  duration: 0.08,
                  delay: (index - bulletPoints.length) * 0.08,
                },
              }}
            >
              {bulletPoint}
            </motion.li>
          </TypographyDeprecated>
        ))}
      </AnimatePresence>
    </ul>
  );
};

const registerBulletPoints = () => {
  Builder.registerComponent(BulletPoints, {
    name: "BulletPoints",
    inputs: [
      {
        name: "bulletPoints",
        friendlyName: "Bullet Points",
        type: "list",
        subFields: [
          {
            name: "bulletPoint",
            friendlyName: "Bullet Point",
            type: "string",
            defaultValue: "Bullet Point",
          },
        ],
      },
      {
        name: "variant",
        friendlyName: "Variant",
        type: "string",
        defaultValue: "p",
        required: true,
        enum: [
          {
            label: "Heading 1",
            value: "h1",
          },
          {
            label: "Heading 2",
            value: "h2",
          },
          {
            label: "Heading 3",
            value: "h3",
          },
          {
            label: "Heading 4",
            value: "h4",
          },
          {
            label: "Heading 4 alt",
            value: "h4alt",
          },
          {
            label: "Heading 5",
            value: "h5",
          },
          {
            label: "Heading 6",
            value: "h6",
          },
          {
            label: "Paragraph",
            value: "p",
          },
          {
            label: "Subtitle",
            value: "subtitle",
          },
          {
            label: "Field Label",
            value: "field-label",
          },
          {
            label: "Button Text",
            value: "button-text",
          },
        ],
      },
      {
        name: "size",
        friendlyName: "Size",
        type: "string",
        defaultValue: "medium",
        showIf: (options) =>
          options.get("variant") === "p" ||
          options.get("variant") === "field-label" ||
          options.get("variant") === "subtitle",
        enum: [
          {
            label: "Extra Large",
            value: "xl",
          },
          {
            label: "Large",
            value: "lg",
          },
          {
            label: "Medium",
            value: "medium",
          },
          {
            label: "Small",
            value: "sm",
          },
          {
            label: "Extra Small",
            value: "xs",
          },
        ],
      },
      {
        name: "color",
        friendlyName: "Color",
        type: "string",
        defaultValue: "primary",
        required: true,
        enum: [
          {
            label: "Primary",
            value: "primary",
          },
          {
            label: "Secondary",
            value: "secondary",
          },
          {
            label: "White",
            value: "white",
          },
          {
            label: "Black",
            value: "black",
          },
        ],
      },
      {
        name: "spaceBetweenItems",
        friendlyName: "Space Between Items",
        type: "number",
        defaultValue: 48,
      },
    ],
  });
};

export { BulletPoints, registerBulletPoints };
