import { type ClassValue, clsx } from "clsx";
import { type ImageLoaderProps } from "next/image";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const imageLoader = ({ src, width, quality }: ImageLoaderProps) =>
  `${src}?width=${width}&quality=${quality ?? 75}&format=webp`;

export const noop = () => {};

export const fillArray = <T>(items: T[] = [], length = 6): T[] => {
  if (items.length === 0) return [];

  const filledArray = [...items];
  let currentIndex = 0;

  while (filledArray.length < length) {
    filledArray.push(filledArray[currentIndex]);
    currentIndex = (currentIndex + 1) % items.length;
  }

  return filledArray;
};

export const formatDate = (date: string | Date) =>
  Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(isValidDate(date) ? new Date(date) : new Date());

export const isValidDate = (date: string | Date): boolean => {
  const parsedDate = new Date(date);
  return !isNaN(parsedDate.getTime());
};

export const getWindow = () => (typeof window !== "undefined" ? window : null);

export const loadScript = (src: string) => (onLoad: () => void) => {
  const script = document.createElement("script");
  const isScriptAlreadyLoaded = document.querySelector(`script[src="${src}"]`);

  if (isScriptAlreadyLoaded) return onLoad();

  script.src = src;
  script.async = true;
  document.body.appendChild(script);
  script.onload = onLoad;
};

export const getItemsByPage = (items: any[], page: number, limit: number) => {
  const flattedItems = items.flat();
  const slicedByPage = flattedItems
    .slice(0, limit * (page + 1))
    .filter((v) => !!v);
  const unique = new Set(slicedByPage.map((item) => item.id));
  return Array.from(unique).map((id) =>
    flattedItems.find((item) => item.id === id)
  );
};

export const getAppUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return new URL("http://localhost:3000");
  }

  if (process.env.NEXT_PUBLIC_APP_DOMAIN) {
    return new URL(`https://${process.env.NEXT_PUBLIC_APP_DOMAIN}`);
  }

  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    return new URL(`https://${process.env.NEXT_PUBLIC_VERCEL_URL}`);
  }

  return undefined;
};
