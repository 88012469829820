import { type VariantProps, cva } from "class-variance-authority";
import { cn } from "~/utils";

import styles from "./icon.module.css";

const iconVariants = cva("", {
  variants: {
    variant: {
      primary: "bg-brand-primary-2",
      "primary-outline": "",
      white: "bg-white",
      "white-outline": "",
      outline: "",
      ghost: "bg-inherit",
    },
    size: {
      default: "size-11 scale-75",
      xxs: "size-5 scale-90",
      xs: "size-9 scale-75",
      sm: "size-10 scale-75",
      lg: "size-12 scale-75",
      xl: "size-[60px] scale-75",
    },
  },
  defaultVariants: {
    size: "default",
    variant: "primary",
  },
});

interface Props extends VariantProps<typeof iconVariants> {
  iconUrl: string;
  className?: string;
}

const Icon = ({ iconUrl, size, variant, className }: Props) => {
  return (
    <div
      className={cn(
        iconVariants({
          className: cn(styles.iconMask, className),
          size,
          variant,
        })
      )}
      style={{
        WebkitMaskImage: `url(${iconUrl})`,
        maskImage: `url(${iconUrl})`,
      }}
    />
  );
};

export { Icon };
