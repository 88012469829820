import { forwardRef } from "react";

import { Builder } from "@builder.io/sdk";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import Link from "next/link";
import { Icon } from "~/components/ui/icon";
import { type BuilderDefaultProps } from "~/types/builder.types";
import { cn } from "~/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary:
          "bg-brand-primary-3 font-bold text-brand-primary-4 hover:brightness-90",
        "primary-outline":
          "border border-brand-primary-4 bg-transparent font-bold text-brand-primary-4 hover:bg-brand-primary-4 hover:text-white",
        secondary:
          "bg-brand-primary-4 font-bold text-white hover:brightness-90",
        white: "bg-white font-bold text-brand-primary-4 hover:brightness-90",
        "white-outline":
          "border border-white bg-transparent font-bold text-white hover:brightness-90",
        outline:
          "border border-brand-primary-3 bg-transparent font-bold uppercase tracking-[1.2px] text-brand-primary-4 hover:border-brand-primary-3 hover:bg-brand-primary-3 hover:opacity-85 hover:brightness-100",
        ghost: "bg-transparent text-brand-primary-4 hover:brightness-90",
      },
      size: {
        default: "h-11 px-4 py-2",
        xxs: "h-5 px-2",
        xs: "h-9 px-3",
        sm: "h-10 px-3",
        lg: "h-12 px-8",
        xl: "h-[60px] px-10",
      },
      rounded: {
        default: "rounded-[8px]",
        full: "rounded-full",
      },
      icon: {
        true: "",
      },
    },
    compoundVariants: [
      {
        icon: true,
        size: "default",
        className: "size-11 fill-brand-primary-4 p-1",
      },
      {
        icon: true,
        size: "xxs",
        className: "size-5 p-1",
      },
      {
        icon: true,
        size: "xs",
        className: "size-9 p-1",
      },
      {
        icon: true,
        size: "sm",
        className: "size-10 p-1",
      },
      {
        icon: true,
        size: "lg",
        className: "size-12 p-1",
      },
      {
        icon: true,
        size: "xl",
        className: "size-14 p-1",
      },
    ],
    defaultVariants: {
      variant: "primary",
      size: "xs",
      rounded: "default",
      icon: false,
    },
  }
);

interface BaseButtonProps
  extends VariantProps<typeof buttonVariants>,
    BuilderDefaultProps {
  asChild?: boolean;
  iconUrl?: string;
  iconClassName?: string;
  link?: {
    href: string;
    external: boolean;
  };
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    BaseButtonProps {}

interface LinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href">,
    BaseButtonProps {}

const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps & LinkProps
>(
  (
    {
      className,
      asChild = false,
      variant,
      size,
      icon,
      rounded,
      iconUrl,
      builderBlock: _builderBlock,
      builderState: _builderState,
      children,
      iconClassName,
      link,
      ...props
    },
    ref
  ) => {
    if (link)
      return (
        <LinkButton
          {...props}
          {...{
            asChild,
            variant,
            size,
            icon,
            rounded,
            iconUrl,
            link,
            className,
            iconClassName,
          }}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          {children}
        </LinkButton>
      );

    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        {...props}
        className={cn(
          buttonVariants({ variant, size, className, icon, rounded })
        )}
        ref={ref as React.Ref<HTMLButtonElement>}
      >
        {icon ? (
          <Icon
            iconUrl={iconUrl ?? ""}
            size={size}
            variant={getVariant(variant)}
            className={iconClassName}
          />
        ) : (
          children
        )}
      </Comp>
    );
  }
);
Button.displayName = "Button";

const LinkButton = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      className,
      asChild = false,
      variant,
      size,
      icon,
      rounded,
      iconUrl,
      builderBlock: _builderBlock,
      builderState: _builderState,
      children,
      iconClassName,
      link,
      ...props
    },
    ref
  ) => {
    const Comp = link!.external ? "a" : Link;

    return (
      <Comp
        href={link!.href ?? "#"}
        className={cn(
          buttonVariants({ variant, size, className, icon, rounded })
        )}
        ref={ref}
        target={link!.external ? "_blank" : undefined}
        {...props}
      >
        {icon ? (
          <Icon
            iconUrl={iconUrl ?? ""}
            size={size}
            variant={getVariant(variant)}
            className={iconClassName}
          />
        ) : (
          children
        )}
      </Comp>
    );
  }
);
LinkButton.displayName = "LinkButton";

const registerButton = () => {
  Builder.registerComponent(Button, {
    name: "Button",
    override: true,
    inputs: [
      {
        name: "variant",
        type: "string",
        defaultValue: "primary",
        enum: [
          { label: "Primary", value: "primary" },
          { label: "Primary Outline", value: "primary-outline" },
          { label: "Secondary", value: "secondary" },
          {
            label: "White",
            value: "white",
          },
          {
            label: "White Outline",
            value: "white-outline",
          },
          {
            label: "Outline",
            value: "outline",
          },
          {
            label: "Ghost",
            value: "ghost",
          },
        ],
      },
      {
        name: "icon",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "size",
        type: "string",
        defaultValue: "xs",
        enum: [
          { label: "Default", value: "default" },
          { label: "Extra Extra Small", value: "xxs" },
          { label: "Extra Small", value: "xs" },
          { label: "Small", value: "sm" },
          { label: "Large", value: "lg" },
          { label: "Extra Large", value: "xl" },
        ],
      },
      {
        name: "rounded",
        type: "string",
        defaultValue: "default",
        enum: [
          { label: "Default", value: "default" },
          { label: "Full", value: "full" },
        ],
      },
      {
        name: "children",
        friendlyName: "Content",
        helperText: "Content of the button",
        type: "string",
        defaultValue: "Button",
        showIf: (options) => !options.get("icon"),
      },
      {
        name: "iconUrl",
        type: "file",
        friendlyName: "Icon Image",
        showIf: (options) => options.get("icon"),
        allowedFileTypes: ["svg"],
      },
      {
        name: "link",
        type: "object",
        subFields: [
          {
            name: "href",
            type: "string",
            defaultValue: "#",
            required: true,
          },
          {
            name: "external",
            type: "boolean",
            defaultValue: false,
            required: true,
          },
        ],
      },
    ],
  });
};

const getVariant = (variant: BaseButtonProps["variant"]) => {
  if (variant === "white") return "primary";
  if (variant === "secondary") return "white";
  return variant;
};

export { Button, buttonVariants, registerButton };
