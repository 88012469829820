"use client";

import { useEffect, useMemo } from "react";

import { Builder, builder } from "@builder.io/sdk";
import { AnimatePresence, motion } from "framer-motion";
import { useSearchParams } from "next/navigation";
import { CardListTabs } from "~/components/card-list-tabs/card-list-tabs";
import { Pagination } from "~/components/pagination/pagination";
import {
  TeamMemberCard,
  type TeamMembers,
} from "~/components/team-member-card/team-member-card";
import { TeamMemberModal } from "~/components/team-member-card/team-member-modal";
import { type Tag } from "~/types/article";
import { type BuilderDefaultProps } from "~/types/builder.types";
import { getItemsByPage, noop } from "~/utils";

import { useTeamMembersQuery } from "./data";

interface Props
  extends BuilderDefaultProps<{
    cardTags: Tag[];
  }> {
  limit: number;
  loadMoreText: string;
  teamMembers: Array<{
    id: string;
    data: any;
  }>;
}

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!);

const TeamMemberList = ({
  teamMembers = [],
  limit,
  builderState,
  loadMoreText,
}: Props) => {
  const searchParams = useSearchParams();
  const tags = builderState?.context.cardTags ?? [];
  const page = Number(searchParams.get("page")) ?? 0;
  const tag = searchParams.get("tag") ?? "all";

  const { data, fetchNextPage, hasNextPage, refetch } = useTeamMembersQuery({
    items: teamMembers,
    limit,
    page,
    tag,
  });

  const items = useMemo(
    () => getItemsByPage(data.pages, page, limit),
    [data.pages, limit, page]
  );

  const currentTeamCard = items.find(
    (item) => item.data.handle === searchParams.get("team-member")
  );

  const getAnimationDelay = (index: number) => {
    if (page === 0) return index * 0.08;
    const isLastLimit = items.length - index <= limit;
    return isLastLimit ? (index - (items.length - limit)) * 0.08 : 0;
  };

  const handleNextPage = () => {
    fetchNextPage().catch(noop);
  };

  useEffect(() => {
    refetch().catch(noop);
  }, [refetch, tag]);

  return (
    <>
      {currentTeamCard ? <TeamMemberModal {...currentTeamCard.data} /> : null}
      <section className="flex flex-col items-center">
        <div className="mt-9 grid grid-cols-2 gap-2 md:grid-cols-2 lg:grid-cols-5">
          <div className="col-span-2 md:col-span-2 lg:col-span-5">
            <CardListTabs tags={tags} align="right" />
          </div>
          <AnimatePresence mode="popLayout" initial={false}>
            {items.map(
              (
                member: {
                  id: string;
                  data: TeamMembers;
                },
                index: number
              ) => (
                <motion.div
                  key={member.id}
                  layout
                  layoutId={member.id}
                  initial={{
                    opacity: 0,
                    scale: 0.75,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: 0.08,
                      delay: getAnimationDelay(index),
                    },
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0.5,
                    transition: {
                      duration: 0,
                    },
                  }}
                >
                  <TeamMemberCard {...member.data} />
                </motion.div>
              )
            )}
          </AnimatePresence>
        </div>
        <Pagination
          className="mt-10"
          nextPage={hasNextPage ? page + 1 : undefined}
          loadMoreText={loadMoreText}
          limit={0}
          total={0}
          onClick={handleNextPage}
        />
      </section>
    </>
  );
};

const registerTeamMemberList = () => {
  Builder.registerComponent(TeamMemberList, {
    name: "TeamMemberList",
    defaults: {
      bindings: {
        "component.options.teamMembers": "context.teamMembers.items",
      },
    },
    inputs: [
      {
        name: "loadMoreText",
        type: "string",
        defaultValue: "Load more",
      },
      {
        name: "limit",
        friendlyName: "Max articles per page",
        type: "number",
        defaultValue: 6,
      },
      {
        name: "teamMembers",
        type: "list",
        hideFromUI: true,
        subFields: [
          {
            name: "teamMembers",
            type: "reference",
            model: "team-member",
          },
        ],
      },
    ],
  });
};

export { TeamMemberList, registerTeamMemberList };
