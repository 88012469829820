import { Builder } from "@builder.io/sdk";
import Image from "next/image";
import { Icon } from "~/components/ui/icon";
import { useVideo } from "~/hooks/use-video";
import { cn } from "~/utils";

interface Props {
  video: string;
  initialMuted: boolean;
  autoplay: boolean;
  rounded: boolean;
  thumbnail?: string;
}

const Video = ({
  autoplay: _autoplay,
  initialMuted,
  video,
  rounded,
  thumbnail,
}: Props) => {
  const {
    ref,
    pause,
    play,
    mute,
    selectProgress,
    isPaused,
    progress,
    downloadProgress,
    muted,
    transition,
    autoplay,
  } = useVideo({
    muted: initialMuted,
    autoplay: _autoplay,
  });

  return (
    <div
      className={cn("group relative aspect-video", {
        "rounded-xl": rounded,
      })}
    >
      {thumbnail && isPaused && (
        <Image
          src={thumbnail}
          alt="Thumbnail"
          layout="fill"
          className={cn("absolute inset-0 size-full w-full object-cover", {
            "rounded-xl": rounded,
          })}
        />
      )}
      <video
        autoPlay={autoplay}
        ref={ref}
        data-id={isPaused}
        className={cn("size-full", {
          "rounded-xl": rounded,
        })}
        disablePictureInPicture
        muted={muted}
        playsInline
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        className={cn(
          "absolute bottom-0 size-full hover:bg-black/10 active:bg-black/10",
          {
            "bg-black/10": isPaused,
          }
        )}
      >
        <div
          className={cn(
            "absolute bottom-0 -z-10 flex h-7 w-full items-center justify-center gap-1 px-2 opacity-0 transition-opacity duration-300 sm:bottom-3 sm:px-10 md:bottom-6",
            "group-hover:z-10 group-hover:opacity-100",
            {
              "opacity-100 z-10": isPaused,
            }
          )}
        >
          <button
            onClick={isPaused ? play : pause}
            className="flex size-4 items-center justify-center sm:size-6 md:size-7"
          >
            <Icon
              className="bg-white"
              size="xxs"
              iconUrl={isPaused ? PLAY_ICON : PAUSE_ICON}
            />
          </button>
          <button
            onClick={mute}
            className="flex size-5 items-center justify-center sm:size-6 md:size-7"
          >
            <Icon
              className="bg-white"
              size="default"
              iconUrl={muted ? MUTED_ICON : VOLUME_HIGH_ICON}
            />
          </button>
          <div className="ml-1 flex h-[3px] flex-1 items-center sm:ml-2 sm:h-1">
            <div
              className="relative h-full flex-1 rounded-full bg-white/30 backdrop-blur-sm"
              onClick={selectProgress}
            >
              <div
                className="absolute left-0 h-full rounded-full bg-white/50 backdrop-blur-sm"
                style={{
                  width: `${downloadProgress}%`,
                  transition,
                }}
              />
              <div
                className={cn(
                  "absolute left-0 flex h-full w-0 items-center justify-end rounded-full bg-brand-primary-3 backdrop-blur-xl"
                )}
                style={{
                  width: `${progress}%`,
                  transition,
                }}
              >
                <div
                  className={
                    "right-0 aspect-square size-[7px] rounded-full bg-white sm:size-[9px]"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const registerVideo = () => {
  Builder.registerComponent(Video, {
    name: "video",
    override: true,
    inputs: [
      {
        name: "video",
        type: "file",
        allowedFileTypes: ["mp4"],
        required: true,
      },
      {
        name: "thumbnail",
        type: "file",
        allowedFileTypes: ["jpeg", "jpg", "png", "webp"],
        required: false,
      },
      {
        name: "autoplay",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "initialMuted",
        friendlyName: "Video starts muted",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "rounded",
        type: "boolean",
        defaultValue: true,
      },
    ],
  });
};

export { Video };

const PLAY_ICON = "/images/icons/play.svg";
const PAUSE_ICON = "/images/icons/pause.svg";
const MUTED_ICON = "/images/icons/muted.svg";
const VOLUME_HIGH_ICON = "/images/icons/volume-high.svg";
